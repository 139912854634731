import React from "react";
import { Tabs } from "../../Contents";
import { removeSpaces } from "../../../../../utils/useful";

interface ContentHeadingProps {
	heading: Tabs;
	Icon?: any;
	select: any;
	selectedTab: Tabs;
	color?: string;
}

const ContentHeading = ({
	heading,
	Icon,
	select,
	selectedTab,
	color,
}: ContentHeadingProps) => {
	return (
		<div
			id={removeSpaces(heading)}
			className="flex cursor-pointer "
			onClick={select}
		>
			<div
				className={`flex  items-center justify-center px-2 py-1 font-semibold md:text-xl lg:px-4 lg:text-[22px] ${
					selectedTab === heading &&
					"border-b-2 border-b-orangeCrushWarm  md:border-b-4"
				}`}
			>
				<Icon
					className="text-2xl md:text-3xl"
					style={{
						// fontSize: "2rem",
						color: selectedTab === heading ? color : "dimGray",
					}}
				/>
				<div
					className={` ${
						selectedTab === heading ? "text-orangeGoku" : "text-dimGray"
					} ml-2 whitespace-nowrap `}
				>
					{heading}
				</div>
			</div>
		</div>
	);
};

export default ContentHeading;
