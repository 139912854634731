import {PayloadAction} from "@reduxjs/toolkit";
// redux create slice template
import {createSlice} from "@reduxjs/toolkit";

export interface pageOptions {
	pageNumber: number;
	filter?: string;
	value?: string;
}

export interface ContentState {
	twitter: pageOptions;
	youtube: pageOptions;
	blogs: pageOptions;
	reddit: pageOptions;
	trendingBlogs: pageOptions;
}

const initalOptions: pageOptions = {
	pageNumber: 1,
	filter: "",
	value: "",
};

const initialState: ContentState = {
	twitter: initalOptions,
	youtube: initalOptions,
	blogs: initalOptions,
	reddit: initalOptions,
	trendingBlogs: initalOptions,
};

const contentSlice = createSlice({
	name: "content",
	initialState,
	reducers: {
		incrementPage: (state, action: PayloadAction<string>) => {
			switch (action.payload) {
				case "twitter":
					state.twitter.pageNumber++;
					break;
				case "youtube":
					state.youtube.pageNumber++;
					break;
				case "blogs":
					state.blogs.pageNumber++;
					break;
				case "reddit":
					state.reddit.pageNumber++;
					break;
				case "trendingBlogs":
					state.trendingBlogs.pageNumber++;
					break;
				default:
					break;
			}
		},
		updateFilter: (state, action: PayloadAction<{type: string; filter: string; value: string}>) => {
			switch (action.payload.type) {
				case "twitter":
					state.twitter.filter = action.payload.filter;
					state.twitter.value = action.payload.value;
					break;
				case "youtube":
					state.youtube.filter = action.payload.filter;
					state.youtube.value = action.payload.value;
					break;
				case "blogs":
					state.blogs.filter = action.payload.filter;
					state.blogs.value = action.payload.value;
					break;
				case "reddit":
					state.reddit.filter = action.payload.filter;
					state.reddit.value = action.payload.value;
					break;
				case "trendingBlogs":
					state.trendingBlogs.filter = action.payload.filter;
					state.trendingBlogs.value = action.payload.value;
					break;
				default:
					break;
			}
		},
	},
});

export const {incrementPage, updateFilter} = contentSlice.actions;

export default contentSlice.reducer;
