import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const tweetsApi = createApi({
	reducerPath: "tweetsApi",
	// baseQuery: fetchBaseQuery({ baseUrl: "http://3.109.107.2:7000/api/" }),
	baseQuery: fetchBaseQuery({ baseUrl: "https://apicruxe.xyz/api/" }),
	endpoints: (builder) => ({
		getAllTweets: builder.mutation<any,{query:string,exact_search:boolean}>({
			query: ({ query, exact_search }) => ({
				headers: { "Content-type": "application/json" },
				url: "tool/showfeed/twitter/",
				method: "POST",
				body: {
					query,
					exact_search: exact_search ? "True" : "False",
				},
			}),
		}),
		// mailSend: builder.mutation({
		// 	query: ({ query, list, mail }) => ({
		// 		headers: { "Content-type": "application/json" },
		// 		url: "tool/mailsend/",
		// 		method: "POST",
		// 		body: {
		// 			query: query ? query : "startup",
		// 			list,
		// 			mail,
		// 		},
		// 	}),
		// }),
		// earlySignup: builder.mutation({
		// 	query: ({ mail }) => ({
		// 		headers: { "Content-type": "application/json" },
		// 		url: "tool/earlysignup/",
		// 		method: "POST",
		// 		body: {
		// 			mail,
		// 		},
		// 	}),
		// }),
	}),
});

export const {
	useGetAllTweetsMutation,
	// useMailSendMutation,
	// useEarlySignupMutation,
} = tweetsApi;
