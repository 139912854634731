import {
	GOOGLE_OAUTH_SUCCESS,
	AUTH_ERROR,
	USER_LOADED,
	LOGOUT,
	PLAN_LOADED,
	PLAN_LOADED_FAIL,
	STATS_LOADED,
	STATS_LOADED_FAIL,
	REDIRECT,
} from "./types";
import axios from "axios";
import { responsiveFontSizes } from "@material-ui/core";

const proxy1 = "https://apicruxe.xyz";
// const proxy1 = "http://3.7.45.147:8080";

const proxy = "https://apicruxe.xyz";

// Load plan
export const loadPlan = (token) => async (dispatch) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const res = await axios.get(proxy1 + "/api/users/me/", config);
		dispatch({
			type: PLAN_LOADED,
			payload: res.data,
		});
	} catch (e) {
		dispatch({
			type: PLAN_LOADED_FAIL,
		});
	}
};

export const loadStats = (token) => async (dispatch) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const res = await axios.get(proxy1 + "/api/uploads/calculate/", config);
		dispatch({
			type: STATS_LOADED,
			payload: res.data,
		});
	} catch (e) {
		console.log(e);
		dispatch({
			type: STATS_LOADED_FAIL,
		});
	}
};

// Load User
export const loadUser = (token) => async (dispatch) => {
	let res = null;
	const config = {
		headers: {
			"Content-type": "application/json",
		},
	};

	const body = {
		token: JSON.stringify(token),
	};

	try {
		res = await axios.post(
			proxy1 + "/api/users/token/obtainc/",
			body,
			config
		);
		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
		console.log("insideload userfunction", res);
		dispatch(loadPlan(res.data.access_token));
		dispatch(loadStats(res.data.access_token));
	} catch (error) {
		dispatch({
			type: AUTH_ERROR,
			payload:
				error.response && error.response.message
					? error.respnse.message
					: error.message,
		});
	}
};

// Google OAuth
export const googleOAuth = (response) => async (dispatch) => {
	console.log("inside google auth");
	if (response.error) {
		console.log("error");
		dispatch({
			type: AUTH_ERROR,
		});

		console.log("inside error function", response);
	} else {
		console.log("success");
		try {
			dispatch({
				type: GOOGLE_OAUTH_SUCCESS,
				payload: response,
			});
			console.log("inside", response);
			dispatch(loadUser(response.access_token));
		} catch (error) {
			console.log("inside error catch");
			dispatch({
				type: AUTH_ERROR,
				payload:
					error.response && error.response.message
						? error.respnse.message
						: error.message,
			});
		}
	}

	console.log("outside", response);
};

// LOGOUT
export const logout = () => async (dispatch) => {
	try {
		dispatch({
			type: LOGOUT,
		});
	} catch (e) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

export const redirectLogin = (response) => async (dispatch) => {
	dispatch({
		type: REDIRECT,
		payload: response,
	});
};
