import React, { useState, useEffect } from "react";

import { GiElectric } from "react-icons/gi";

import { LightTooltip } from "./ToolTip";
//redux
import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";
import { useFetchStatsQuery } from "../../../redux/features/apis/otherApi";
import StreakIcon from "../../icons/StreakIcon";
import WordSummarized from "../../icons/WordSummarized";
import ActivePlanIcon from "../../icons/ActivePlanIcon";

// circular progracebar
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const ProgressBox1 = () => {
	const [outOf, setOutOf] = useState(10000);
	const [percent, setPercent] = useState(0);

	const { user } = useTypedSelector((state) => state.auth);
	const { data: stats } = useFetchStatsQuery();

	let toolTipTittle: string =
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit";

	useEffect(() => {
		if (stats && stats.summary_wordcount) {
			let p = Math.round((stats.summary_wordcount / outOf) * 100);
			if (p > 100) {
				p = 100;
			}
			setPercent(p);
		}
	}, [stats]);

	useEffect(() => {
		const plan = user?.type_of_plan;
		if (plan === "free") {
			setOutOf(500);
		} else if (plan === "basic") {
			setOutOf(2000);
		} else if (plan === "pro") {
			setOutOf(10000);
		} else if (plan === "super") {
			setOutOf(25000);
		}
	}, [user?.type_of_plan]);

	// console.log("user", user);

	return (
		<>
			<div className="homePageIcon group relative   ">
				<div>
					<StreakIcon />
				</div>
				<div className="   flex flex-col">
					<span className=" text-base  text-[#646464] sm:text-sm">
						Progress Streak
					</span>
					<span className="font-lato text-lg font-bold md:text-[20px]">
						{stats?.streak}
					</span>
				</div>
  				{/* <span className="pointer-events-none absolute -left-16 w-44 -translate-x-1/2  rounded bg-white  px-2 py-2 text-sm text-black opacity-0  shadow transition before:absolute before:-right-6 before:bottom-1/2 before:-translate-x-1/2 before:-rotate-90 before:border-8 before:border-transparent before:border-t-white before:shadow before:content-[''] group-hover:opacity-100 sm:text-base">
					// Lorem ipsum dolor sit amet, veniam et velit aut.
				// </span>*/}
			</div>
			<div className="homePageIcon relative">
				<div
					className="flex items-center justify-center"
					style={{ height: 70, width: 70 }}
				>
					<CircularProgressbar
						styles={buildStyles({
							strokeLinecap: "butt",
							pathColor: "#ee732e",
						})}
						value={(stats?.summary_wordcount / outOf) * 100}
					/>
				</div>
				<div className="flex flex-col">
					<span className=" text-base  text-[#646464] sm:text-sm">
						Word Summarized
					</span>
					<span className="font-lato text-lg font-bold md:text-[20px]">
						{stats?.summary_wordcount}/{outOf}
					</span>
				</div>
			</div>
			<div className="homePageIcon relative ">
				<div>
					<ActivePlanIcon />
				</div>
				<div className="flex flex-col">
					<span className=" text-base  text-[#646464] sm:text-sm">
						Active Plan
					</span>
					<span className="font-lato text-lg font-bold md:text-[20px]">
						{user?.type_of_plan}
					</span>
				</div>
			</div>

			{/* {stats?.summary_wordcount}/{outOf} */}

			{/* width: `${percent ? percent : 0}%  */}

			{/* {user?.type_of_plan} */}
		</>
	);
};

export default ProgressBox1;
