import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";

import { Tweet } from "react-twitter-widgets";

interface Props {
	data: string[];
}

const Tweets = ({ data }: Props) => {
	if (data?.length < 1) {
		return (
			<h1 className="text-center text-gray-500">
				No Tweets found for this query{" "}
				<span className=" rotate-45 ">:(</span>
				<br />
				Please note this is exact keyphrase search
			</h1>
		);
	}

	// console.log("tweets page ");

	return (
		<>
			<div className="tweets  flex flex-col items-center justify-center space-y-8 ">
				<div className="lg:columns-2">
					{data?.map((tweet) => (
						<div className="" key={tweet.split("/")[5]}>
							<div className="tweets-div">
								<LazyLoad height={400}>
									<Tweet tweetId={tweet.split("/")[5]} />
								</LazyLoad>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Tweets;
