import React, { useState, useContext } from "react";

import {
	FaTwitterSquare,
	FaLinkedin,
	FaInstagramSquare,
	FaFacebookSquare,
} from "react-icons/fa";

import { Button } from "@material-ui/core";
import { SiCanva } from "react-icons/si";

import { canvaContext } from "../../../App";
import CanvaSocialbtn from "../components/other/CanvaSocialbtn";
function CanvaPage() {
	let api = useContext(canvaContext);

	const [desId, setdesId] = useState(null);
	const TwitterPost = "TwitterPost";
	const InstagramPost = "InstagramPost";
	const InstagramStory = "InstagramStory";
	const FacebookPost = "FacebookPost";
	const LinkedInBanner = "LinkedInBanner";

	var desigprevDesignId;
	const canvaDesign = (name: string) => {
		api.createDesign({
			design: {
				type: name,
			},
			onDesignOpen: ({ designId }: any) => {
				desigprevDesignId = designId;
			},
			onDesignPublish: ({ exportUrl, designId }: any) => {
				desigprevDesignId = designId;
				setdesId(desigprevDesignId);
				const Dowbtn: any = document.getElementById("canva-download");
				const Dbtn: any = document.getElementById("download-btn");
				Dowbtn.style.display = "inline-block";
				Dbtn.href = exportUrl;

				const Editbtn: any = document.getElementById("edit-btn");

				// Editbtn.style.display = "inline-block";
				Dowbtn.classList.add("flex");
				Dowbtn.classList.remove("hidden");
				Editbtn.classList.add("flex");
				Editbtn.classList.remove("hidden");
				// console.log(desigprevDesignId);
			},
			onDesignClose: () => {
				// Triggered when editor is closed.
			},
		});
	};
	const canvaEdit = (id1: any) => {
		api.editDesign({
			design: {
				id: desId,
			},
			onDesignOpen: ({ designId }: any) => {
				desigprevDesignId = designId;
			},
			onDesignPublish: ({ exportUrl, designId }: any) => {
				desigprevDesignId = designId;
				setdesId(desigprevDesignId);
				const Dowbtn: any = document.getElementById("canva-download");
				const Dbtn: any = document.getElementById("download-btn");
				Dowbtn.style.display = "inline-block";
				Dbtn.href = exportUrl;
				const Editbtn: any = document.getElementById("edit-btn");
				// Editbtn.style.display = "inline-block";
				Editbtn.classList.add("flex");
				Editbtn.classList.remove("hidden");
			},
		});
	};

	// const canvaClick = () => {};

	return (
		<div className=" px-4 pt-4 font-poppins sm:mr-16 sm:ml-[80px]  sm:pt-8 lg:mx-20">
			<div className="flex min-h-[500px] flex-col items-center justify-center">
				<div className="mb-2 flex flex-row items-center text-[40px] md:text-[50px]">
					<SiCanva className=" mr-3 text-[10rem]" color="#20c4cb" />
				</div>
				<div className="mb-2 grid -translate-x-2 grid-cols-2 gap-2">
					<a href="www.cruxe.in" id="download-btn" download>
						<Button
							id="canva-download"
							className=" downloadEditBtn  hidden rounded-md border-[1px] border-[#EE732E] py-2 px-4 font-semibold text-orangeCrushWarm hover:bg-[#FDF0E7] "
						>
							Download
						</Button>
					</a>

					<Button
						id="edit-btn"
						className=" downloadEditBtn  hidden rounded-md border-[1px] border-[#EE732E] py-2 px-4 font-semibold text-orangeCrushWarm hover:bg-[#FDF0E7]"
						onClick={canvaEdit}
					>
						Edit
					</Button>
				</div>
				<div className="flex flex-wrap items-center justify-center md:mt-4 lg:space-x-7">
					<CanvaSocialbtn
						name={TwitterPost}
						Icon={FaTwitterSquare}
						canvaDesign={canvaDesign}
						color="#1DA1F2"
						text="Twitter Post"
					/>
					<CanvaSocialbtn
						name={LinkedInBanner}
						Icon={FaLinkedin}
						canvaDesign={canvaDesign}
						color="#0077b5"
						text="LinkedIn Banner"
					/>
					<CanvaSocialbtn
						name={InstagramPost}
						Icon={FaInstagramSquare}
						canvaDesign={canvaDesign}
						color="#C13584"
						text="Instagram Post"
					/>
					<CanvaSocialbtn
						name={InstagramStory}
						Icon={FaInstagramSquare}
						canvaDesign={canvaDesign}
						color="#C13584"
						text="Instagram Story"
					/>
					<CanvaSocialbtn
						name={FacebookPost}
						Icon={FaFacebookSquare}
						canvaDesign={canvaDesign}
						color="#1DA1F2"
						text="Facebook Post"
					/>
				</div>
			</div>
		</div>
	);
}

export default CanvaPage;
