import Logo from "../../../img/CruxeLogo_white.png";
import {
	AiFillInstagram,
	AiFillLinkedin,
	AiOutlineTwitter,
	AiFillYoutube,
} from "react-icons/ai";

const Footer = () => {
	return (
		<div className="flex items-center justify-center bg-[#2F4858] py-10">
			<div className="mx-auto grid max-w-[1000px] grid-cols-2 gap-4 md:grid-cols-4">
				{/* <div className="bg-[#2F4858] w-full py-[30px] sm:py-[60px] px-2 sm:px-[30px] md:px-[50px] lg:px-[200px] flex flex-col sm:flex-row justify-between items-center sm:items-start"> */}
				<div className="ml-4 flex  flex-col ">
					<div className="flex  flex-col  ">
						<img
							src={Logo}
							alt="logo"
							className="mb-3 h-fit w-[150px] sm:w-[200px]"
						/>
						<div className="mx-2 mb-2 text-sm text-[#C5C5C5] sm:mx-0 sm:text-base">
							<p>
								Curate impactful, efficient and simplified content with
								Cruxe's AI based Content Recommendation platform.
							</p>
						</div>
					</div>
					<div className="mt-2 flex flex-row ">
						<a href="https://www.instagram.com/cruxe_in/">
							<AiFillInstagram size="30px" color="white" />
						</a>
						<a href="https://www.linkedin.com/company/cruxe7/" className="ml-2">
							<AiFillLinkedin size="30px" color="white" />
						</a>
						<a href="https://twitter.com/cruxe_in" className="ml-2">
							<AiOutlineTwitter size="30px" color="white" />
						</a>
						<a href="youtube.com/@cruxe_in" className="ml-2">
							<AiFillYoutube size="30px" color="white" />
						</a>
					</div>
				</div>

				<div className="ml-2 flex flex-1 flex-col    ">
					<div className="mb-3 text-2xl font-bold text-white">Company</div>
					<a href="/" className="mb-2 text-[#C5C5C5] hover:underline">
						About Us
					</a>
					<a href="/" className="mb-2 text-[#C5C5C5] hover:underline">
						Terms and Conditions
					</a>
					<a href="/" className="mb-2 text-[#C5C5C5] hover:underline">
						Privacy Policy
					</a>
				</div>
				<div className="ml-4 flex flex-1 flex-col  ">
					<div className="mb-3 text-2xl font-bold text-white">Help</div>
					<a href="https://youtu.be/T7lvwTCvuGo" className="mb-2 text-[#C5C5C5] hover:underline">
						Watch the Demo
					</a>
					<a href="https://forms.gle/Y976CVS4x5Lw7bxw7" className="mb-2 text-[#C5C5C5] hover:underline">
						Report a Bug
					</a>
					<a href="https://forms.gle/TBAVShS1aAHLYJyL8" className="mb-2 text-[#C5C5C5] hover:underline">
						Share Feedback
					</a>
				</div>

				<div className=" ml-2 flex  flex-col  sm:mt-0 ">
					<div className="mb-3 text-2xl font-bold text-white">
						Contact Us
					</div>
					<a href="/" className="mb-2 text-[#C5C5C5] hover:underline">
						paras@cruxe.in
					</a>
					<a href="/" className="mb-2 text-[#C5C5C5] hover:underline">
						ai.cruxe@gmail.com
					</a>
					<div className="flex flex-row text-[#C5C5C5]">
						ph:{" "}
						<div className="ml-1 flex flex-col">
							<a
								href="/"
								className="mb-1 text-[#C5C5C5] hover:underline"
							>
								+91-9027193693
							</a>
							<a
								href="/"
								className="mb-1 text-[#C5C5C5] hover:underline"
							>
								+91-9027193693
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
