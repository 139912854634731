import React from "react";

function StreakIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="63"
			height="68"
			fill="none"
			viewBox="0 0 63 68"
		>
			<rect width="63" height="68" fill="#FAE2CF" rx="9"></rect>
			<path
				fill="url(#paint0_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint1_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint2_linear_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint3_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint4_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint5_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint6_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<path
				fill="url(#paint7_radial_1_940)"
				d="M27.124 26.052c1.73-2.697 2.753-5.188 3.287-6.95.204-.674 1.068-.95 1.581-.466 7.732 7.291 10.26 12.859 10.763 19.291.37 6.223-2.689 11.76-10.898 11.76-7.706 0-13.395-5.379-12.622-13.905.463-5.094 2.444-8.976 4.027-11.287.493-.719 1.52-.73 2.088-.068l1.417 1.65a.224.224 0 00.357-.025z"
			></path>
			<g filter="url(#filter0_i_1_940)">
				<path
					fill="url(#paint8_radial_1_940)"
					d="M24.45 38.362c1.465-3.488 4.472-7.346 6.503-9.44a1.503 1.503 0 012.106-.042c3.893 3.588 5.066 6.831 6.06 10.607 1.035 3.928 0 10.257-7.786 10.257-6.705 0-9.03-6.275-6.883-11.382z"
				></path>
				<path
					fill="url(#paint9_radial_1_940)"
					d="M24.45 38.362c1.465-3.488 4.472-7.346 6.503-9.44a1.503 1.503 0 012.106-.042c3.893 3.588 5.066 6.831 6.06 10.607 1.035 3.928 0 10.257-7.786 10.257-6.705 0-9.03-6.275-6.883-11.382z"
				></path>
				<path
					fill="url(#paint10_radial_1_940)"
					d="M24.45 38.362c1.465-3.488 4.472-7.346 6.503-9.44a1.503 1.503 0 012.106-.042c3.893 3.588 5.066 6.831 6.06 10.607 1.035 3.928 0 10.257-7.786 10.257-6.705 0-9.03-6.275-6.883-11.382z"
				></path>
			</g>
			<path
				fill="url(#paint11_linear_1_940)"
				d="M24.45 38.362c1.465-3.488 4.472-7.346 6.503-9.44a1.503 1.503 0 012.106-.042c3.893 3.588 5.066 6.831 6.06 10.607 1.035 3.928 0 10.257-7.786 10.257-6.705 0-9.03-6.275-6.883-11.382z"
			></path>
			<path
				fill="url(#paint12_radial_1_940)"
				d="M24.45 38.362c1.465-3.488 4.472-7.346 6.503-9.44a1.503 1.503 0 012.106-.042c3.893 3.588 5.066 6.831 6.06 10.607 1.035 3.928 0 10.257-7.786 10.257-6.705 0-9.03-6.275-6.883-11.382z"
			></path>
			<g filter="url(#filter1_f_1_940)">
				<path
					fill="url(#paint13_linear_1_940)"
					d="M26.29 28.981c2.605-3.813 4.13-7.94 4.594-8.957-.678 5.213-2.655 9.032-6.376 13.948-2.977 3.933-3.515 7.299-3.489 8.119-.93-6.257 2.015-8.343 5.272-13.11z"
				></path>
			</g>
			<g filter="url(#filter2_f_1_940)">
				<path
					fill="url(#paint14_linear_1_940)"
					d="M24.04 24.858c-1.462 1.816-4.37 6.851-4.297 12.47 1.44-5.763 4.81-7.578 4.298-12.47z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_i_1_940"
					width="16.278"
					height="21.274"
					x="23.676"
					y="28.471"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dx="0.5"></feOffset>
					<feGaussianBlur stdDeviation="0.25"></feGaussianBlur>
					<feComposite
						in2="hardAlpha"
						k2="-1"
						k3="1"
						operator="arithmetic"
					></feComposite>
					<feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.615686 0 0 0 0 0.364706 0 0 0 1 0"></feColorMatrix>
					<feBlend
						in2="shape"
						result="effect1_innerShadow_1_940"
					></feBlend>
				</filter>
				<filter
					id="filter1_f_1_940"
					width="12.539"
					height="24.567"
					x="19.595"
					y="18.774"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_1_940"
						stdDeviation="0.625"
					></feGaussianBlur>
				</filter>
				<filter
					id="filter2_f_1_940"
					width="7.351"
					height="15.47"
					x="18.242"
					y="23.358"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_1_940"
						stdDeviation="0.75"
					></feGaussianBlur>
				</filter>
				<radialGradient
					id="paint0_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="matrix(-19.23529 -.1766 .26632 -29.00818 40.002 38.185)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF953D"></stop>
					<stop offset="1" stopColor="#FF5141"></stop>
				</radialGradient>
				<radialGradient
					id="paint1_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(-157.937 15.61 14.4) scale(11.6371 20.0807)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE5327"></stop>
					<stop offset="1" stopColor="#CE5327" stopOpacity="0"></stop>
				</radialGradient>
				<linearGradient
					id="paint2_linear_1_940"
					x1="33.628"
					x2="33.628"
					y1="49.687"
					y2="43.952"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF7583"></stop>
					<stop offset="1" stopColor="#FF7583" stopOpacity="0"></stop>
				</linearGradient>
				<radialGradient
					id="paint3_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(24.015 -66.385 59.767) scale(3.49273 28.9115)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFAA7B"></stop>
					<stop offset="1" stopColor="#FFAA7B" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint4_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="matrix(.94837 4.21502 -5.25938 1.18335 23.474 23.031)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF5E47"></stop>
					<stop offset="1" stopColor="#FF5E47" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint5_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(87.882 6.643 25.103) scale(11.4046 10.5561)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF2F3C"></stop>
					<stop offset="1" stopColor="#FF2F3C" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint6_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(25.328 -33.3 74.423) scale(2.58631 5.19021)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF846C"></stop>
					<stop offset="1" stopColor="#FF846C" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint7_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(113.224 5.191 21.52) scale(2.56316 .58737)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFA682"></stop>
					<stop offset="1" stopColor="#FFA682" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint8_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(-168.558 20.602 19.866) scale(11.2827 14.0502)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFDA2F"></stop>
					<stop offset="1" stopColor="#FF8E41"></stop>
				</radialGradient>
				<radialGradient
					id="paint9_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="matrix(5.69033 14.85792 -12.90952 4.94413 26.95 25.666)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FD5639"></stop>
					<stop offset="1" stopColor="#FE5533" stopOpacity="0"></stop>
				</radialGradient>
				<radialGradient
					id="paint10_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(174.239 16.336 20.475) scale(11.0235 26.9903)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.628" stopColor="#D7812D" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#D7812D"></stop>
				</radialGradient>
				<linearGradient
					id="paint11_linear_1_940"
					x1="31.566"
					x2="31.566"
					y1="27.939"
					y2="32.041"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F95131"></stop>
					<stop offset="1" stopColor="#F95131" stopOpacity="0"></stop>
				</linearGradient>
				<radialGradient
					id="paint12_radial_1_940"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="matrix(-14.43642 10.85375 -7.854 -10.44649 38.86 37.153)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.772" stopColor="#F18A52" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#F18A52"></stop>
				</radialGradient>
				<linearGradient
					id="paint13_linear_1_940"
					x1="29.87"
					x2="21.612"
					y1="20.787"
					y2="41.574"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF7558"></stop>
					<stop offset="1" stopColor="#F38758"></stop>
				</linearGradient>
				<linearGradient
					id="paint14_linear_1_940"
					x1="23.734"
					x2="19.28"
					y1="25.162"
					y2="37.914"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF815B"></stop>
					<stop offset="1" stopColor="#FF9C6D"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default StreakIcon;
