import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { summaryHistory } from "../../../actions/summary";
import { useDebounce } from "../../function";
import format from "date-fns/format";
import ModalDialog from "react-bootstrap/ModalDialog";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import { useGetHistoryQuery } from "../../../redux/features/apis/summaryApi";
import SortIcon from "../../icons/SortIcon";
import SearchIcon from "../../icons/SearchIcon";

const tableHeader = {
	FILENAME: "FILENAME",
	ORIGINALWORDCOUNT: "ORIGINALWORDCOUNT",
	SUMMARYWORDCOUNT: "SUMMARYWORDCOUNT",
	SUMMARYDATE: "SUMMARYDATE",
};

interface summaryDataProps {
	name: string;
	id: string;
	wordcount_original: number;
	wordcount_summary: number;
	date_created: string;
	generated_summary: string;
}

function History() {
	const { data: summaryData } = useGetHistoryQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	const [showModal, setShowModal] = useState(false);
	const [query, setQuery] = useState<string>("");
	const [summary, setSummary] = useState<summaryDataProps[]>(summaryData);
	const [generatedSummary, setGeneratedSummary] = useState("");

	const handleCloseModal = () => setShowModal(false);

	useEffect(() => {
		setSummary(summaryData);
	}, [summaryData]);

	const debouncedQuery: string = useDebounce<string>(query, 500);

	useEffect(() => {
		if (debouncedQuery) {
			setSummary(
				summaryData.filter((item: summaryDataProps) =>
					item.name.toLowerCase().includes(debouncedQuery.toLowerCase())
				)
			);
		} else {
			setSummary(summaryData);
		}
	}, [debouncedQuery]);

	const handleSort = (sortBy: string) => {
		if (sortBy === tableHeader.FILENAME) {
			console.log("sort by filename");
			setSummary((prev: summaryDataProps[]) =>
				[...prev].sort((a: summaryDataProps, b: summaryDataProps) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				})
			);
		} else if (sortBy === tableHeader.ORIGINALWORDCOUNT) {
			setSummary((prev: summaryDataProps[]) =>
				[...prev].sort(
					(a: summaryDataProps, b: summaryDataProps) =>
						a.wordcount_original - b.wordcount_original
				)
			);
		} else if (sortBy === tableHeader.SUMMARYWORDCOUNT) {
			setSummary((prev: summaryDataProps[]) =>
				[...prev].sort(
					(a: summaryDataProps, b: summaryDataProps) =>
						a.wordcount_summary - b.wordcount_summary
				)
			);
		} else if (sortBy === tableHeader.SUMMARYDATE) {
			setSummary((prev: summaryDataProps[]) =>
				[...prev].sort((a: summaryDataProps, b: summaryDataProps) => {
					if (new Date(a.date_created) < new Date(b.date_created)) {
						return -1;
					}
					if (new Date(a.date_created) > new Date(b.date_created)) {
						return 1;
					}
					return 0;
				})
			);
		}
	};

	return (
		<div className=" sm:w-[93%]  md:mx-auto md:w-[90%] md:pl-4 lg:ml-24 lg:w-[80%] ">
			<div className=" mb-5 w-full ">
				<div className="my-4  flex  justify-center py-2 text-2xl text-gray-500 ">
					History
				</div>
				<div className=" h-[80vh] w-full overflow-x-hidden  overflow-y-scroll   bg-white py-4 shadow-md md:text-lg">
					<div className="mx-4  mb-4 flex justify-start ">
						<span className=" -mr-4 rounded-lg border-[1px] border-r-0 py-3 px-4">
							<SearchIcon />
						</span>
						<input
							type="text"
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							placeholder="Search"
							className="w-3/4 rounded-lg border-[1px] border-l-0 py-2 px-4 pl-2 text-lg focus:outline-none lg:w-1/2"
						/>
					</div>

					<table className="table_rows mr-4 w-full">
						<thead className="left_table_border w-full  px-4">
							<tr className="text-center font-bold uppercase text-[#7C34D5]">
								<th className="cursor-pointer px-3 py-2 text-sm active:bg-[#FDF0E7] md:text-base">
									S.No
								</th>
								<th
									onClick={() => handleSort(tableHeader.FILENAME)}
									className="flex cursor-pointer items-center justify-center space-x-1 px-3 py-2 text-sm transition-all duration-200 active:bg-[#FDF0E7]  md:text-base "
								>
									<span>FILE NAME</span>
									<SortIcon />
								</th>
								<th
									onClick={() =>
										handleSort(tableHeader.ORIGINALWORDCOUNT)
									}
									className="hidden cursor-pointer px-3 py-2 transition-all  duration-200 active:bg-[#FDF0E7] sm:table-cell"
								>
									<span className="flex items-center justify-center space-x-1 text-sm md:text-base">
										Original Wordcount <SortIcon />
									</span>
								</th>
								<th
									onClick={() =>
										handleSort(tableHeader.SUMMARYWORDCOUNT)
									}
									className="hidden cursor-pointer px-3 py-2 transition-all duration-200 active:bg-[#FDF0E7] sm:table-cell "
								>
									<span className="flex items-center justify-center space-x-1 text-sm md:text-base">
										SUMMARY WORDCOUNT <SortIcon />
									</span>
								</th>
								<th
									onClick={() => handleSort(tableHeader.SUMMARYDATE)}
									className="flex cursor-pointer items-center justify-center space-x-1 px-3 py-2 text-sm transition-all duration-200 active:bg-[#FDF0E7] md:text-base "
								>
									<span>SUMMARY DATE</span>
									<SortIcon />
								</th>
							</tr>
						</thead>
						<tbody className="w-full">
							{summary?.map((item: any, index: number) => (
								<tr
									className="cursor-pointer text-center hover:bg-[#FDF0E7]"
									key={index}
									onClick={() => {
										setGeneratedSummary(item.generated_summary);
										setShowModal(true);
									}}
								>
									<td className=" py-4">{index + 1}</td>
									<td className=" py-4 capitalize ">{item.name}</td>
									<td className="hidden py-4 sm:table-cell">
										{item.wordcount_original}
									</td>
									<td className="hidden py-4 sm:table-cell">
										{item.wordcount_summary}
									</td>
									<td className=" py-4">
										{format(
											new Date(item.date_created),
											"dd MMM yyyy"
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* generated summary  */}
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Summary</Modal.Title>
				</Modal.Header>
				<Modal.Body>{generatedSummary.slice(2, -2)}</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={handleCloseModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default History;
