import React, { Fragment, useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Navbar1 from "./components/Navbar1";
import Sidebar from "./components/Sidebar";
import History from "./pages/History";
import HomeNew from "./pages/HomeNew";
import SavedContent from "./pages/SavedContent";
import SummarizeByCopyPaste from "./pages/SummarizeByCopyPaste";
import SummarizeForSocialMedia from "./pages/SummarizeForSocialMedia";

// redux
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/features/slices/authSlice";
import { useTypedSelector } from "../../redux/hooks/useTypedSelector";
import TagsModal from "./components/TagsModal";
import { useGetTagsQuery } from "../../redux/features/apis/contentApi";
import { useLazyLoadUserQuery } from "../../redux/features/apis/authApi";
import Joyride from "react-joyride";
import { Tabs } from "./components/Contents";
import { removeSpaces } from "../../utils/useful";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ContentInspiration from "./pages/ContentInspiration";

// User Routes
import UserRoute from "../auth/UserRoute";
import CanvaPage from "./pages/CanvaPage";
import TwitterPage from "./pages/TwitterPage";
import UpgradePlan from "./pages/UpgradePlan";

const defaultOptions = {
	arrowColor: "#fff",
	backgroundColor: "#fff",
	beaconSize: 36,
	overlayColor: "rgba(0, 0, 0, 0.5)",
	primaryColor: "#F89B55",
	spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
	textColor: "#333",
	width: undefined,
	zIndex: 100,
};

const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { width } = useWindowDimensions();

	const [show, setShow] = useState(false);
	const [steps, setSteps] = useState([
		{
			target: "#j_contents",
			content: "Get recommendations from different sources",
		},
		{
			target: `#${removeSpaces(Tabs.TRENDING)}`,
			content: "Get the latest trending blogs",
		},
		{
			target: `#${removeSpaces(Tabs.BLOGS)}`,
			content: "Get blogs recommended for you",
		},
		{
			target: `#${removeSpaces(Tabs.TWITTER)}`,
			content: "Get tweets recommended for you",
		},
		{
			target: `#${removeSpaces(Tabs.YOUTUBE)}`,
			content: "Get youtube videos recommended for you",
		},
		//if screen width  greater than 640px, show this step
		...(width > 640
			? [
					{
						target: `#${removeSpaces(Tabs.CREATORS)}`,
						content: "Find the best content creators",
					},
			  ]
			: []),
		{
			target: `#j_summarize_btn`,
			content: "You can click here to go to summarize page",
		},
		{
			target: `#j_words_summarized`,
			content:
				"You can see how many words you have sumarized so far out of your plan limit",
		},
		{
			target: `#j_streak`,
			content:
				"You can see your streak here. Streak is the number of consecutive days you have summarized any content",
		},

		{
			target: `${width > 640 ? "#j_tags_desktop" : "#j_tags_mobile"}`,
			content:
				"You can update the categories you are interested in by clicking here ",
		},
	]);

	const { token, user } = useTypedSelector((state) => state.auth);
	const { data: tags, isSuccess: isSuccessTags } = useGetTagsQuery();
	const [loadUser, { data: user_data, isSuccess: isSuccessUser }] =
		useLazyLoadUserQuery();

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const path = window.location.pathname;

		// get loaduser from query params
		const loadUserParam = searchParams.get("loaduser");
		// console.log("loadUserParam", loadUserParam);
		// if loadUser is "true" , then fetch the user again
		if (loadUserParam === "true") {
			// console.log("load user param");
			if (token) {
				// console.log("token", token);
				loadUser({ token })
					.unwrap()
					.then((res) => {
						if (res) {
							dispatch(setAuth({ token, user: res }));
						}
					});
			}
		}

		if (user && isSuccessTags && tags) {
			// console.log(tags, tags.length);
			if (tags.length === 0) {
				setShow(true);
			} else if (path === "/dash") {
				navigate("/dash/home");
			}
		}
	}, [isSuccessTags, tags]);

	useEffect(() => {
		const path = window.location.pathname;
		if (path === "/dash") {
			navigate("/dash/home");
		}
		if (path === "/") {
			navigate("/dash/home");
		}
		if (!token || (user && !user.account_activated)) {
			navigate("/login");
		}

		// if (user && user.tags.length === 0) {
		// 	setShow(true);
		// }
	}, []);

	return (
		<UserRoute>
			<div className="">
				<div className="hidden sm:block">
					<Joyride
						steps={steps}
						debug={true}
						run={false}
						continuous
						disableOverlayClose
						scrollOffset={100}
						showSkipButton
						disableScrollParentFix
						disableScrolling
						// spotlightClicks
						showProgress
						styles={{
							options: defaultOptions,
						}}
					/>
				</div>
				{show && <TagsModal close={() => setShow(false)} />}
				<div className="">
					<Sidebar />
				</div>
				{/* <div className="flex w-full sm:hidden">
					<Navbar />
				</div> */}
				<div>
					<Navbar1 />
				</div>
				{/* <Home /> */}
				<div className="">
					<Routes>
						<Route path="/home" element={<HomeNew />} />
						<Route path="/canva" element={<CanvaPage />} />
						<Route path="/twitter" element={<TwitterPage />} />
						<Route
							path="/content-inspiration"
							element={<ContentInspiration />}
						/>
						<Route
							path="/saved-content"
							element={
								user?.type_of_plan == "pro" ||
								user?.type_of_plan == "super" ? (
									<SavedContent />
								) : (
									<UpgradePlan />
								)
							}
						/>
						<Route
							path="/summarize-by-copy-paste"
							element={<SummarizeByCopyPaste />}
						/>
						<Route
							path="/summarize-for-social-media"
							element={<SummarizeForSocialMedia />}
						/>
						<Route
							path="/history"
							element={
								user?.type_of_plan == "pro" ||
								user?.type_of_plan == "super" ? (
									<History />
								) : (
									<UpgradePlan />
								)
							}
						/>
					</Routes>
				</div>
			</div>
		</UserRoute>
	);
};

export default Dashboard;
