import React from "react";
import PricingCardBasicText from "./PricingCardBasicText";
import PricingCardProText from "./PricingCardProText";
import PricingCardSuperText from "./PricingCardSuperText";

interface Props {
	loadingPricing: boolean;
	currency: string;
	plan: any;
	handleShow: any;
	index: number;
}

const PricingCard = ({
	loadingPricing,
	currency,
	plan,
	handleShow,
	index,
}: Props) => {
	// console.log("plan", plan);

	return (
		<div
			className={`relative my-6 flex h-fit flex-col items-center justify-center rounded-3xl border px-2 text-center shadow-lg transition-all duration-500 hover:-translate-y-1 sm:mx-8 ${
				index % 2 === 0
					? " w-[325px] translate-y-2 bg-white py-16 "
					: "w-[375px] bg-[#FDF0E7] py-20"
			}`}
		>
			{index % 2 != 0 && (
				<div className="   absolute -top-4 w-full rounded-t-3xl bg-accentOrangeD px-3 py-2 font-semibold">
					<span className="text-lg text-white">MOST POPULAR</span>
				</div>
			)}
			<div>
				{
					<h1 className="text-xl font-bold uppercase ">
						{plan && plan?.product?.title}
					</h1>
				}

				<div
					style={{
						marginTop: "0.2rem ",
						fontWeight: "700",
						fontSize: "1.6rem",
					}}
				>
					{loadingPricing ? null : (
						<span style={{ fontWeight: "500", fontSize: "3.4rem" }}>
							{currency === "USD" ? <span>$</span> : <span>₹</span>}
							{Math.trunc(plan && plan?.unit_amount)}
						</span>
					)}
				</div>
				<>
					{plan && plan?.product?.title === "Basic" && (
						<PricingCardBasicText />
					)}
				</>
				<>
					{plan && plan?.product?.title === "Pro" && (
						<PricingCardProText />
					)}
				</>
				<>
					{plan && plan?.product?.title === "Super" && (
						<PricingCardSuperText />
					)}
				</>
				<button
					onClick={() => handleShow(index)}
					className={`mt-5 rounded-lg border bg-[#EE732E] px-10 py-2 
							  text-lg  font-semibold
							 text-white transition-all`}
				>
					Choose Plan
				</button>
			</div>
		</div>
	);
};

export default PricingCard;
