/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from "react";
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {HashLink as Link} from "react-router-hash-link";
import {browserName} from "react-device-detect";
import {Button} from "@material-ui/core";
import {logout} from "../../actions/google";
// import { store } from "../../store";
import cruxe from "../../img/blackXchotta.png";
import cruxeWhite from "../../img/whiteXchotta.png";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";

const Navbar4 = ({google: {isAuthenticated}}) => {
	const clickhandler = () => {
		// store.dispatch(logout());
	};
	const userLogin = useSelector((state) => state.auth.isAuthenticated);

	const [collapse, setCollapse] = useState(true);

	const clickhandler2 = () => {};
	const [navbar, setNavbar] = useState(false);

	const authLinks = (
		<Nav className="nav-list">
			<Nav.Link href="/about">About Us </Nav.Link>
			<Nav.Link>
				<Link to="/dashboard">Dashboard</Link>
			</Nav.Link>

			<Nav.Link>
				<Link to="/pricing">Pricing</Link>
			</Nav.Link>

			<Nav.Link>
				<Link to="#extension">
					<Button className="hidden" id="navbar-button">
						{" "}
						+ Add to {browserName}
					</Button>
				</Link>
			</Nav.Link>
			{/* {profileObj?
      <li><Link to=""><img alt='' src={profileObj.imageUrl}/><span>{profileObj.givenName}</span></Link></li>:
      ''
      } */}
			<Nav.Link>
				<Link to="" onClick={clickhandler}>
					Logout <i className="fas fa-sign-out-alt"></i>
				</Link>
			</Nav.Link>
		</Nav>
	);
	const guestLinks = (
		<Nav className="nav-list">
			<Nav.Link href="/about">About Us</Nav.Link>
			<Nav.Link href="/pricing">Pricing</Nav.Link>
			<Nav.Link href="/login">Login</Nav.Link>
			{!navbar && <Nav.Link href="#extension">Add Extension</Nav.Link>}

			<Nav.Link href="#extension">
				<Link to="#extension">
					<Button className="hidden" id="navbar-button">
						{" "}
						+ Add to {browserName}
					</Button>
				</Link>
			</Nav.Link>
		</Nav>
	);

	return (
		<Navbar collapseOnSelect expand="lg" id="md-navbar" className="fixed-top">
			<Navbar.Brand>
				<Link to="/">
					{/* {!navbar ? (
            <img src={cruxe} alt='logo' className='nav-img' />
          ) : ( */}
					<img src={cruxeWhite} alt="logo" className="nav-img" />
					{/* )} */}
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={clickhandler2} />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav
					style={{
						textAlign: "center",
						backgroundColor: !navbar && "#fcf7f4",
						borderBottom: "1px solid black",
					}}>
					{isAuthenticated || userLogin ? authLinks : guestLinks}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

Navbar4.propTypes = {
	google: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	google: state.google,
});

export default connect(mapStateToProps)(Navbar4);
