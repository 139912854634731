import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "./features/apiSlice";
import authReducer from "./features/slices/authSlice";
import otherReducer from "./features/slices/otherSlice";
import contentReducer from "./features/slices/contentSlice";
import { tweetsApi } from "./features/apis/tweetsApi";
export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		[tweetsApi.reducerPath]:tweetsApi.reducer,
		auth: authReducer,
		other: otherReducer,
		content: contentReducer,

	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
