// imported components
import RiseLoader from "react-spinners/RiseLoader";
import instaLogo from "../../../../assets/socials/insta48.png";
import linkedinLogo from "../../../../assets/socials/linkedin48.png";
import twitterLogo from "../../../../assets/socials/twitter48.png";
import youtubeLogo from "../../../../assets/socials/youtube48.png";

//redux
import {useFetchCreatorsQuery} from "../../../../redux/features/apis/contentApi";

function Creators() {
	const {data: creators, isFetching} = useFetchCreatorsQuery();
	return (
		<div>
			<div className="sm:grid-cols-2 lg:mx-10 grid grid-cols-1 gap-4">
				{creators
					? Array.from(creators[0]).map((creator: any, idx) => (
							<div className="flex flex-row mb-4 bg-[#C2AEFE]/10  p-3 rounded-lg" key={idx}>
								<div className="w-[200px] ">
									<img
										src={creator.profile_photo_url}
										alt="profile"
										className="max-w-[200px] rounded   "
									/>
								</div>
								<div className=" flex flex-col ml-2">
									<div className="text-black/75 mb-2 text-lg font-bold">{creator.name}</div>
									<div className="mb-2 text-xs">{creator.brief_description}</div>
									<div className="flex flex-row items-center mt-2">
										{creator.instagram_link && (
											<a
												href={creator.instagram_link}
												target="_blank"
												rel="noreferrer"
												className="cursor-pointer max-w-[40px] mr-2">
												<img src={instaLogo} alt="instagram" />
											</a>
										)}
										{creator.linkedin_link && (
											<a
												href={creator.linkedin_link}
												target="_blank"
												rel="noreferrer"
												className="cursor-pointer max-w-[40px] mr-2">
												<img src={linkedinLogo} alt="linkedin" />
											</a>
										)}
										{creator.twitter_link && (
											<a
												href={creator.twitter_link}
												target="_blank"
												rel="noreferrer"
												className="cursor-pointer max-w-[40px] mr-2">
												<img src={twitterLogo} alt="twitter" />
											</a>
										)}
										{creator.youtube_link && (
											<a
												href={creator.youtube_link}
												target="_blank"
												rel="noreferrer"
												className="cursor-pointer max-w-[40px] mr-2">
												<img src={youtubeLogo} alt="youtube" />
											</a>
										)}
									</div>
								</div>
							</div>
					  ))
					: null}
			</div>
			<div className="flex justify-center p-4 mb-4">
				{isFetching && (
					<div className="">
						<RiseLoader color="#f89b55" />
					</div>
				)}
			</div>
		</div>
	);
}

export default Creators;
