import React from "react";
import InstaIcon from "./InstaIcon";
import { Page } from "../../components/dashboard/components/Sidebar";
import PageIcon from "./PageIcon";
import HomeIcon from "./HomeIcon";
import HistoryIcon from "./HistoryIcon";
import AddIcon from "./AddIcon";
import SavedIcon from "./SavedIcon";
import PriceIcon from "./PriceIcon";
import CanvaIcon from "./CanvaIcon";

interface SideBarIconProps {
	name: Page;
	isSelected: boolean;
}

const SideBarIcon = ({ name, isSelected }: SideBarIconProps) => {
	return (
		<>
			{name === Page.SUMMARIZE_FOR_SOCIAL_MEDIA && (
				<>
					<InstaIcon width={25} height={25} isSelected={isSelected} />
					<div
						className={`transition-transform-2ms  ${
							isSelected && "-translate-y-1 "
						} `}
					>
						<span
							className={`text-sm font-medium  ${
								isSelected ? "text-[#EE732E]" : "text-[#858585]"
							} `}
						>
							Summarize
						</span>
					</div>
				</>
			)}
			{name === Page.SUMMARIZE_BY_COPY_PASTE && (
				<PageIcon width={25} height={25} isSelected={isSelected} />
			)}
			{name === Page.HOME && (
				<>
					<HomeIcon width={25} height={25} isSelected={isSelected} />
					<div
						className={`transition-transform-2ms ${
							isSelected && "-translate-y-1"
						} `}
					>
						<span
							className={`text-sm font-medium  ${
								isSelected ? "text-[#EE732E]" : "text-[#858585]"
							} `}
						>
							Home
						</span>
					</div>
				</>
			)}
			{name === Page.HISTORY && (
				<>
					<HistoryIcon width={25} height={25} isSelected={isSelected} />
					<div
						className={`transition-transform-2ms   ${
							isSelected && "-translate-y-1"
						} `}
					>
						<span
							className={`text-sm font-medium  ${
								isSelected ? "text-[#EE732E]" : "text-[#858585]"
							} `}
						>
							History
						</span>
					</div>
				</>
			)}
			{name === Page.ADD_EXTENSION && <AddIcon width={28} height={28} />}
			{name === Page.SAVED_CONTENT && (
				<>
					<SavedIcon width={29} height={29} isSelected={isSelected} />
					<div
						className={`transition-transform-2ms -mt-1  ${
							isSelected && "-translate-y-1"
						} `}
					>
						<span
							className={`text-sm font-medium  ${
								isSelected ? "text-[#EE732E]" : "text-[#858585]"
							} `}
						>
							Saved
						</span>
					</div>
				</>
			)}
			{name === Page.CANVA && (
				<>
					<CanvaIcon width={29} height={29} isSelected={isSelected} />
					<div
						className={`transition-transform-2ms -mt-1  ${
							isSelected && "-translate-y-1"
						} `}
					>
						<span
							className={`text-sm font-medium  ${
								isSelected ? "text-[#EE732E]" : "text-[#858585]"
							} `}
						>
							Canva
						</span>
					</div>
				</>
			)}

			{name === Page.PRICING && (
				<PriceIcon width={27} height={27} isSelected={isSelected} />
			)}
			{/* bottom bar on selected  */}

			<div
				className={`transition-width-2ms absolute bottom-0 left-0 ${
					isSelected ? " w-full" : " w-0"
				} h-1 rounded-full bg-accentOrange  `}
			></div>

			{/* hover popup */}
			{/* <div className="tab-hover transition-width-2ms absolute  top-[-5px]">
				{name.replaceAll("-", " ")}
			</div> */}
		</>
	);
};

export default SideBarIcon;
