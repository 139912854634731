import { useState } from "react";

import Blogs from "./contents/Blogs";
import Twitter from "./contents/Twitter";
import Youtube from "./contents/Youtube";
import Reddit from "./contents/Reddit";
import Creators from "./contents/Creators";
import { FaBlogger } from "react-icons/fa";
import {
	AiFillRedditCircle,
	AiFillYoutube,
	AiOutlineTwitter,
	AiFillFire,
} from "react-icons/ai";
import { BiTrendingUp } from "react-icons/bi";
import Trending from "./contents/Trending";
import ContentHeading from "./contents/comp/ContentHeading";

export enum Tabs {
	TRENDING = "Trending",
	BLOGS = "Hand Picked",
	TWITTER = "Twitter",
	YOUTUBE = "Youtube",
	REDDIT = "Reddit",
	CREATORS = "Creators to follow",
}

function Contents({ isSaved }: { isSaved: boolean }) {
	const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.BLOGS);

	return (
		<div className="z-10 mt-0 flex flex-col ">
			<nav
				id="j_contents"
				className=" sticky top-0 z-[99] mt-0 mb-2 flex flex-row justify-between overflow-x-auto rounded-t-md border-b-[1px] bg-white  py-2 pt-2 font-lato "
			>
				<ContentHeading
					heading={Tabs.TRENDING}
					selectedTab={selectedTab}
					select={() => setSelectedTab(Tabs.TRENDING)}
					Icon={BiTrendingUp}
					color="#e87b1d"
				/>
				<ContentHeading
					heading={Tabs.BLOGS}
					selectedTab={selectedTab}
					select={() => setSelectedTab(Tabs.BLOGS)}
					Icon={FaBlogger}
					color="#e87b1d"
				/>
				<ContentHeading
					heading={Tabs.TWITTER}
					selectedTab={selectedTab}
					select={() => setSelectedTab(Tabs.TWITTER)}
					Icon={AiOutlineTwitter}
					color="#1d9bf0"
				/>
				<ContentHeading
					heading={Tabs.YOUTUBE}
					selectedTab={selectedTab}
					select={() => setSelectedTab(Tabs.YOUTUBE)}
					Icon={AiFillYoutube}
					color="#FF0000"
				/>
				{/* <ContentHeading
					heading={Tabs.REDDIT}
					selectedTab={selectedTab}
					select={() => setSelectedTab(Tabs.REDDIT)}
					icon={<AiFillRedditCircle color="#ff4500" />}
				/> */}
				{/* {!isSaved && (
					<ContentHeading
						heading={Tabs.CREATORS}
						selectedTab={selectedTab}
						select={() => setSelectedTab(Tabs.CREATORS)}
					/>
				)} */}
			</nav>

			{selectedTab === Tabs.TRENDING && <Trending isSaved={isSaved} />}
			{selectedTab === Tabs.BLOGS && <Blogs isSaved={isSaved} />}
			{selectedTab === Tabs.TWITTER && <Twitter isSaved={isSaved} />}
			{selectedTab === Tabs.YOUTUBE && <Youtube isSaved={isSaved} />}
			{/* {selectedTab === Tabs.REDDIT && <Reddit isSaved={isSaved} />} */}
			{selectedTab === Tabs.CREATORS && !isSaved && <Creators />}
		</div>
	);
}

export default Contents;
