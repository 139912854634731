import React from "react";
import { Link } from "react-router-dom";
// icons
import { HiOutlineEmojiSad } from "react-icons/hi";
const UpgradePlan = () => {
	return (
		<div className="flex h-screen w-full items-center justify-center ">
			<div className="flex translate-y-[-100px] flex-col items-center justify-center text-center">
				<HiOutlineEmojiSad className="text-8xl font-thin text-gray-300" />
				<p className=" max-w-[700px] text-sm font-semibold text-red-500  sm:text-base md:text-lg ">
					It looks like you need to upgrade/Buy Plan to use this feature
				</p>
				<div className="mt-4 flex flex-col items-center justify-center">
					<Link style={{ textDecoration: "none" }} to="/pricing">
						<span className="rounded-md bg-[#EE732E] py-2 px-4 font-semibold  text-white ">
							Upgrade Plan
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default UpgradePlan;
