import React from "react";

const PricingCardProText = () => {
	const CardDivStyle = { marginBottom: "0.5rem", fontSize: "1rem" };
	return (
		<div style={{ marginTop: "1rem ", textAlign: "left" }}>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				All in Basic Plan
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				Summarise upto 10k words
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				50 hand pick Articles of your Niche
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				Summarise by URL/Copy Paste
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				Save your content
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-circle-check fa-sm"
					style={{ color: "green", marginRight: "0.6rem" }}
				></i>
				24/7 Support
			</div>
			<div style={CardDivStyle}>
				<i
					className="fa-solid fa-times-circle fa-sm"
					style={{ color: "red", marginRight: "0.6rem" }}
				></i>
				Api access
			</div>
		</div>
	);
};

export default PricingCardProText;
