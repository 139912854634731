import React from "react";
import { IconProp } from "../assetInterfaces";

const HistoryIcon = ({ width, height, isSelected }: IconProp) => {
	return (
		<>
			<svg
				width={width}
				height={height}
				viewBox="0 0 23 24"
				fill="gray"
				className={`transition-transform-2ms translate-x-[2px]  ${
					isSelected
						? "-translate-y-1 fill-[#EE732E] hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22.9926 12.0941V12.1161C22.9926 18.4657 17.8454 23.6129 11.4958 23.6129C8.8604 23.6171 6.30427 22.7118 4.25895 21.0499L4.28003 21.0671C4.15897 20.9688 4.05991 20.8462 3.98926 20.7072C3.91861 20.5682 3.87795 20.4159 3.86991 20.2602C3.86187 20.1044 3.88663 19.9487 3.94259 19.8032C3.99855 19.6577 4.08446 19.5255 
				4.19475 19.4153L4.71694 18.8931C4.90959 18.6986 5.16714 18.5819 5.44039 18.5653C5.71364 18.5486 5.98344 18.6333 6.19823 18.803L6.19631 18.8011C7.70197 19.9994 9.57053 20.6499 11.4948 20.6455C12.8868 20.6455 14.2575 20.3049 15.4875 19.6534C16.7176 19.0019 17.7695 18.0593 18.5515 16.9079C19.3335 15.7565 19.8219 14.4311 19.9741 13.0476C20.1262 11.664 19.9375 10.2642 19.4243 8.97037C18.9112 7.6765 18.0892 6.52786 17.0302 5.62465C15.9711 4.72145 14.7071 4.09111 13.3485 3.78865C11.9898 3.48619 10.5778 3.52078 9.23562 3.88942C7.89342 4.25806 6.66181 4.94953 5.64826 5.90352L5.65305 5.89969L8.00625 8.25289C8.1099 8.35666 8.18046 8.48884 8.209 8.63271C8.23753 8.77658 8.22277 8.92568 8.16657 9.06116C8.11037 9.19664 8.01525 9.31241 7.89326 9.39383C7.77126 9.47525 7.62786 9.51867 7.48118 9.5186H0.741603C0.544918 9.5186 0.356288 9.44046 0.217211 9.30139C0.078133 9.16231 0 8.97368 0 8.77699V2.03741C0.000379047 1.89094 0.0440664 1.74786 0.125566 1.62616C0.207066 1.50445 0.322737 1.40957 0.458028 1.35345C0.593319 1.29733 0.742186 1.28247 0.885901 1.31074C1.02962 1.33901 1.16176 1.40916 1.26571 1.51235L3.55471 3.80136C5.6914 1.754 8.53753 0.61301 11.4968 0.617445C17.8387 0.617445 22.982 5.75309 22.9935 12.0922V12.0931L22.9926 12.0941ZM14.6059 15.7456L15.0611 15.1601C15.151 15.0451 15.2174 14.9135 15.2563 14.7727C15.2953 14.632 15.3061 14.485 15.2881 14.34C15.2701 14.1951 15.2237 14.0552 15.1516 13.9282C15.0794 13.8013 14.9829 13.6898 14.8675 13.6003L14.8646 13.5984L12.9781 12.1305V7.29379C12.9781 6.67962 12.4798 6.18043 11.8647 6.18043H11.1231C10.5089 6.18043 10.0097 6.67866 10.0097 7.29379V13.5792L13.0422 15.9372C13.2745 16.119 13.5694 16.2013 13.8622 16.1659C14.155 16.1305 14.4218 15.9804 14.604 15.7484L14.6059 15.7456Z"
				/>
			</svg>
		</>
	);
};

export default HistoryIcon;
