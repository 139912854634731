import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const styles = {
	accordian: {
		borderRadius: "8px",
		marginBottom: "10px",
		padding: "5px 10px",
		"&:before": {
			display: "none",
		},
		"&:hover": {
			backgroundColor: "#f1f5f9",
		},
	},
};

const FAQ = () => {
	return (
		<div
			id="faq"
			className="mt-[30px]  flex w-full flex-col items-center py-10 px-3 sm:px-[100px] md:px-[200px]"
		>
			<div className="mb-10 text-2xl font-semibold sm:text-4xl md:text-5xl">
				Frequently Asked Questions {"("}FAQ's{")"}
			</div>
			<div>
				<Accordion sx={styles.accordian}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>What is Cruxe?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Cruxe is an AI based Content Recommendation System that provides you with content ideas from all the social media platforms at one place so you can spend less time on research, and more time making great content.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion sx={styles.accordian}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							Is Cruxe free to use?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							The basic features of Cruxe, including content ideas for trending topics, YouTube and Twitter, as well as hand-picked articles, are available for free. However, access to additional features, such as more summaries and additional content, requires purchasing a subscription plan.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion sx={styles.accordian}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>How can I use it to improve my content creation journey?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Our ideas are meant to inspire and guide you in creating consistent, high-quality content. You can use them as starting points, or adapt and personalize them to suit your own style and brand.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion sx={styles.accordian}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							How often is the content updated on your website?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							We are constantly updating our database with fresh and relevant ideas to help keep your content pipeline full. Our aim is to provide you with new ideas on a regular basis. All you need to do is sign up for free and start growing with us.  
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
};

export default FAQ;
