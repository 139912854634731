import React from "react";
import {IconProp} from "../assetInterfaces";

const AddIcon = ({width, height}: IconProp) => {
	return (
		<>
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" className="translate-x-[2px]">
				<path d="M15 3C8.373 3 3 8.373 3 15s5.373 12 12 12 12-5.373 12-12S21.627 3 15 3zm6 13h-5v5a1 1 0 11-2 0v-5H9a1 1 0 110-2h5V9a1 1 0 112 0v5h5a1 1 0 110 2z"></path>
			</svg>
		</>
	);
};

export default AddIcon;
