import React, { useState } from "react";

import BlogSummary from "./BlogSummary";

const TrendingArticles = ({
	blog,
	data,
	i,
	lastPostObserver,
	isSaved,
	setIsLimitOver,
	savePost,
	unsavePost,
}: {
	blog: any;
	data: any;
	i: number;
	lastPostObserver: any;
	isSaved: boolean;
	setIsLimitOver: any;
	savePost: any;
	unsavePost: any;
}) => {
	const [isSummary, setIsSummary] = useState(false);

	return (
		<article className="flex-column mx-2 mb-2 flex w-full justify-between rounded-xl border border-black/25    sm:w-[90%] lg:w-[70%]  ">
			{data?.trendingBlogs.length === i + 3 && (
				<div ref={lastPostObserver} className="absolute"></div>
			)}
			<div className="flex flex-row">
				<div>
					<img
						src={blog.media_url_photo}
						alt="blog"
						className=" mr-2 h-[150px] min-w-[150px] max-w-[150px] rounded-lg object-cover sm:mr-10 sm:h-[200px] sm:min-w-[200px] sm:max-w-[200px]"
					/>
				</div>
				<div className="flex flex-col justify-between">
					<div>
						<div className="mb-2 flex flex-row items-end">
							<img
								src={blog.website_logo_url}
								alt="website logo"
								className="mr-3 max-h-[15px]  sm:max-h-[20px]"
							/>

							<p className="m-0 text-[10px] font-semibold sm:text-sm">
								{blog.website_name}
							</p>
						</div>
						<h1 className="text-sm font-bold sm:text-xl">{blog.title}</h1>
						<div className="hidden pt-4 sm:flex">
							<BlogSummary
								id={blog.id}
								setIsSummary={setIsSummary}
								isTrending={true}
								setIsLimitOver={setIsLimitOver}
							/>
						</div>
					</div>
					<div className="hidden flex-row items-center space-x-4 sm:flex lg:space-x-12">
						{blog.saved || isSaved ? (
							<div className="">
								<span
									className="j_bookmark cursor-pointer rounded-md border-[1px]  border-orangeCrushWarm bg-orangeCrushWarm py-1 px-4 text-white"
									onClick={() =>
										unsavePost({
											post_id: blog.id,
											type_of_post: "blog_trending",
										})
									}
								>
									Saved
								</span>
							</div>
						) : (
							<>
								<div className="relative flex items-center justify-center ">
									<span
										// size="23px"
										className="j_bookmark cursor-pointer rounded-md border-[1px] border-orangeCrushWarm px-6 py-1 text-orangeCrushWarm hover:bg-[#FDF0E7]"
										onClick={() =>
											savePost({
												post_id: blog.id,
												type_of_post: "blog_trending",
											})
										}
									>
										Save
									</span>
									{/* <BeatLoader color="#f89b55" size="10px" /> */}
								</div>
							</>
						)}
						<a
							href={blog.post_url}
							target="_blank"
							rel="noreferrer"
							className=" bottom-b-1 cursor-pointer border-[#472208]  text-[#472208] "
						>
							<span className=" text-base ">visit</span>
						</a>
					</div>
					{/* <BlogSummary id={blog.id} /> */}
				</div>
			</div>

			<div
				className={`flex ${
					isSummary ? " flex-col" : "items-center justify-between"
				} pt-2 sm:hidden  sm:py-0 sm:pt-2`}
			>
				<div className="">
					<BlogSummary
						setIsSummary={setIsSummary}
						id={blog.id}
						isTrending={true}
						setIsLimitOver={setIsLimitOver}
					/>
				</div>
				<div
					className={`flex 
						 flex-row 
					 items-center space-x-2`}
				>
					<a
						href={blog.post_url}
						target="_blank"
						rel="noreferrer"
						className=" bottom-b-1 cursor-pointer border-[#472208]  text-[#472208]"
					>
						<span className=" text-base ">visit</span>
					</a>
					{blog.saved || isSaved ? (
						<div className="">
							<span
								className="j_bookmark cursor-pointer rounded-md border-orangeCrushWarm bg-orangeCrushWarm py-1 px-4 text-sm text-white"
								onClick={() =>
									unsavePost({
										post_id: blog.id,
										type_of_post: "blog_trending",
									})
								}
							>
								Saved
							</span>
						</div>
					) : (
						<>
							<div className="relative flex items-center justify-center ">
								<span
									// size="23px"
									className="j_bookmark cursor-pointer rounded-md border-[1px] border-orangeCrushWarm px-6 py-1 text-sm text-orangeCrushWarm hover:bg-[#FDF0E7]"
									onClick={() =>
										savePost({
											post_id: blog.id,
											type_of_post: "blog_trending",
										})
									}
								>
									Save
								</span>
								{/* <BeatLoader color="#f89b55" size="10px" /> */}
							</div>
						</>
					)}
				</div>
			</div>
		</article>
	);
};

export default TrendingArticles;
