import {apiSlice} from "../apiSlice";

const pricingApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getPricing: build.query<any, {planType: string; currency: string}>({
			query: ({planType, currency}) => ({
				url: `/products/plans/${planType}/${currency}/`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		getCoupon: build.query<any, {coupon: string}>({
			query: ({coupon}) => ({
				url: `/products/couponInfo/${coupon}/`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		getPricingById: build.query<any, {id: string}>({
			query: ({id}) => ({
				url: `/products/plan/${id}`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}),
		}),
		payAmount: build.mutation<any, {plan_id: string; coupon: string | undefined}>({
			query: ({plan_id, coupon}) => ({
				url: `/products/pay/`,
				method: "POST",
				body: {
					type: "original",
					plan_id,
					coupon_code: coupon,
				},
			}),
		}),
	}),
	overrideExisting: false,
});

export const {usePayAmountMutation, useLazyGetPricingQuery, useLazyGetCouponQuery} = pricingApi;
