import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Modal,
	Button,
	DropdownButton,
	Dropdown,
} from "react-bootstrap";
import Logo from "../../img/CruxeLogo-01.png";
import Faq from "../landing/Faq";
// import {getPricing, getCoupon, getPricingById, payAmount, payAmount1, removePayAmount} from "../../actions/pricing";
import { redirectLogin } from "../../actions/google";
import { Link } from "react-router-dom";

// redux
import { useTypedSelector } from "../../redux/hooks/useTypedSelector";
import {
	usePayAmountMutation,
	useLazyGetPricingQuery,
	useLazyGetCouponQuery,
} from "../../redux/features/apis/pricingApi";
import Navbar from "../landing/comp/Navbar";
import { AiOutlineClose } from "react-icons/ai";
import PricingCard from "./comp/PricingCard";

const Pricing1 = () => {
	const navigate = useNavigate();
	// plans setplans
	const [plans, setPlans] = useState<any>([]);
	const { token } = useTypedSelector((state) => state.auth);

	// api hooks
	const [
		payAmount,
		{
			data: payData,
			isLoading: loadingPayAmount,
			isSuccess: successPayAmount,
		},
	] = usePayAmountMutation();

	const [
		getPricing,
		{
			data: plansData,
			isFetching: loadingPricing,
			isSuccess: successPricing,
		},
	] = useLazyGetPricingQuery();

	const [
		getCoupon,
		{
			data: couponData,
			isFetching: loadingCoupon,
			isSuccess: successCoupon,
			isError: errorCoupon,
		},
	] = useLazyGetCouponQuery();

	const [show, setShow] = useState(false);
	const [coupon, setCoupon] = useState("");
	const [timelyPlan, setTimelyPlan] = useState(true);
	const [currency, setcurrency] = useState("INR");
	// const [chosenPlanId, setChosenPlanId] = useState("");
	const [chosenPlanIndex, setChosenPlanIndex] = useState(0);

	const [toggle, setToggle] = useState(false);
	const clickHandler = () => {
		if (token) {
			if (couponData?.discount) {
				// payAmount(google.user.access_token, id, coupon);
				payAmount({ plan_id: plans[chosenPlanIndex]?.id, coupon });
			} else {
				// payAmount(google.user.access_token, id);
				payAmount({
					plan_id: plans[chosenPlanIndex]?.id,
					coupon: undefined,
				});
			}
			setToggle(true);
			// if (payData) temp1();
		} else {
			// dispatch(redirectLogin(google.isAuthenticated ? false : true));
			navigate("/login");
		}
	};

	let temp;
	const temp1 = () => {
		temp = newScript();
		console.log("remove");
		// onScriptLoad();
		// dispatch(removePayAmount());
	};

	const newScript = () => {
		var options = {
			key: payData?.merchant_id, // Enter the Key ID generated from the Dashboard
			amount: payData?.unit_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			currency: currency,
			name: "Cruxe",
			// description: "Test Transaction",
			// image: "https://example.com/your_logo",
			order_id: payData?.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
			callback_url: payData?.callback_url,
			prefill: {
				name: "Gaurav Kumar",
				email: "gaurav.kumar@example.com",
				contact: "9999999999",
			},

			// only add the following if currency is USD
			...(currency === "USD"
				? {
						config: {
							display: {
								hide: [
									{
										method: "card",
									},
								],
								preferences: {
									show_default_blocks: true,
								},
							},
						},
				  }
				: []),
			notes: {
				address: "Razorpay Corporate Office",
			},
			// theme: {
			// 	color: "#3399cc",
			// },
		};
		var rzp1: any = new (window as any).Razorpay(options);
		rzp1.open();
	};

	const SuccessHandlerToggled = () => {
		if (payData && toggle) temp1();
	};

	useEffect(() => {
		SuccessHandlerToggled();
	}, [payData, toggle]);

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = (index: number) => {
		setChosenPlanIndex(index);
		setShow(true);
	};

	const handleCoupon = () => {
		if (token) {
			// dispatch(getCoupon(coupon));
			getCoupon({ coupon }, true);
		} else {
			navigate("/login");
		}
	};

	const removeCoupon = () => {
		setCoupon("");
		// dispatch(getCoupon(""));
		// document.getElementsByClassName("promo").value = null;
	};

	useEffect(() => {
		if (timelyPlan) {
			// dispatch(getPricing("month", currency));
			getPricing({ planType: "month", currency }, true);
		} else {
			// dispatch(getPricing("year", currency));
			getPricing({ planType: "year", currency }, true);
		}
	}, [timelyPlan, currency]);
	console.log("planes pricing", plansData);

	useEffect(() => {
		// sort plan according to pricing
		if (plansData) {
			setPlans(
				plansData
					.slice()
					.sort((a: any, b: any) => a.unit_amount - b.unit_amount)
			);
		}
	}, [plansData]);

	return (
		<div>
			<Navbar />
			<Container fluid>
				<Row style={{ textAlign: "center" }}>
					<Col>
						<h1 className="text-2xl font-semibold text-gray-600 sm:text-4xl">
							Ready to start with{" "}
							<span className="text-[#EE732E]">Cruxe Premium</span>
						</h1>
						<div style={{ fontSize: "1.2rem" }}>
							Get a 7-days free trial
						</div>
					</Col>
				</Row>
				<Row style={{ margin: "2rem 0" }}>
					<div className="flex w-full flex-col items-center space-y-2 sm:flex-row sm:justify-between sm:space-y-2 ">
						<div className="flex flex-row  items-center sm:mx-auto">
							Monthly
							<label className="switch">
								<input
									type="checkbox"
									onClick={() => {
										setTimelyPlan(!timelyPlan);
									}}
								></input>
								<span className="slider-round"></span>
							</label>
							Yearly (save 10%)
						</div>
						<div className="flex h-fit w-fit items-center justify-center rounded-lg bg-[#FAE2CF] font-semibold sm:mr-2  ">
							<span
								onClick={() => setcurrency("INR")}
								className={` ${
									currency == "INR"
										? "bg-[#EE732E] text-white"
										: "text-gray-400"
								} cursor-pointer rounded-lg  px-4 py-2`}
							>
								INR
							</span>
							<span
								onClick={() => setcurrency("USD")}
								className={` ${
									currency == "USD"
										? "bg-[#EE732E] text-white"
										: "text-gray-400"
								} cursor-pointer rounded-lg px-4 py-2`}
							>
								USD
							</span>

							{/* <DropdownButton
								id="dropdown-basic-button"
								title={currency}
							>
								<Dropdown.Item
									href="#"
									onClick={() => setcurrency("INR")}
									style={{}}
								>
									₹ INR
								</Dropdown.Item>
								<Dropdown.Item
									href="#"
									onClick={() => setcurrency("USD")}
								>
									$ USD
								</Dropdown.Item>
							</DropdownButton> */}
						</div>
					</div>
				</Row>
				<div className="flex flex-wrap justify-center">
					{plans &&
						plans.map((plan: any, index: number) => (
							<PricingCard
								loadingPricing={loadingPricing}
								currency={currency}
								plan={plan}
								key={index}
								index={index}
								handleShow={(index: number) => handleShow(index)}
							/>
						))}
				</div>
				<Modal show={show} onHide={handleClose} centered>
					<Modal.Body className="relative">
						<span
							onClick={handleClose}
							className="absolute -top-6 -right-1 cursor-pointer text-2xl"
						>
							<AiOutlineClose className="  text-gray-900 hover:text-black " />
						</span>

						<div className="absolute left-[50%] -top-4 mb-4 -translate-x-[60px] rounded-lg bg-orangeCrushWarm px-4 py-2 text-center font-semibold uppercase text-white ">
							{plans && plans[chosenPlanIndex]?.product?.title} Plan
						</div>
						{loadingPricing ? null : (
							<div className="mt-8 text-center text-xl ">
								Cost:
								{currency == "USD" ? <span>$</span> : <span>₹</span>}
								{plans && plans[chosenPlanIndex]?.unit_amount}
								/-
							</div>
						)}
						<div className="my-4 text-center text-gray-500">
							<p>
								Congratulations! You've chosen a step towards better content with us. Get ready to elevate your journey with quality content!
							</p>
						</div>

						<div className="flex space-x-2 px-6">
							<input
								onChange={(e) => setCoupon(e.target.value)}
								placeholder="Do you have a promo-code?"
								className="promo w-full border p-2 focus:border-orangeCrushWarm focus:outline-none"
							></input>
							<div className="flex justify-end shadow-md">
								<button
									onClick={handleCoupon}
									style={{
										background: "white",
										border: "1px solid #F89B55",
										padding: "0.3rem 2rem",
										color: "#F89B55",
										borderRadius: "5px",
									}}
									className="transition-all duration-500 hover:bg-accentOrangeD hover:text-white"
								>
									{" "}
									Apply
								</button>
							</div>

							{couponData?.discount ? (
								<button
									onClick={removeCoupon}
									style={{
										background: "red",
										border: "1px solid red",
										padding: "0.3rem 2rem",
										color: "white",
										borderRadius: "5px",
										margin: "0 0.4rem",
									}}
								>
									{" "}
									Remove
								</button>
							) : null}
						</div>
						{couponData?.discount ? (
							<div>
								<div style={{ color: "green" }}>
									Congratulations, coupon is applied. You are getting{" "}
									{couponData.discount}% off!
								</div>
								<div>
									Final Cost:{" "}
									{currency == "USD" ? <span>$</span> : <span>₹</span>}
									{plans &&
										plans[chosenPlanIndex]?.unit_amount *
											(1 - couponData.discount / 100)}
									/-
								</div>
							</div>
						) : null}
						{errorCoupon || couponData?.code == "404" ? (
							<div style={{ color: "red" }}>
								Not a valid coupon. Please try another!
							</div>
						) : null}
						<div style={{ textAlign: "center" }}>
							{loadingPricing ? null : (
								<Button
									onClick={clickHandler}
									style={{
										padding: "0.5rem 3rem",
										background: "#ee732e",
										border: "0",
										borderRadius: "5px",
										margin: "1rem auto",
									}}
									className="transition-all duration-500 hover:bg-accentOrangeD "
								>
									Checkout
								</Button>
							)}
						</div>
					</Modal.Body>
				</Modal>

				{/* <Row>
					<Col
						style={{
							textAlign: "center",
							marginTop: "3rem",
							marginBottom: "2rem",
							color: "#475569",
							fontSize: "2.2rem",
						}}
					>
						<h2>Are you a student?</h2>
						Studends get special price
						<div>Enter your institute's email ID</div>
					</Col>
				</Row> */}
				{/* <Row>
					<Faq />
				</Row> */}
			</Container>
		</div>
	);
};

export default Pricing1;
