import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { HiOutlineEmojiSad } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
	isLimitOver: boolean;
	setIsLimitOver: (isLimitOver: boolean) => void;
}

function WordLimitOver({ isLimitOver, setIsLimitOver }: Props) {
	const handleClose = () => {
		setIsLimitOver(false);
	};

	return (
		<Modal show={isLimitOver} onHide={handleClose} centered>
			<Modal.Body className="relative text-center ">
				<span
					onClick={handleClose}
					className="absolute -top-6 -right-1 cursor-pointer text-2xl"
				>
					<AiOutlineClose className="  text-gray-900 hover:text-black " />
				</span>
				<div className="flex flex-col items-center">
					<HiOutlineEmojiSad className="text-8xl font-thin text-gray-300" />
					<h1 className="h1 font-bold text-gray-400">Oops!</h1>
					<p className=" text-base font-semibold  text-red-500 ">
						Your Word Limit is Over
					</p>
					<Link to="/pricing">
						<button className="mt-4 rounded-md bg-orangeCrushWarm px-4 py-2 text-lg font-semibold text-white ">
							Find a plan
						</button>
					</Link>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default WordLimitOver;
