import React, { useState } from "react";

import bell from "../../../../img/bell_icon3.png";
import { Dropdown } from "react-bootstrap";
import Avatar1 from "react-avatar";

import { useTypedSelector } from "../../../../redux/hooks/useTypedSelector";
import { logout } from "../../../../redux/features/slices/authSlice";
import { apiSlice } from "../../../../redux/features/apiSlice";

import TagsModal from "../TagsModal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { HiLogout } from "react-icons/hi";
import { AiOutlineAppstore } from "react-icons/ai";

interface AvatarDropdownProps {
	flexDirection: string;
}

function AvatarDropdown({ flexDirection }: AvatarDropdownProps) {
	const [show, setShow] = useState<boolean>(false);

	const user = useTypedSelector((state) => state.auth.user);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("auth");
		dispatch(logout());
		dispatch(apiSlice.util.resetApiState());
		//@todo navigate to /
		navigate("/login");
	};
	return (
		<div className={`flex ${flexDirection} items-center justify-between`}>
			{/* <img src={bell} alt="bell" className=" h-10 w-10  " /> */}
			<Dropdown className="bg-white" id="j_tags_desktop">
				<Dropdown.Toggle className="flex w-fit items-center justify-center rounded-full border-none bg-white p-0 shadow-xl before:content-none after:content-none">
					{<Avatar1 name={user?.firstName} size="35" round={true} />}
				</Dropdown.Toggle>

				<Dropdown.Menu className="z-[100] rounded p-2">
					<Dropdown.Item
						className="flex flex-row items-center px-3 py-1"
						onClick={() => setShow(true)}
					>
						<AiOutlineAppstore />
						<span className="ml-2">Update Categories</span>
					</Dropdown.Item>
					<Dropdown.Item
						className="flex flex-row items-center px-3 py-1"
						onClick={() => handleLogout()}
					>
						<HiLogout />
						<span className="ml-2">logout</span>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{show && <TagsModal close={() => setShow(false)} />}
		</div>
	);
}

export default AvatarDropdown;
