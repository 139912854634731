import React from "react";
import { IconProp } from "../assetInterfaces";

const HomeIcon = ({ width, height, isSelected }: IconProp) => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				fill="gray"
				className={`transition-transform-2ms translate-x-[2px]  ${
					isSelected
						? "-translate-y-1 fill-[#EE732E] hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
				viewBox="0 0 44 42"
			>
				<path d="M42.367 20.672L24.254 2.569 23.04 1.355a1.476 1.476 0 00-2.081 0L1.632 20.672a2.995 2.995 0 00-.88 2.156c.018 1.65 1.391 2.967 3.041 2.967h1.993v15.268h32.428V25.795h2.034c.802 0 1.556-.314 2.123-.88a2.982 2.982 0 00.877-2.124c0-.797-.314-1.552-.881-2.119zM24.625 37.688h-5.25v-9.563h5.25v9.563zM34.839 22.42v15.268h-7.214V27c0-1.036-.84-1.875-1.875-1.875h-7.5c-1.036 0-1.875.84-1.875 1.875v10.688H9.16V22.42h-4.5L22.004 5.09l1.083 1.084L39.343 22.42H34.84z"></path>
			</svg>
			{/* <svg
				width={width}
				height={height}
				viewBox="0 0 26 25"
				fill="black"
				className={`svg-icon transition-transform-2ms ${
					isSelected
						? "-translate-y-1  fill-accentOrange hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M25.1057 11.9046L13.4578 0.265089C13.3739 0.181056 13.2743 0.114388 13.1646 0.0689011C13.055 0.0234137 12.9374 0 12.8187 0C12.7 0 12.5824 0.0234137 12.4727 0.0689011C12.3631 0.114388 12.2635 0.181056 12.1796 0.265089L0.531638 11.9046C0.192295 12.2439 0 12.7048 0 13.1856C0 14.1838 0.811596 14.9954 1.80983 14.9954H3.03712V23.3008C3.03712 23.8014 3.44151 24.2058 3.94204 24.2058H11.0089V17.8713H14.1761V24.2058H21.6954C22.1959 24.2058 22.6003 23.8014 22.6003 23.3008V14.9954H23.8276C24.3083 14.9954 24.7692 14.8059 25.1086 14.4638C25.8127 13.7568 25.8127 12.6115 25.1057 11.9046Z" />
			</svg> */}
		</>
	);
};

export default HomeIcon;
