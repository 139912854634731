import React from "react";
import {IconProp} from "../assetInterfaces";

const PriceIcon = ({width, height, isSelected}: IconProp) => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				version="1"
				viewBox="0 0 200 200"
				className={` -translate-x-[2px] ${isSelected ? "fill-accentOrange hover:fill-accentOrange -translate-y-2" : "hover:fill-black/75 translate-y-0"} `}>
				<path
					d="M830 1816c-312-72-554-302-637-606-25-91-25-329 0-420C313 349 764 83 1202 194c570 144 814 811 473 1291-106 149-282 272-460 321-79 22-309 28-385 10zm270-391c0-40 4-55 14-55 18 0 137-37 154-48 10-7 5-26-22-85l-35-77-56 26c-66 30-171 38-198 15-43-35-14-66 107-111 147-54 204-103 226-191 23-91-33-188-135-235l-50-23-5-58-5-58-92-3-93-3v55c0 52-2 56-27 62-81 18-111 28-144 46l-37 20 34 77c18 42 35 75 38 73 61-33 130-56 185-60 57-3 70-1 94 18 56 44 25 83-106 131-151 54-217 120-217 216 0 30 7 67 15 82 20 41 87 95 135 111l40 13v117h180v-55z"
					transform="matrix(.1 0 0 -.1 0 200)"></path>
			</svg>
		</>
	);
};

export default PriceIcon;
