import React, { useRef, useEffect } from "react";
import { Link as LinkS } from "react-scroll";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { motion } from "framer-motion";
import Logo from "../../../img/CruxeLogo-01.png";
import "./Navbar.css";
import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/slices/authSlice";
import { apiSlice } from "../../../redux/features/apiSlice";
import { useNavigate } from "react-router-dom";

const startVariants = {
	initial: {
		opacity: 0,
	},
	end: {
		opacity: 1,
		transition: {
			type: "tween",
			bounce: 0.4,
			duration: 0.1,
			staggerChildren: 0.1,
		},
	},
};

const pathVariants = {
	initial: {
		opacity: 0,
		pathLength: 0,
	},
	end: {
		opacity: 1,
		pathLength: 1,
		transition: {
			duration: 2,
			ease: "easeInOut",
		},
	},
};

const childVariants = {
	initial: {
		y: -30,
		opacity: 0,
	},
	end: {
		y: 0,
		opacity: 1,
		transition: {
			type: "tween",
			bounce: 0.4,
			duration: 0.8,
		},
	},
};

function useOutsideAlerter(ref: any, toggleDrawer: any) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			// handle if clicked on menuBtn
			if (
				event.target.classList.contains("ham-box") ||
				event.target.classList.contains("menuBtn") ||
				event.target.classList.contains("ham-box-inner")
			) {
				return;
			}
			if (ref.current.classList.contains("drawer-hidden")) {
				return;
			}
			if (ref.current && !ref.current.contains(event.target)) {
				toggleDrawer();
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
}

function Navbar() {
	const drawerRef: any = useRef(false);
	const hamRef: any = useRef(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user, token } = useTypedSelector((state) => state.auth);

	const toggleDrawer = () => {
		drawerRef.current.classList.toggle("drawer-visible");
		drawerRef.current.classList.toggle("drawer-hidden");
		hamRef.current.classList.toggle("cross");
	};
	useOutsideAlerter(drawerRef, toggleDrawer);

	const handleLogout = () => {
		localStorage.removeItem("auth");
		dispatch(logout());
		dispatch(apiSlice.util.resetApiState());
		navigate("/");
	};

	return (
		<header className="navbar z-[100] flex h-[100px] items-center justify-between px-[25px] md:px-[40px]  lg:px-[50px]">
			<nav className="flex w-full flex-row items-center justify-between">
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.5 }}
					className="my-auto min-w-[43px] cursor-pointer  "
				>
					<Link to="/" className="my-auto min-w-[43px] cursor-pointer  ">
						<div>
							<img src={Logo} alt="Cruxe Logo" className="h-10 " />
						</div>
					</Link>
				</motion.div>
				<motion.div
					className="hidden flex-row sm:flex "
					initial="initial"
					animate="end"
					variants={startVariants}
				>
					{/* <motion.div
						className="flex flex-row items-center p-[10px]"
						variants={childVariants}
					>
						<Link
							to="/about"
							className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
						>
							About
						</Link>
					</motion.div> */}
					<motion.div
						className="flex flex-row items-center p-[10px]"
						variants={childVariants}
					>
						<Link
							to="/dash/home"
							className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
						>
							Dashboard
						</Link>
					</motion.div>
					<motion.div
						className="flex flex-row items-center p-[10px]"
						variants={childVariants}
					>
						<Link
							to="/pricing"
							className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
						>
							Pricing
						</Link>
					</motion.div>
					<motion.div
						className="flex flex-row items-center p-[10px]"
						variants={childVariants}
					>
						<HashLink
							smooth
							to="/#faq"
							className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
						>
							FAQ's
						</HashLink>
					</motion.div>
					{token ? (
						<motion.div
							className="flex flex-row items-center p-[10px]"
							variants={childVariants}
						>
							<div
								className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
								onClick={handleLogout}
							>
								Logout
							</div>
						</motion.div>
					) : (
						<motion.div
							className="flex flex-row items-center p-[10px]"
							variants={childVariants}
						>
							<Link
								to="/login"
								className="cursor-pointer px-2 py-1 text-black hover:underline sm:mr-2 md:mr-5"
							>
								Login
							</Link>
						</motion.div>
					)}

					<motion.div
						className="ml-4 flex cursor-pointer items-center "
						variants={childVariants}
					>
						<Link
							to="/signup"
							className="rounded-xl bg-orangeCrushWarm px-4 py-2 text-white outline-none hover:bg-accentOrangeD"
						>
							Get Started
						</Link>
					</motion.div>
				</motion.div>

				{/* mobile nav menu button */}
				<motion.button
					className="menuBtn sm:hidden"
					onClick={toggleDrawer}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.5 }}
				>
					<div className="ham-box">
						<div className="ham-box-inner" ref={hamRef}></div>
					</div>
				</motion.button>

				<aside
					className="drawer drawer-hidden  flex   items-start"
					ref={drawerRef}
				>
					<div className=" translate-y-[90px] text-lg text-white">
						{/* <LinkS
							to="about"
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							onClick={() => toggleDrawer()}
							className="mx-1 flex cursor-pointer flex-col  items-center  justify-center p-[10px] text-white "
						>
							<div className="mt-1 mb-2">About</div>
						</LinkS> */}
						<Link
							to="/dash/home"
							onClick={() => toggleDrawer()}
							className="mx-1 flex cursor-pointer flex-col  items-center  justify-center p-[10px] text-white "
						>
							<div className="mt-1 mb-2">Dashboard</div>
						</Link>
						<HashLink
							to="/#faq"
							smooth
							onClick={() => toggleDrawer()}
							className="mx-1 flex cursor-pointer flex-col  items-center   justify-center p-[10px]  text-white"
						>
							<div className="mt-1 mb-2">FAQ's</div>
						</HashLink>
						<Link
							to="/pricing"
							className="mx-1 flex cursor-pointer flex-col  items-center   justify-center p-[10px]  text-white"
						>
							<div onClick={() => toggleDrawer()} className="mt-1 mb-2">
								Pricing
							</div>
						</Link>
						{token ? (
							<Link
								to="/login"
								onClick={() => {
									handleLogout();
									toggleDrawer();
								}}
								className="mx-1 flex cursor-pointer flex-col  items-center   justify-center p-[10px]  text-white"
							>
								<div className="mt-1 mb-2">Logout</div>
							</Link>
						) : (
							<Link
								to="/login"
								onClick={() => toggleDrawer()}
								className="mx-1 flex cursor-pointer flex-col  items-center   justify-center p-[10px]  text-white"
							>
								<div className="mt-1 mb-2">Login</div>
							</Link>
						)}
						<div className="mt-9 flex items-center ">
							<Link
								to="/signup"
								className="rounded-xl bg-accentOrange px-4 py-2 text-white hover:bg-accentOrangeD"
							>
								Get Started
							</Link>
						</div>
					</div>
				</aside>
			</nav>
		</header>
	);
}

export default Navbar;
