import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface Props {
	showPassword: boolean;
	onChange: (e: any) => void;
	setShowPassword: (showPassword: boolean) => void;
	setPassword?: (pass: string) => void;
}

function InputPassword({
	showPassword,
	onChange,
	setShowPassword,
	setPassword,
}: Props) {
	return (
		<>
			<div className="relative">
				<input
					id="password"
					type={showPassword ? "text" : "password"}
					name="password"
					className="formInput"
					placeholder="Password"
					required
					onChange={(e) => onChange(e)}
					style={{
						border: "0.5px solid ",
						width: "100%",
						borderColor: "#939393",
						padding: "1rem ",
					}}
				/>
				{showPassword ? (
					<AiOutlineEyeInvisible
						onClick={() => setShowPassword(!showPassword)}
						className="absolute top-4 right-4 cursor-pointer text-2xl text-gray-600"
					/>
				) : (
					<AiOutlineEye
						onClick={() => setShowPassword(!showPassword)}
						className="absolute top-4 right-4 cursor-pointer text-2xl text-gray-400"
					/>
				)}
			</div>
		</>
	);
}

export default InputPassword;
