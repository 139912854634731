import React from "react";
import { IconProp } from "../assetInterfaces";

const PageIcon = ({ width, height, isSelected }: IconProp) => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				version="1"
				viewBox="0 0 128 128"
				className={`svg-icon transition-transform-2ms translate-x-[2px] ${
					isSelected
						? "-translate-y-2 fill-accentOrange hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
			>
				<path
					d="M63 1264c-64-32-63-25-63-624C0 38-1 47 65 16 93 2 154 0 490 0c510 0 480-14 480 228v153l-35-27c-43-33-154-74-199-74-41 0-73 25-85 66-6 23-2 50 16 109 25 79 27 81 164 220l139 141v164H830c-104 0-142 3-145 13-3 6-6 73-7 147l-3 135-290 2c-247 2-295 0-322-13zm682-384c15-48 3-50-298-48-245 3-281 5-290 19-6 9-7 24-4 33 6 14 37 16 296 16 283 0 290 0 296-20zM595 730c15-47-3-51-223-48-176 3-206 5-215 19-6 9-7 24-4 33 6 14 32 16 221 16 208 0 215-1 221-20zm0-150c15-47-3-51-223-48-176 3-206 5-215 19-6 9-7 24-4 33 6 14 32 16 221 16 208 0 215-1 221-20zm0-150c15-47-3-51-223-48-176 3-206 5-215 19-6 9-7 24-4 33 6 14 32 16 221 16 208 0 215-1 221-20zm145-215c6-8 9-23 5-35-6-20-12-20-148-18-127 3-142 5-145 21-8 40 14 47 148 47 99 0 130-3 140-15z"
					transform="matrix(.1 0 0 -.1 0 128)"
				></path>
				<path
					d="M750 1165v-115h110c109 0 110 0 110 23 0 59-151 207-211 207-5 0-9-50-9-115zM1139 873l-40-38 54-53 54-53 36 40c44 48 47 82 12 116-35 36-69 32-116-12zM922 657L800 535l53-53 52-52 123 123 122 122-53 53-52 52-123-123zM735 420c-10-31-14-55-8-61 5-5 30-2 61 9l52 17-44 44-44 43-17-52z"
					transform="matrix(.1 0 0 -.1 0 128)"
				></path>
			</svg>
		</>
	);
};

export default PageIcon;
