import Contents from "../components/Contents";
import {
	useLazyFetchBlogsQuery,
	useSavePostMutation,
	useFetchSavedPostsQuery,
} from "../../../redux/features/apis/contentApi";

function SavedContent() {
	const { data: savedPosts } = useFetchSavedPostsQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<div className="mt-5 min-h-screen rounded-lg bg-white sm:mx-4 sm:px-4  md:mr-14  lg:ml-[95px] ">
			<div className="border-b border-black/75 px-2 py-1 text-2xl font-semibold text-black/75 sm:border-b-2 sm:text-4xl">
				<div className="">Saved Content</div>
			</div>
			<Contents isSaved={true} />
		</div>
	);
}

export default SavedContent;
