import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import logo from "../../img/logo.png";
import GoogleLogin from "react-google-login";
import { googleOAuth, redirectLogin } from "../../actions/google";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { signup, login, verifyotp } from "../../actions/auth";

import PulseLoader from "react-spinners/PulseLoader";
import InputPassword from "../Forms/InputPassword";

// redux
import {
	useSignUpMutation,
	useLoginMutation,
	useVerifyOTPMutation,
	useLazyLoadUserQuery,
	useResendOTPMutation,
	useGetTokenForGoogleMutation,
} from "../../redux/features/apis/authApi";
import { useTypedSelector } from "../../redux/hooks/useTypedSelector";
import { setAuth } from "../../redux/features/slices/authSlice";

//third party cookies check
import { useThirdPartyCookieCheck } from "../../hooks/UseThirdPartyCookieCheck";

// modal
import IsCookiesEnabled from "../modal/IsCookiesEnabled";

//reactjs-social-login
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";

import { loadUser as userAllData } from "../../actions/google";

const Signup = () => {
	const navigate = useNavigate();

	// show modal
	const [showModal, setShowModal] = useState(false);
	//third party cookies status
	const status = useThirdPartyCookieCheck();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState("");
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		mobile: "9999999999",
	});
	const [Otp, setOtp] = useState("");
	const [showOtp, setShowOtp] = useState(false);

	const [
		signUp,
		{
			data: signUpResponse,
			isLoading: isLoadingSignUp,
			isSuccess: isSuccessSignUp,
			isError: isErrorSignUp,
		},
	] = useSignUpMutation();

	const [
		getTokenForGoogle,
		{
			data: tokenForGoogle,
			isLoading: isLoadingToken,
			isSuccess: isSuccessToken,
			isError: isErrorToken,
			error: errorToken,
		},
	] = useGetTokenForGoogleMutation();

	const [
		login,
		{ data: tokens, isLoading: isLoadingLogin, isSuccess: isSuccessLogin },
	] = useLoginMutation();

	const [
		verifyOTP,
		{
			data: verifyOTPResponse,
			isLoading: isLoadingVerifyOTP,
			isSuccess: isSuccessVerifyOTP,
		},
	] = useVerifyOTPMutation();

	const [
		loadUser,
		{ data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser },
	] = useLazyLoadUserQuery();

	const [
		resendOTP,
		{ isLoading: isLoadingResendOTP, isSuccess: isSuccessResendOTP },
	] = useResendOTPMutation();

	const { user, token } = useTypedSelector((state) => state.auth);

	const handleClose = () => {
		setShowOtp(false);
	};

	// useEffect(() => {
	// 	if (isSuccessSignUp) {
	// 		setShowOtp(true);
	// 		login({email: formData.email, password: formData.password});
	// 	}
	// 	if (isErrorSignUp) {
	// 		setShowOtp(true);
	// 		login({email: formData.email, password: formData.password});
	// 	}
	// }, [isSuccessSignUp, signUpResponse, isErrorSignUp]);

	// useEffect(() => {
	// 	if (isSuccessVerifyOTP && tokens) {
	// 		loadUser({token: tokens.access});
	// 	}
	// }, [isSuccessVerifyOTP]);

	// useEffect(() => {
	// 	function start() {
	// 		gapi.client.init({
	// 			clientId:
	// 				"190515764585-pree9afrkr29ir6oohjq8uqqpf4p7djp.apps.googleusercontent.com",
	// 			scope: "email",
	// 		});
	// 	}

	// 	gapi.load("client:auth2", start);
	// }, []);

	useEffect(() => {
		if (isSuccessToken && tokenForGoogle) {
			loadUser({ token: tokenForGoogle.access_token });
		}
		if (isSuccessLogin && tokens) {
			// console.log("tokens", tokens);
			dispatch(setAuth({ token: tokens.access, user: null }));
		}
		if (isSuccessUser && userData && tokens) {
			dispatch(setAuth({ token: tokens.access, user: userData }));
		}

		if (isSuccessUser && userData && tokenForGoogle) {
			dispatch(
				setAuth({ token: tokenForGoogle.access_token, user: userData })
			);
		}

		if (user && user.account_activated) {
			navigate("/dash/home");
			localStorage.setItem(
				"auth",
				JSON.stringify({ token: token, user: user })
			);
		}
		if (user && !user.account_activated) {
			navigate("/signup");
			localStorage.removeItem("auth");
		}
	}, [
		isSuccessUser,
		isSuccessLogin,
		user,
		userData,
		tokenForGoogle,
		isSuccessToken,
	]);

	const onChange = (e: any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		setErrorMsg("");
		signUp(formData)
			.unwrap()
			.then((res: any) => {
				if (res?.response?.includes("Account already exists")) {
					setErrorMsg(
						"Account already existsAccount already exists with this Email. Please Sign in"
					);
				} else if (res?.response?.includes("verify your email address")) {
					setErrorMsg(
						"Please verify your email address. An email with the verification code has been sent to you."
					);
					setShowOtp(true);
					login({ email: formData.email, password: formData.password });
				} else {
					setShowOtp(true);
					login({ email: formData.email, password: formData.password });
				}
			});
	};

	const verify = (e: any) => {
		e.preventDefault();
		verifyOTP(Otp)
			.unwrap()
			.then((res) => {
				// console.log(tokens);
				if (tokens) {
					loadUser({ token: tokens.access });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const show = () => {
		var s = showPassword;
		setShowPassword(!s);
	};

	const responseGoogle = (response: any) => {
		console.log("google auth", response);
		// googleOAuth(response);
		loadUser({ token: response.accessToken });
	};
	const responseGoogleFail = (response: any) => {
		if (!status) {
			setShowModal(true);
		}
		// console.log("fail google auth", response);
	};

	const resend = () => {
		resendOTP();
	};

	// if (google.isAuthenticated) {
	// 	navigate("/login");
	// }
	return (
		<div className="mx-auto flex max-w-[500px] flex-col items-center px-3">
			<Link to="/">
				<span className="absolute top-[2%] left-[5%] text-base font-semibold text-gray-300 transition-all  duration-300 hover:text-gray-400">
					{" "}
					Go Back
				</span>
			</Link>
			<div className="mt-10 w-[200px]">
				<img
					alt="logo"
					src={logo}
					style={{ width: "13rem", margin: "0 auto" }}
				></img>
			</div>
			<div className="mt-10">
				<div className="mx-auto text-center text-[#8D8787] sm:text-lg ">
					Content creation made 10x easier, faster, and simpler
					<br /> No more fear of running out of ideas again!
				</div>
			</div>
			<div className="mt-10 w-full">
				<div
					// onClick={() => {
					// 	if (!status) {
					// 		setShowModal(true);
					// 	}
					// }}
					className="mx-auto w-full rounded-lg border border-[#F89B55] bg-white py-2 text-center"
				>
					{/* <GoogleLogin
						disabled={!status}
						clientId="190515764585-pree9afrkr29ir6oohjq8uqqpf4p7djp.apps.googleusercontent.com"
						buttonText="Continue with Google"
						onSuccess={responseGoogle}
						onFailure={responseGoogleFail}
						cookiePolicy={"single_host_origin"}
						className="googlelogin"
					/> */}

					<LoginSocialGoogle
						client_id={
							"444881216965-tmlqqj220o8bnqulsh8l98a7helckg84.apps.googleusercontent.com"
						}
						scope="openid profile email"
						discoveryDocs="claims_supported"
						access_type="offline"
						onResolve={({ provider, data }: any) => {
							// console.log("provider", provider);
							getTokenForGoogle(data?.access_token);
							// console.log("data", data);
						}}
						onReject={(err) => {
							console.log(err);
						}}
					>
						<GoogleLoginButton className="flex items-center justify-center text-center shadow-none " />
					</LoginSocialGoogle>
				</div>
			</div>

			<div className="my-4">
				<div style={{ margin: "0 auto", color: "#8D8787" }}>OR</div>
			</div>
			<div className=" w-full">
				<div className="mx-auto w-full">
					<form onSubmit={onSubmit} className="w-full">
						<input
							id="firstName"
							type="text"
							name="firstName "
							className="formInput inputBorder  "
							placeholder="First Name"
							required
							onChange={onChange}
							style={{
								border: "0.5px solid ",
								width: "100%",
								borderColor: "#939393",
								padding: "1rem ",
							}}
						/>
						<input
							id="lastName"
							type="text"
							name="lastName"
							className="formInput"
							placeholder="Last Name"
							required
							onChange={onChange}
							style={{
								border: "0.5px solid ",
								width: "100%",
								borderColor: "#939393",
								padding: "1rem ",
							}}
						/>
						<input
							id="email"
							type="email"
							name="email"
							className="formInput"
							placeholder="Email"
							required
							onChange={onChange}
							style={{
								border: "0.5px solid ",
								width: "28re100%",
								borderColor: "#939393",
								padding: "1rem ",
							}}
						/>
						<InputPassword
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							onChange={onChange}
						/>
						{errorMsg && (
							<div>
								<p className="mb-2 text-xs text-red-500">{errorMsg}</p>
							</div>
						)}
						<button
							type="submit"
							style={{
								border: "0.5px solid ",
								width: "100%",
								background: "#ee732e",
								padding: "0.9rem ",
								color: "white",
								borderRadius: "5px",
								fontSize: "1.1rem",
							}}
						>
							<div className="flex flex-row items-center justify-center">
								{isLoadingSignUp ? (
									<PulseLoader color="white" />
								) : (
									"Create Account"
								)}
							</div>
						</button>
					</form>
				</div>
			</div>
			<Modal show={showOtp} onHide={handleClose} centered backdrop="static">
				<Modal.Body>
					<div className="w-[ flex flex-col items-center px-10">
						<div className="text-lg font-semibold">
							Enter verification code
						</div>
						<div className="mb-2 w-full text-center text-sm font-normal">
							An OTP has been sent to your email ID. <br /> Please enter
							the code to verify your email
						</div>
						<input
							onChange={(e) => {
								setOtp(e.target.value);
								e.target.value = e.target.value.slice(0, 4);
							}}
							placeholder="Enter the OTP sent to your email ID"
							// maxlength='4'
							min="-999"
							max="9999"
							type="number"
							autoFocus
							className="inputBorder w-full rounded-lg px-2   py-2"
							id="otp"
						></input>

						<div className="w-full">
							{errorMsg && (
								<div>
									<p className="mb-2 text-sm text-gray-400">
										{errorMsg}
									</p>
								</div>
							)}

							<div className="flex flex-row items-center justify-center">
								{/* resent otp button */}
								<button
									className="mt-2 text-sm text-gray-500 hover:text-blue-400"
									onClick={resend}
								>
									<div className="flex flex-row items-center justify-center">
										{isLoadingResendOTP ? (
											<PulseLoader color="#F89B55" />
										) : (
											"Resend OTP"
										)}
									</div>
								</button>
							</div>

							<Button
								onClick={verify}
								style={{
									padding: "0.5rem 3rem",
									background: "#ee732e",
									border: "0",
									borderRadius: "5px",
									margin: "1rem auto",
									width: "100%",
								}}
							>
								Submit
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Row style={{ margin: "0.7rem 0" }}>
				<Link
					to="/login"
					style={{
						margin: "0 auto",
						textDecoration: "underline",
						color: "#8D8787",
					}}
				>
					Already a member? Login
				</Link>
			</Row>

			<IsCookiesEnabled show={showModal} setShow={setShowModal} />
		</div>
	);
};

export default Signup;
