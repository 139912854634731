import React from "react";

function SortIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="15"
			fill="none"
			viewBox="0 0 14 19"
		>
			<path
				fill="#5C5C5C"
				d="M1.107 8.685H12.44c.846 0 1.295-1.001.733-1.634L7.507.676a.98.98 0 00-1.466 0L.373 7.051c-.563.633-.113 1.634.734 1.634zm4.933 9.97a.979.979 0 001.466 0l5.666-6.374c.563-.632.114-1.633-.733-1.633H1.107c-.846 0-1.296 1-.733 1.634l5.666 6.374z"
			></path>
		</svg>
	);
}

export default SortIcon;
