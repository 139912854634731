export const GOOGLE_OAUTH_SUCCESS = "GOOGLE_OAUTH_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_ALL_FILES = "GET_ALL_FILES";
export const GET_FILES_ERROR = "GET_FILES_ERROR";
export const GET_FILE = "GET_FILE";
export const GET_FILE_ERROR = "GET_FILE_ERROR";
export const SUMMARY_PDF = "SUMMARY_PDF ";
export const SUMMARY_ERROR = "SUMMARY_ERROR ";
export const SUMMARY_FOR_SOCIAL = "SUMMARY_FOR_SOCIAL ";
export const SUMMARY_URL = "SUMMARY_URL ";
export const SUMMARY_PASTE = "SUMMARY_PASTE";
export const SUMMARY_HISTORY = "SUMMARY_HISTORY";
export const LOADING_SUMMARY = "LOADING_SUMMARY";
export const GET_PRICING = "GET_PRICING";
export const GET_PRICING_ERROR = "GET_PRICING_ERROR";
export const PAY_AMOUNT = "PAY_AMOUNT";
export const REMOVE_PAY_AMOUNT = "REMOVE_PAY_AMOUNT";
export const PAY_AMOUNT_ERROR = "PAY_AMOUNT_ERROR";
export const RESET = "RESET";
export const RESET_CONTENT = "RESET_CONTENT";
export const ADD_FILE = "ADD_FILE";
export const ADD_FILE_ERROR = "ADD_FILE_ERROR";
export const GET_PRICING_BY_ID = "GET_PRICING_BY_ID";
export const GET_PDFS = "GET_PDFS";
export const CANVA_API = "CANVA_API";
export const PLAN_LOADED = "PLAN_LOADED";
export const PLAN_LOADED_FAIL = "PLAN_LOADED_FAIL";
export const STATS_LOADED = "STATS_LOADED";
export const STATS_LOADED_FAIL = "STATS_LOADED_FAIL";
export const REDIRECT = "REDIRECT";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const LOAD_USER = "LOAD_USER";
export const FETCH_TWEETS = "FETCH_TWEETS";
export const FETCH_TWEETSFAILED = "FETCH_TWEETSFAILED";
export const FETCH_YT = "FETCH_YT";
export const FETCH_YTFAILED = "FETCH_YTFAILED";
export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_BLOGSFAILED = "FETCH_BLOGSFAILED";
export const FETCH_REDDIT = "FETCH_REDDIT";
export const FETCH_REDDITFAILED = "FETCH_REDDITFAILED";
export const FETCH_CREATORS = "FETCH_CREATORS";
export const FETCH_CREATORSFAILED = "FETCH_CREATORSFAILED";
export const FETCH_COUPONINFO = "FETCH_COUPONINFO";
export const FETCH_COUPONINFOFAILED = "FETCH_COUPONINFOFAILED";
export const VERIFY_OTP_SUCCESSFUL = "VERIFY_OTP_SUCCESSFUL";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const SAVE_POST_SUCCESSFUL = "SAVE_POST_SUCCESSFUL";
export const SAVE_POST_FAILED = "SAVE_POST_FAILED";
export const FETCH_SAVED_POSTS = "FETCH_SAVED_POSTS";
export const FETCH_SAVED_POSTS_FAILED = "FETCH_SAVED_POSTS_FAILED";
export const ADD_TAGS = "ADD_TAGS";
export const ADD_TAGS_FAILED = "ADD_TAGS_FAILED";
export const ADD_SAVED_TO_CONTENT = "ADD_SAVED_TO_CONTENT";
export const FETCH_STREAK = "FETCH_STREAK";
export const FETCH_STREAK_FAILED = "FETCH_STREAK_FAILED";
