import React from "react";

interface CanvaSocialbtnProps {
	canvaDesign: (name: string) => void;
	name: string;
	Icon: any;
	color: string;
	text: string;
}

function CanvaSocialbtn({
	canvaDesign,
	name,
	Icon,
	color,
	text,
}: CanvaSocialbtnProps) {
	return (
		<div
			onClick={() => {
				canvaDesign(name);
			}}
			className=" flex cursor-pointer flex-col items-center justify-center space-y-2 rounded-md p-4 text-center hover:bg-[#FDF0E7]"
		>
			<Icon style={{ color: color }} className={`text-5xl`} />
			<h1>{text}</h1>
		</div>
	);
}

export default CanvaSocialbtn;
