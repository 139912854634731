import ProgressBox1 from "../components/ProgressBox1";
import React, { useEffect, useState } from "react";
import Avatar1 from "react-avatar";
import StreakIcon from "../../icons/StreakIcon";

import Contents from "../components/Contents";
import Canvabtn from "../components/Canvabtn";

import { FaBookmark } from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";

import { useNavigate, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";
import Joyride from "react-joyride";
import WordSummarized from "../../icons/WordSummarized";
import ActivePlanIcon from "../../icons/ActivePlanIcon";

function HomeNew() {
	const { user } = useTypedSelector((state) => state.auth);
	const [steps, setSteps] = useState([
		{
			target: ".j_sidebar",
			content: "This is my awesome feature!",
		},
		{
			target: ".j_main",
			content: "This another awesome feature!",
		},
	]);
	return (
		<div className="flex flex-col   pt-4 font-poppins  sm:mr-4 lg:mr-10  lg:ml-20">
			<main className=" flex h-fit flex-col justify-between gap-10 sm:mx-4 sm:ml-8 sm:flex-row">
				<div className=" my-2 flex w-full flex-col ">
					<div className="  flex flex-wrap justify-center gap-2">
						<div className="grow-1 shrink-2  p-4">
							<div className="font-lato text-lg md:text-[22px]">
								Hi <span className="font-bold">{user?.firstName}</span>
							</div>
							<p className="mb-3 text-sm  text-[#646464] sm:text-base">
								Good Morning
							</p>
						</div>
						<ProgressBox1 />
					</div>
				</div>
			</main>

			<div className=" rounded-t-md bg-white px-0  sm:mx-5 md:px-4">
				<Contents isSaved={false} />
			</div>
		</div>
	);
}

export default HomeNew;
