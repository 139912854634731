import React from "react";
import cruxe from "../../img/whiteXchotta.png";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {browserName} from "react-device-detect";
import {logout} from "../../actions/google";
// import {store} from "../../store";

const Navbar2 = ({google: {isAuthenticated, profileObj}}) => {
	const clickhandler = () => {
		// store.dispatch(logout());
	};

	const authLinks = (
		<ul className="nav-list">
			<li>
				<a href="/about">About Us</a>
			</li>
			<li>
				<Link to="/dashboard">Dashboard</Link>
			</li>

			<li>
				<Link to="/pricing">Pricing</Link>
			</li>
			<li>
				<Link to="">
					<Button className="hidden" id="navbar-button">
						{" "}
						+ Add to {browserName}
					</Button>
				</Link>
			</li>

			<li>
				<Link to="" onClick={clickhandler}>
					Logout <i className="fas fa-sign-out-alt"></i>
				</Link>
			</li>
		</ul>
	);
	const guestLinks = (
		<ul className="nav-list">
			<li>
				<a href="/about">About Us</a>
			</li>

			<li>
				<Link to="/pricing">Pricing</Link>
			</li>
			<li>
				<Link to="/login">Login</Link>
			</li>
			<li>
				<Link to="">
					<Button className="hidden" id="navbar-button">
						{" "}
						+ Add to {browserName}
					</Button>
				</Link>
			</li>
		</ul>
	);

	return (
		<section className="navigation fixed-top black">
			<div className="nav-container">
				<div className="brand">
					<Link to="/">
						<img alt="" src={cruxe} />
					</Link>
				</div>
				<nav>
					<div className="nav-mobile">
						<span id="nav-toggle"></span>
					</div>
					{isAuthenticated ? authLinks : guestLinks}
				</nav>
			</div>
		</section>
	);
};

Navbar2.propTypes = {
	google: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	google: state.google,
});

export default connect(mapStateToProps)(Navbar2);
