import {
	AUTH_ERROR,
	LOGIN_FAILED,
	LOGIN_SUCCESS,
	SIGNUP_FAILED,
	SIGNUP_SUCCESS,
	LOAD_USER,
	LOGOUT,
	VERIFY_OTP_SUCCESSFUL,
	VERIFY_OTP_FAILED,
	FETCH_STREAK,
	FETCH_STREAK_FAILED,
} from "../actions/types.js";

const initialState = {
	token: localStorage.getItem("token"),
	isAuthenticated: localStorage.getItem("authData") ? true : false,
	loading: localStorage.getItem("authData") ? false : true,
	user: localStorage.getItem("authData") ? JSON.parse(localStorage.getItem("authData")) : null,
	err: null,
	success: false,
	errorauth: null,
};

const auth = (state = initialState, action) => {
	const {type, payload} = action;
	console.log(type, payload);
	switch (type) {
		case LOGIN_SUCCESS:
			localStorage.setItem("token", payload);
			return {
				...state,
				token: payload,
				err: null,
				// success: true,
				errorauth: null,
				isAuthenticated: true,
			};
		case LOAD_USER:
			localStorage.setItem("data", payload.email);
			localStorage.setItem("authData", JSON.stringify(payload));
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		case LOGIN_FAILED:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
				profileObj: null,
				tokenObj: null,
				type: null,
				error: action.payload,
			};
		case AUTH_ERROR:
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
				profileObj: null,
				tokenObj: null,
				type: null,
				errorauth: action.payload,
				err: null,
				success: false,
			};
		case LOGOUT:
			localStorage.removeItem("token");
			localStorage.removeItem("data");
			localStorage.removeItem("authData");
			localStorage.removeItem("plan");
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
				profileObj: null,
				tokenObj: null,
				type: null,
				success: null,
				err: null,
				errorauth: null,
			};
		case SIGNUP_SUCCESS:
			return {
				...state,
				success: true,
				err: null,
			};
		case SIGNUP_FAILED:
			return {
				...state,
				err: action.payload,
				success: false,
			};
		case VERIFY_OTP_SUCCESSFUL:
			return {
				...state,
				verified: true,
			};
		case VERIFY_OTP_FAILED:
			return {
				...state,
				verified: false,
			};
		case FETCH_STREAK:
			return {
				...state,
				streak: payload,
			};
		case FETCH_STREAK_FAILED:
			return {
				...state,
				err: payload,
			};
		default:
			return state;
	}
};

export default auth;
