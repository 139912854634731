import React, { useState } from "react";
import { isFirefox } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";

import AvatarDropdown from "./other/AvatarDropdown";
// icons
import SearchIcon from "../../icons/SearchIcon";
import { RiArrowRightSLine } from "react-icons/ri";
// redux
import {
	setTwitterSearchQry,
	setSidebarOpen,
} from "../../../redux/features/slices/otherSlice";
import { useDispatch } from "react-redux";
function Navbar1() {
	const navigate = useNavigate();
	const [query, setQuery] = useState("");
	const dispatch = useDispatch();
	let path = window.location.pathname;

	var link: string;
	if (isFirefox) {
		link =
			"https://addons.mozilla.org/en-US/firefox/addon/cruxe-summarise-text-using-ai/";
	} else {
		link =
			"https://chrome.google.com/webstore/detail/cruxe-summarise-text-usin/fbflpifoodcfknnolhnambbiekfjekmb?authuser=1";
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (path != "/dash/twitter") {
			navigate("/dash/twitter");
		}
		dispatch(setTwitterSearchQry(query));
	};

	const handleSidebar = () => {
		dispatch(setSidebarOpen(true));
	};

	return (
		<div className=" flex items-center  bg-white px-2 py-1 font-poppins shadow-md   sm:pr-6 lg:ml-[85px]  ">
			<div
				onClick={handleSidebar}
				className=" mr-1 flex h-fit w-fit cursor-pointer flex-col space-y-1 rounded-full  px-2 py-3 active:bg-[#FDF0E7] lg:hidden  "
			>
				<span className="hamburgerMenu"></span>
				<span className="hamburgerMenu"></span>
				<span className="hamburgerMenu"></span>
			</div>
			<div className="flex flex-1 items-center justify-between">
				<div className="hidden  shrink-[2] grow-[1] text-lg capitalize text-gray-400 lg:block">
					Home{" "}
					{path.split("/")[2] !== "home" ? ">> " + path.split("/")[2] : ""}
				</div>
				<div className=" shrink-[1] grow-[2]  sm:px-4">
					{/*<form className="flex justify-start" onSubmit={handleSubmit}>
						<span className=" -mr-4 flex items-center justify-center rounded-lg border-[1px] border-r-0 py-1 pl-2 pr-4 md:px-4 md:py-3">
							<SearchIcon />
						</span>
						<input
							type="text"
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							placeholder="Search"
							className=" w-full  rounded-lg border-[1px] border-l-0  py-1 px-4 pl-2 text-lg focus:outline-none md:py-2 lg:w-3/4 "
						/>
					</form>*/}
				</div>
				<div className="flex grow-0 items-center justify-end space-x-1 text-xs	font-semibold sm:space-x-3 md:text-base ">
					<a
						className="hidden md:block"
						href={link}
						target="_blank"
						rel="noreferrer"
					>
						<span className=" rounded-md border-[1px] border-[#EE732E] py-2 px-4 text-[#EE732E] no-underline">
							Add Extension
						</span>
					</a>
					<Link style={{ textDecoration: "none" }} to="/pricing">
						<span className="rounded-md bg-[#EE732E] py-2 px-4  text-white ">
							Upgrade
						</span>
					</Link>
					<div className="hidden lg:block">
						<AvatarDropdown flexDirection="flex-row" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar1;
