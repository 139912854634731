import { apiSlice } from "../apiSlice";
import axios from "axios";
const otherApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		// login: build.mutation<{access: string; refresh: string}, {email: string; password: string}>({
		// 	query: ({email, password}) => ({
		// 		url: "users/simple/token/",
		// 		method: "POST",
		// 		body: {email, password},
		// 	}),
		// }),
		fetchStats: build.query<any, void>({
			query: () => ({
				url: `uploads/calculate/`,
				method: "GET",
			}),
			providesTags: ["stats"],
		}),

		getAllTweets: build.query<any, { query: string; exact_search: boolean }>({
			queryFn: async ({ query, exact_search }) => {
				const config = {
					query,
					exact_search: exact_search ? "True" : "False",
				};

				const { data }: any = await axios.post(
					"https://apicruxe.xyz/api/tool/showfeed/twitter/",
					config
				);

				return { data: data.response };
			},
		}),
	}),
	overrideExisting: false,
});

export const { useFetchStatsQuery, useLazyGetAllTweetsQuery } = otherApi;
