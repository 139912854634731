import Tips from "../components/Tips";
import { useState, useEffect } from "react";
import { Slider, Box } from "@material-ui/core";

import { Link } from "react-router-dom";

import Loader from "../../loader/Loader";
import Quotes from "../../loader/Quotes";
import Canvabtn2 from "../components/Canvabtn2";
import { useSummarizeForCopyPasteMutation } from "../../../redux/features/apis/summaryApi";

import WordLimitOver from "../../modal/WordLimitOver";

const marks = [
	{
		value: 25,
		label: "25",
	},

	{
		value: 250,
		label: "250",
	},
];

const wordSize = [
	{
		_id: 1,
		value: 25,
	},
	{
		_id: 2,
		value: 50,
	},
	{
		_id: 3,
		value: 75,
	},
	{
		_id: 4,
		value: 100,
	},
	{
		_id: 5,
		value: 125,
	},
	{
		_id: 6,
		value: 150,
	},
	{
		_id: 7,
		value: 175,
	},
	{
		_id: 8,
		value: 200,
	},
	{
		_id: 9,
		value: 225,
	},
	{
		_id: 10,
		value: 250,
	},
];

function SummarizeByCopyPaste() {
	const [isLimitOver, setIsLimitOver] = useState<boolean>(false);
	const [text, setText] = useState("");
	const [value, setValue] = useState(100);
	const [copied, setCopied] = useState(false);
	const [output, setOutput] = useState("");

	const [summarize, { data: response, isLoading }] =
		useSummarizeForCopyPasteMutation();

	const handletext = (e: any) => {
		setText(e.target.value);
	};
	// const handleChange = (e: any, newValue: any) => {
	// 	setValue(newValue);
	// };

	const handleSubmit = (e: any) => {
		e.preventDefault();
		summarize({ text, number: value });
	};

	const copyText = () => {
		navigator.clipboard.writeText(output);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	useEffect(() => {
		if (response?.generated_summary) {
			const { generated_summary } = response;
			setOutput(
				generated_summary.substring(2, generated_summary.length - 2)
			);
		}
		if (response && !response?.generated_summary) {
			setIsLimitOver(true);
		}
	}, [response]);

	return (
		<div className="flex w-full flex-col items-center  sm:pl-5 lg:mx-16 ">
			<div className="my-4 mx-6 flex justify-center py-2 text-center text-2xl text-gray-500 ">
				Summarize your text in just one click by{" "}
				<span className="text-orangeCrushWarm"> Cruxe!</span>
			</div>
			<div className=" flex flex-col rounded-xl bg-white p-2 sm:w-[85%] md:p-8 lg:flex-row xl:w-[80%] ">
				<div className="relative mt-4 min-h-[400px] w-full rounded-xl border-2   ">
					<form onSubmit={handleSubmit} className=" h-full">
						<div className="mb-3 flex flex-row justify-around bg-[#F1EAFB]  text-center  font-semibold md:pt-4 md:text-lg ">
							<Link
								to="/dash/summarize-for-social-media"
								className="px-2 text-black hover:text-gray-700 "
							>
								Summarize for Social Media
							</Link>
							<div className=" border-b-4 border-orangeCrushWarm px-2 text-orangeCrushWarm">
								Summarize by Copy/Paste
							</div>
						</div>

						{/* 
						<div className="mb-3 flex flex-row justify-around text-center text-sm font-semibold sm:text-base">
							<Link
								to="/dash/summarize-for-social-media"
								className="px-2 text-black hover:text-accentOrange/75"
							>
								Summarize For Social Media
							</Link>
							<div className="border-b-2 border-accentOrange px-2 text-accentOrange">
								Summarize by Copy/Paste
							</div>
						</div> */}

						{isLoading ? (
							<div className="mt-10 flex flex-col items-center justify-center">
								<Loader />
								<div
									style={{
										color: "gray",
										marginTop: "2rem",
										paddingBottom: "3rem",
									}}
									className="note-resp"
								>
									<Quotes />
								</div>
							</div>
						) : (
							<div className=" h-full ">
								<div className="mb-3">
									<textarea
										name="text"
										id="textSummarise"
										cols={30}
										rows={10}
										placeholder="Paste text here"
										className="w-full border-b-[1px]   bg-white p-3 text-justify focus:outline-none "
										value={text}
										onChange={handletext}
									></textarea>
								</div>
								{/* <div className="mb-3 font-semibold">Words</div>
								<div className="flex w-full justify-center">
									<div className="flex w-full justify-center">
										<Slider
											aria-labelledby="discrete-slider-small-steps"
											color="secondary"
											step={25}
											marks={marks}
											min={25}
											max={250}
											valueLabelDisplay="auto"
											value={value}
											onChange={handleChange}
										/>
									</div>
								</div> */}

								{/* {response?.generated_summary && (
									<div className="relative mt-12">
										<textarea
											name="output"
											id="sumByURLoutput"
											cols={30}
											rows={6}
											className="w-full rounded border-none bg-accentOrange/25 p-3 text-justify"
											value={output}
											onChange={(e) => setOutput(e.target.value)}
										></textarea>

										<button
											type="button"
											onClick={copyText}
											className="absolute top-0 right-0 -translate-y-[102%] rounded border-2 border-black/75 px-3 py-1 hover:bg-accentOrange/25"
										>
											{copied ? "Copied!" : "Copy"}
										</button>
									</div>
								)} */}

								{/* <div className="mx-auto mt-5 mb-3 flex w-full flex-row items-center justify-center">
									<div className=" flex flex-col items-center justify-between">
										<div className="mb-2 flex flex-row items-center">
											<div className="mr-4 cursor-pointer text-sm font-semibold hover:text-black/75">
												Reset
											</div>
											<button
												type="submit"
												className="rounded-lg bg-accentOrange px-8 py-1 text-white hover:bg-accentOrange/75"
											>
												Summarize
											</button>
										</div>
										<Canvabtn2 />
									</div>
								</div> */}
							</div>
						)}
					</form>
					{/* <div className="hidden w-[40%] sm:flex">
						<Tips />
					</div> */}

					<div className=" absolute bottom-0 flex w-full flex-row items-center justify-end border-t-[1px]  ">
						<div className="my-4 mr-4  flex flex-col items-center ">
							<div className=" flex flex-row items-center">
								<button
									type="submit"
									onClick={handleSubmit}
									className="rounded-lg bg-orangeCrushWarm px-6 py-2 font-semibold text-white md:text-lg "
								>
									Summarize
								</button>
							</div>
							{/* <Canvabtn2 /> */}
						</div>
					</div>
				</div>
				<div className=" flex flex-col items-center  text-center">
					<span className="mt-2 text-sm font-semibold text-gray-400">
						Wordcount
					</span>
					<div className="flex w-fit flex-wrap justify-center rounded-md bg-[#F1EAFB] lg:flex-col  lg:py-2">
						{wordSize.map((item, index) => (
							<span
								key={item._id}
								onClick={() => setValue(item.value)}
								className={`wordCount-box 
										${value === item.value && "bg-orangeCrushWarm font-semibold text-white"}
										`}
							>
								{item.value}
							</span>
						))}
						{/* <span className="wordCount-box ">25</span>
						<span className=" wordCount-box bg-orangeCrushWarm font-semibold text-white ">
							50
						</span> */}
					</div>
				</div>
				<div className="relative mt-4 mr-2 w-full rounded-xl border-2 bg-white   ">
					<div className="flex space-x-2 bg-[#F1EAFB] px-4 py-2">
						<span className="md:text-2xl">Summary </span>
						{"  "}
						<div className="flex items-end text-black md:text-base">
							<span>5 </span>
							<span className="text-[#858585]"> sentences </span>/
							<span> 56</span>
							<span className="text-[#858585]"> words </span>
						</div>
					</div>
					{response?.generated_summary && (
						<div className=" ">
							<textarea
								name="output"
								id="sumByURLoutput"
								cols={50}
								rows={5}
								className=" w-full border-none bg-white p-3 text-justify focus:outline-none"
								value={output}
								onChange={(e) => setOutput(e.target.value)}
							></textarea>
							<div className="absolute bottom-2 flex w-full flex-row  items-center justify-end border-t-[1px] ">
								<div className="my-4 mr-4  flex flex-col items-center ">
									<button
										type="button"
										onClick={copyText}
										className="rounded-lg border-[1px] border-orangeCrushWarm px-4 py-1 font-semibold text-orangeCrushWarm transition-all duration-500 hover:bg-orangeCrushWarm  hover:text-white  md:text-lg "
									>
										{copied ? "Copied!" : "Copy"}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<WordLimitOver
				isLimitOver={isLimitOver}
				setIsLimitOver={setIsLimitOver}
			/>
		</div>
	);
}

export default SummarizeByCopyPaste;
