import {apiSlice} from "../apiSlice";

const summaryApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getHistory: build.query<any, void>({
			query: () => ({
				url: `uploads/upload/`,
				method: "GET",
			}),
		}),
		// get summary of blog
		getSummary: build.mutation<{summary: string,error:string}, {id: number; isTrending?: boolean}>({
			query: ({id, isTrending}) => ({
				url: `content/${isTrending ? "summarytrending" : "summary"}/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["stats"],
		}),

		summarizeForSocial: build.mutation<any, {url: string; social_media: string}>({
			query: ({url, social_media}) => ({
				url: `uploads/upload/`,
				method: "POST",
				body: {type: "social_media", social_media_type: social_media, url},
			}),
			invalidatesTags: ["stats"],
		}),
		summarizeForCopyPaste: build.mutation<any, {text: string; number: number}>({
			query: ({text, number}) => ({
				url: `uploads/upload/`,
				method: "POST",
				body: {type: "text", text, number},
			}),
			invalidatesTags: ["stats"],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetHistoryQuery,
	useGetSummaryMutation,
	useSummarizeForSocialMutation,
	useSummarizeForCopyPasteMutation,
	// useGetSummaryTrendingMutation,
} = summaryApi;
