import React from "react";
import Img from "../../../assets/contentInspiration/finance1.png";
import ContentCard from "../components/contentInspiration/ContentCard";
import FinanceImg from "../components/contentInspiration/FinanceImg";

const ContentInspiration = () => {
	return (
		<div className="flex-row flex  justify-center h-screen relative sm:ml-[65px] ">
			<div className=" flex flex-row flex-1 w-full bg-white">
				<div className=" flex items-center justify-center w-full bg-orange-300">
					<FinanceImg width={500} height={500} />
					{/* <img src={Img} alt="f" /> */}
				</div>
			</div>
			<div className="flex-1 w-full py-10">
				<h1 className="border-slate-500 pl-10 text-3xl border-b shadow">Finance</h1>
				<div className="ml-[100px]">
					<ContentCard open={false} />
					<ContentCard open={false} />
					<ContentCard open={false} />
				</div>
			</div>
		</div>
	);
};

export default ContentInspiration;
