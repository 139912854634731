import React, { Fragment, useState, useEffect } from "react";
import { Slider, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { summaryForSocial } from "../../../actions/summary";
import { useNavigate } from "react-router-dom";

import Loader from "../../loader/Loader";
import Quotes from "../../loader/Quotes";
import Canvabtn2 from "../components/Canvabtn2";

// icons
import instaIcon from "../../../assets/socials/insta48.png";
import youtubeIcon from "../../../assets/socials/youtube48.png";
import linkedinIcon from "../../../assets/socials/linkedin48.png";
import twitterIcon from "../../../assets/socials/twitter48.png";
import { MdContentCopy } from "react-icons/md";
import { VscTriangleUp } from "react-icons/vsc";
import { SiCanva } from "react-icons/si";
import { useSummarizeForSocialMutation } from "../../../redux/features/apis/summaryApi";
import Tips from "../components/Tips";
import WordLimitOver from "../../modal/WordLimitOver";

const marks = [
	{
		value: 25,
		label: "25",
	},

	{
		value: 250,
		label: "250",
	},
];

function SummarizeForSocialMedia() {
	const [url, setUrl] = useState("");
	const [value, setValue] = useState(100);
	const [socialSelected, setSocialSelected] = useState("Instagram");
	const [socialIconSelected, setSocialIconSelected] = useState(instaIcon);
	const [selectOpen, setSelectOpen] = useState(false);
	const [copied, setCopied] = useState(false);
	const [output, setOutput] = useState("");
	const [isLimitOver, setIsLimitOver] = useState<boolean>(false);

	let path = window.location.pathname;

	const [summarize, { data: response, isLoading, isSuccess }] =
		useSummarizeForSocialMutation();

	const handleUrl = (e: any) => {
		setUrl(e.target.value);
	};
	const handleChange = (e: any, newValue: any) => {
		setValue(newValue);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		summarize({ url, social_media: socialSelected });
	};

	const copyText = () => {
		navigator.clipboard.writeText(output);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	useEffect(() => {
		if (response?.generated_summary) {
			const summary = response?.generated_summary;
			setOutput(summary.substring(2, summary.length - 2));
		}
		if (response && !response?.generated_summary) {
			setIsLimitOver(true);
		}
	}, [response]);

	return (
		<div className="flex w-full flex-col items-center  sm:pl-5 lg:mx-16 ">
			<div className="my-4 mx-6 flex justify-center py-2 text-center text-2xl text-gray-500 ">
				Summarize your text in just one click by{" "}
				<span className="text-orangeCrushWarm"> Cruxe!</span>
			</div>
			<div className=" flex flex-col space-y-4 rounded-xl bg-white  p-2 sm:w-[85%] md:p-8 lg:flex-row lg:space-x-4 lg:space-y-0 xl:w-[80%] ">
				<div className="relative   min-h-[400px] w-full rounded-xl border-2 ">
					<form onSubmit={handleSubmit} className=" h-full">
						<div className="mb-3 flex flex-row justify-around bg-[#F1EAFB]  text-center  font-semibold md:pt-4 md:text-lg ">
							<div className="border-b-4 border-orangeCrushWarm px-2 text-orangeCrushWarm">
								Summarize for Social Media
							</div>
							<Link
								to="/dash/summarize-by-copy-paste"
								className="px-2 text-black hover:text-gray-700"
							>
								Summarize by Copy/Paste
							</Link>
						</div>

						{isLoading ? (
							<div className="mt-10 flex flex-col items-center justify-center">
								<Loader />
								<div
									style={{
										color: "gray",
										marginTop: "2rem",
										paddingBottom: "3rem",
									}}
									className="note-resp text-center"
								>
									<Quotes />
								</div>
							</div>
						) : (
							<div className=" h-full  ">
								<div className="mx-4 mb-4 ">
									<input
										type="text"
										placeholder="Enter URL"
										className="w-full rounded-lg border-none bg-[#F3F3F3] px-3 py-2 placeholder-neutral-500  outline-none"
										value={url}
										onChange={handleUrl}
									/>
								</div>

								{/* custom select drop down  */}
								<div className="mx-4 mb-20 ml-auto w-fit select-none rounded-md border-[2px] border-accentOrange px-3 py-2">
									<div
										className="flex cursor-pointer flex-row justify-between font-semibold"
										onClick={() => setSelectOpen(!selectOpen)}
									>
										<div className="flex flex-row items-center">
											{socialSelected && !selectOpen && (
												<img
													src={socialIconSelected}
													alt="Instagram Icon"
													className="mr-1 h-[20px] w-[20px] "
												/>
											)}
											<span>
												{socialSelected
													? selectOpen
														? "Share on Socials"
														: socialSelected
													: "Share on Socials"}
											</span>
										</div>
										<div
											className={`transition-transform-2ms flex items-center ${
												selectOpen ? "rotate-0" : "rotate-180"
											}`}
										>
											<VscTriangleUp />
										</div>
									</div>
									<div
										className={`transition-select-image  ${
											selectOpen
												? "mt-3 max-h-[400px]"
												: "mt-0 max-h-0"
										}  flex flex-col overflow-hidden font-semibold`}
									>
										<div
											className="flex cursor-pointer flex-row rounded py-1 text-sm hover:bg-accentOrange/25"
											onClick={() => {
												setSocialSelected("Instagram");
												setSocialIconSelected(instaIcon);
												setSelectOpen(!selectOpen);
											}}
										>
											<img
												src={instaIcon}
												alt="Instagram Icon"
												className="mr-1 h-[20px] w-[20px] "
											/>
											<span>Instagram</span>
										</div>

										<div
											className="flex cursor-pointer flex-row rounded py-1 text-sm hover:bg-accentOrange/25"
											onClick={() => {
												setSocialSelected("Twitter");
												setSocialIconSelected(twitterIcon);
												setSelectOpen(!selectOpen);
											}}
										>
											<img
												src={twitterIcon}
												alt="Twitter Icon"
												className="mr-1 h-[20px] w-[20px]"
											/>
											<span>Twitter</span>
										</div>
										<div
											className="flex cursor-pointer flex-row rounded py-1 text-sm hover:bg-accentOrange/25"
											onClick={() => {
												setSocialSelected("LinkedIn");
												setSocialIconSelected(linkedinIcon);
												setSelectOpen(!selectOpen);
											}}
										>
											<img
												src={linkedinIcon}
												alt="Linkedin Icon"
												className="mr-1 h-[20px] w-[20px]"
											/>
											<span>LinkedIn</span>
										</div>
										<div
											className="flex cursor-pointer flex-row rounded py-1 text-sm hover:bg-accentOrange/25"
											onClick={() => {
												setSocialSelected("Youtube");
												setSocialIconSelected(youtubeIcon);
												setSelectOpen(!selectOpen);
											}}
										>
											<img
												src={youtubeIcon}
												alt="Youtube Icon"
												className="mr-1 h-[20px] w-[20px]"
											/>
											<span>youtube</span>
										</div>
									</div>
								</div>

								{/* 
						<div className="flex flex-row items-center justify-center w-full mx-auto mt-5 mb-3">
							<div className="flex flex-row items-center justify-between w-3/4 mb-3">
								<div className="mr-4 text-lg font-semibold">Reset</div>
								<button
									type="submit"
									className="bg-accentOrange px-3 py-1 text-lg text-white rounded-lg">
									Summarize
								</button>
								<Canvabtn2 />
							</div>
						</div> */}
							</div>
						)}
					</form>

					{/* <div className="hidden w-[40%] sm:flex">
				<Tips />
			</div> */}
					<div className=" absolute bottom-0 flex w-full flex-row items-center justify-end border-t-[1px] ">
						<div className="my-4 mr-4  flex flex-col items-center ">
							<div className=" flex flex-row items-center">
								<div
									onClick={() => setUrl("")}
									className=" cursor-pointer px-4 text-sm font-semibold hover:text-black/75  md:text-lg"
								>
									Reset
								</div>
								<button
									type="submit"
									onClick={handleSubmit}
									className="rounded-lg bg-orangeCrushWarm px-6 py-2 font-semibold text-white md:text-lg "
								>
									Summarize
								</button>
							</div>
							{/* <Canvabtn2 /> */}
						</div>
					</div>
				</div>
				{/* <div className=" flex flex-col items-center  text-center">
					<span className="mt-2 text-sm font-semibold text-gray-400">
						Wordcount
					</span>
					<div className="flex w-fit flex-wrap rounded-md bg-[#F1EAFB] lg:flex-col  lg:py-2">
						<span className="wordCount-box ">25</span>
						<span className="wordCount-box "> 50</span>
						<span className="wordCount-box "> 75</span>
						<span className=" wordCount-box bg-orangeCrushWarm font-semibold text-white ">
							100
						</span>
						<span className="wordCount-box "> 125</span>
						<span className="wordCount-box"> 150</span>
						<span className="wordCount-box "> 175</span>
						<span className="wordCount-box "> 200</span>
						<span className="wordCount-box "> 225</span>
						<span className="wordCount-box "> 250</span>
					</div>
				</div> */}
				<div className="relative mt-4 mr-2 w-full rounded-xl border-2 bg-white   ">
					<div className="flex space-x-2 bg-[#F1EAFB] px-4 py-2">
						<span className="md:text-2xl">Summary </span>
						{"  "}
						<div className="flex items-end text-black md:text-base">
							<span>5 </span>
							<span className="text-[#858585]"> sentences </span>/
							<span> 56</span>
							<span className="text-[#858585]"> words </span>
						</div>
					</div>
					{response?.generated_summary && (
						<div className=" ">
							<textarea
								name="output"
								id="sumByURLoutput"
								cols={50}
								rows={6}
								className=" w-full border-none  bg-white p-3 text-justify focus:outline-none"
								value={output}
								onChange={(e) => setOutput(e.target.value)}
							></textarea>
							<div className="absolute bottom-2 flex w-full flex-row  items-center justify-end border-t-[1px] ">
								<div className="my-4 mr-4  flex flex-col items-center ">
									<button
										type="button"
										onClick={copyText}
										className="rounded-lg border-[1px] border-orangeCrushWarm px-4 py-1 font-semibold text-orangeCrushWarm transition-all duration-500 hover:bg-orangeCrushWarm  hover:text-white  md:text-lg "
									>
										{copied ? "Copied!" : "Copy"}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<WordLimitOver
				isLimitOver={isLimitOver}
				setIsLimitOver={setIsLimitOver}
			/>
		</div>
	);
}

export default SummarizeForSocialMedia;
