import React, { useState } from "react";

import Pic1 from "../../../assets/landing/howItWorks/select.png";
import Pic3 from "../../../assets/landing/howItWorks/select3.png";
import Pic2 from "../../../assets/dashboard4.png";
// import SvgBackground from "../../../assets/landing/howItWorks/svgBackground.svg";
// import SvgBackground from "../../../assets/landing/howItWorks/SvgBackground.svg";

const HowItWorks = () => {
	const [image, setImage] = useState(Pic1);

	return (
		<div className=" mb-0 flex flex-col items-center justify-center md:mx-10   lg:mx-28 lg:-mt-12 xl:mx-44">
			<div className="mb-16 text-4xl font-semibold text-gray-600 md:text-6xl">
				How it Works
			</div>
			<div className="mb-5 flex w-full flex-col items-center justify-center sm:flex-row">
				<div className=" flex h-[14rem] w-full scale-[90%] items-center justify-center rounded-xl sm:mr-10 sm:h-[22rem]">
					<img
						src={image || Pic1}
						alt="image"
						className=" h-full rounded-xl   object-contain  shadow-md md:shadow-xl "
					/>
				</div>
				<div className="  mt-2 flex w-full flex-col sm:mx-2 sm:ml-10 sm:mt-0">
					<div
						onClick={() => setImage(Pic1)}
						className={` ${
							image == Pic1 && "bg-orangeCrushWarm px-2 py-4 text-white"
						} mb-4 flex cursor-pointer flex-row items-center rounded-xl border-2 border-accentOrange px-2 py-4  `}
					>
						<div className="mr-4 flex max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] items-center justify-center  rounded-full border-2  border-accentOrange  p-3 text-center text-xl font-semibold">
							1
						</div>
						<div className="text-normal sm:text-lg">
							Select the niche in which you create content
						</div>
					</div>
					<div
						onClick={() => setImage(Pic2)}
						className={` ${
							image == Pic2 && "bg-orangeCrushWarm px-2 py-4 text-white"
						} mb-4 flex cursor-pointer flex-row items-center rounded-xl border-2 border-orangeCrushWarm px-2 py-4`}
					>
						<div className="mr-4 flex max-h-[40px]  min-h-[40px] min-w-[40px] max-w-[40px]  items-center justify-center rounded-full border-2 border-accentOrange p-3 text-center text-xl font-semibold">
							2
						</div>
						<div className="text-normal sm:text-lg">
							Choose an idea from your personalized feed
						</div>
					</div>
					<div
						onClick={() => setImage(Pic3)}
						className={`
						${image == Pic3 && "bg-orangeCrushWarm px-2 py-4 text-white"}
						flex cursor-pointer flex-row items-center rounded-xl border-2 border-orangeCrushWarm px-2 py-4`}
					>
						<div className="mr-4 flex max-h-[40px]  min-h-[40px] min-w-[40px] max-w-[40px]  items-center justify-center rounded-full border-2 border-accentOrange p-3 text-center text-xl font-semibold">
							3
						</div>
						<div className="text-normal sm:text-lg">
							Summarise it for getting a script real quick
						</div>
					</div>
				</div>
			</div>
			<div className=" h-full w-full">
				<div className="container mx-1 rounded-sm sm:mx-0 md:shadow-xl">
					<iframe
						className="responsive-iframe  shadow-sm md:rounded-2xl "
						src="https://www.youtube.com/embed/T7lvwTCvuGo"
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default HowItWorks;
