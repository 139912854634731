import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isFirefox } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideBarIcon from "../../../assets/sidebar/SideBarIcon";
import Logo from "../../../assets/cruxeLogo.jpg";

import { logout } from "../../../redux/features/slices/authSlice";
import { apiSlice } from "../../../redux/features/apiSlice";
import AvatarDropdown from "./other/AvatarDropdown";
import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";

import { setSidebarOpen } from "../../../redux/features/slices/otherSlice";

// icons
import { AiOutlineClose } from "react-icons/ai";

export enum Page {
	HOME = "home",
	SUMMARIZE_FOR_SOCIAL_MEDIA = "summarize-for-social-media",
	SUMMARIZE_BY_COPY_PASTE = "summarize-by-copy-paste",
	HISTORY = "history",
	ADD_EXTENSION = "add-extension",
	PRICING = "pricing",
	SAVED_CONTENT = "saved-content",
	CANVA = "canva",
}

const Sidebar: React.FC = () => {
	const isSidebarOpen = useTypedSelector((state) => state.other.showSidebar);

	const [selected, setSelected] = useState<Page>(Page.HOME);
	let path = window.location.pathname;

	useEffect(() => {
		if (path === "/dash/home") {
			setSelected(Page.HOME);
		} else if (path === "/dash/saved-content") {
			setSelected(Page.SAVED_CONTENT);
		} else if (
			path === "/dash/summarize-for-social-media" ||
			path === "/dash/summarize-by-copy-paste"
		) {
			setSelected(Page.SUMMARIZE_FOR_SOCIAL_MEDIA);
		} else if (path === "/dash/history") {
			setSelected(Page.HISTORY);
		} else if (path === "/dash/canva") {
			setSelected(Page.CANVA);
		} else {
			setSelected(Page.HOME);
		}
	}, [path]);

	var link;
	if (isFirefox) {
		link =
			"https://addons.mozilla.org/en-US/firefox/addon/cruxe-summarise-text-using-ai/";
	} else {
		link =
			"https://chrome.google.com/webstore/detail/cruxe-summarise-text-usin/fbflpifoodcfknnolhnambbiekfjekmb?authuser=1";
	}
	const dispatch = useDispatch();

	const navigate = useNavigate();

	// const handleLogout = () => {
	// 	localStorage.removeItem("auth");
	// 	dispatch(logout());
	// 	dispatch(apiSlice.util.resetApiState());
	// 	//@todo navigate to /
	// 	navigate("/login");
	// };

	// console.log(path);

	const handleClose = () => {
		dispatch(dispatch(setSidebarOpen(false)));
	};

	return (
		<div
			className={`fixed z-[100] 
		 h-screen w-[85px]
		 transform
		  bg-white px-0 pt-2 shadow-lg transition-all   duration-100 ease-in-out   lg:translate-x-0
		${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
		`}
		>
			<div className="relative h-full">
				<div
					onClick={handleClose}
					className={` ${
						!isSidebarOpen && "hidden"
					} absolute -right-6 top-1 flex cursor-pointer items-center justify-end rounded-full border-r-[3px] bg-white pl-2 transition-all duration-300 hover:border-r-[6px]  lg:hidden `}
				>
					<AiOutlineClose className="rounded-full p-1 text-4xl text-gray-600 opacity-75 hover:text-gray-800 active:bg-[#FDF0E7] " />
				</div>
				<div className="flex h-full flex-col  items-center justify-between ">
					<Link
						onClick={handleClose}
						to="/"
						className="mt-1 flex justify-center"
					>
						<img src={Logo} alt="logo" className="h-10" />{" "}
					</Link>
					<nav className="mb-16 flex w-full flex-col items-center justify-center">
						<Link
							onClick={handleClose}
							to="/dash/home"
							style={{ textDecoration: "none" }}
							className={` ${
								selected === Page.HOME ? "bg-[#FDF0E7]" : ""
							} tab-link-box sidebarIcon relative `}
						>
							<SideBarIcon
								name={Page.HOME}
								isSelected={selected === Page.HOME}
							/>
						</Link>

						<Link
							onClick={handleClose}
							to="/dash/summarize-for-social-media"
							style={{ textDecoration: "none" }}
							className={` ${
								selected === Page.SUMMARIZE_FOR_SOCIAL_MEDIA
									? "bg-[#FDF0E7]"
									: ""
							} tab-link-box sidebarIcon relative `}
						>
							<SideBarIcon
								name={Page.SUMMARIZE_FOR_SOCIAL_MEDIA}
								isSelected={
									selected === Page.SUMMARIZE_FOR_SOCIAL_MEDIA
								}
							/>
						</Link>
						{/* <Link to="/dash/summarize-by-copy-paste" className="tab-link-box mb-[32px] relative">
					<SideBarIcon
						name={Page.SUMMARIZE_BY_COPY_PASTE}
						isSelected={selected === Page.SUMMARIZE_BY_COPY_PASTE}
					/>
				</Link> */}

						<Link
							onClick={handleClose}
							to="/dash/history"
							style={{ textDecoration: "none" }}
							className={` ${
								selected === Page.HISTORY ? "bg-[#FDF0E7]" : ""
							} tab-link-box sidebarIcon relative `}
						>
							<SideBarIcon
								name={Page.HISTORY}
								isSelected={selected === Page.HISTORY}
							/>
						</Link>

						{/* <a
					href={link}
					target="_blank"
					rel="noreferrer"
					className={`tab-link-box mb-[32px] -translate-x-[4px] text-black hover:text-black/75 `}
				>
					<SideBarIcon
						name={Page.ADD_EXTENSION}
						isSelected={selected === Page.ADD_EXTENSION}
					/>
				</a> */}
						{/* <Link to="/pricing" className="tab-link-box mb-[32px] relative">
					<SideBarIcon name={Page.PRICING} isSelected={selected === Page.PRICING} />
				</Link> */}

						<Link
							onClick={handleClose}
							to="/dash/saved-content"
							style={{ textDecoration: "none" }}
							className={`text textDecoration ${
								selected === Page.SAVED_CONTENT ? "bg-[#FDF0E7]" : ""
							} tab-link-box sidebarIcon relative `}
						>
							<SideBarIcon
								name={Page.SAVED_CONTENT}
								isSelected={selected === Page.SAVED_CONTENT}
							/>
						</Link>

						<Link
							onClick={handleClose}
							to="/dash/canva"
							style={{ textDecoration: "none" }}
							className={`text textDecoration ${
								selected === Page.CANVA ? "bg-[#FDF0E7]" : ""
							} tab-link-box sidebarIcon relative `}
						>
							<SideBarIcon
								name={Page.CANVA}
								isSelected={selected === Page.CANVA}
							/>
						</Link>
						<div className=" mt-2 lg:hidden ">
							<AvatarDropdown flexDirection="flex-col" />
						</div>
					</nav>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
