import React from "react";
import { IconProp } from "../assetInterfaces";

const InstaIcon = ({ width, height, isSelected }: IconProp) => {
	return (
		<>
			{/* <svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				className={`svg-icon transition-transform-2ms -translate-x-[2px]   ${
					isSelected
						? "-translate-y-1 fill-accentOrange text-accentOrange"
						: "translate-y-0"
				} `}
				fill="none"
				viewBox="0 0 48 48"
			>
				<rect
					width="29.5"
					height="37.5"
					x="9.25"
					y="5.25"
					stroke="#858585"
					strokeWidth="3.5"
					rx="3.75"
				></rect>
				<path
					fillRule="evenodd"
					d="M24.012 15a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 4a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 9a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 4a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm-8-4v3h3v-3h-3zm-1-2h5a1 1 0 011 1v5a1 1 0 01-1 1h-5a1 1 0 01-1-1v-5a1 1 0 011-1zm6.707-11.707a1 1 0 010 1.414l-4.707 4.707-2.707-2.707a1 1 0 111.414-1.414l1.293 1.293 3.293-3.293a1 1 0 011.414 0z"
					clipRule="evenodd"
				></path>
			</svg> */}

			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				fill="none"
				className={`svg-icon transition-transform-2ms -translate-x-[2px]   ${
					isSelected
						? "-translate-y-1   text-accentOrange"
						: "translate-y-0"
				} `}
				viewBox="0 0 34 42"
			>
				<rect
					width="29.5"
					height="37.5"
					x="2.25"
					y="2.25"
					stroke={`${isSelected ? "#EE732E" : "#858585"}`}
					strokeWidth="3.5"
					rx="3.75"
				></rect>
				<path
					fill={`${isSelected ? "#EE732E" : "#858585"}`}
					fillRule="evenodd"
					d="M17.012 12a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 4a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 9a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm0 4a1 1 0 011-1h8a1 1 0 010 2h-8a1 1 0 01-1-1zm-8-4v3h3v-3h-3zm-1-2h5a1 1 0 011 1v5a1 1 0 01-1 1h-5a1 1 0 01-1-1v-5a1 1 0 011-1zm6.707-11.707a1 1 0 010 1.414l-4.707 4.707-2.707-2.707a1 1 0 111.414-1.414l1.293 1.293 3.293-3.293a1 1 0 011.414 0z"
					clipRule="evenodd"
				></path>
			</svg>
		</>
	);
};

export default InstaIcon;
