import React, { useState, useEffect, useRef } from "react";
import Tag from "./other/Tag";
import { useUpdateTagsMutation } from "../../../redux/features/apis/contentApi";
import Logo from "../../../assets/cruxeLogo.jpg";
import { Modal } from "@mui/material";

import { useGetTagsQuery } from "../../../redux/features/apis/contentApi";

export enum Tags {
	TECHNOLOGY = "Technology",
	SPACE = "Space",
	WEB3CRYPTO = "Web3 & Crypto",
	FACTS = "Facts",
	SCIENCE = "Science",
	FINANCE = "Finance",
	STARTUP = "Startup",
	CASE_STUDIES = "Case Studies of Companies",
}

const Person = {
	name: "John Doe",
	image: Logo,
	description:
		"John Doe is a software engineer who is passionate about building great products.",
	id: "1",
};

function TagsModal({ close }: any) {
	const [categories, setCategories] = useState<Tags[]>([]);

	const [updateTags] = useUpdateTagsMutation();
	const { data: tags, isSuccess: isSuccessTags } = useGetTagsQuery();

	const [open, setOpen] = useState(true);
	const handleClose = () => {
		setOpen(false);
		close();
	};

	const handleSubmit = () => {
		updateTags(categories);
		handleClose();
	};

	useEffect(() => {
		if (isSuccessTags && tags) {
			tags.forEach((tag: Tags) => {
				// if tag is not already in categories, add it
				if (!categories.includes(tag)) {
					setCategories((prevCategories) => [...prevCategories, tag]);
				}
			});
		}
	}, [isSuccessTags, tags]);

	const handleSelectCat = (name: Tags) => {
		if (categories.find((element) => element === name)) {
			if (categories.length < 4) {
				// remove name from categories

				setCategories((prevCategories) => {
					const newCategories = prevCategories.filter(
						(category) => category !== name
					);
					return newCategories;
				});
			}
		} else {
			if (categories.length < 3) {
				// add name to categories
				setCategories((prevCategories) => [...prevCategories, name]);
			}
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="tags nodal"
			aria-describedby="Update or add tags"
		>
			<div className="absolute top-1/2 left-1/2 h-fit w-full  -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white p-4 sm:w-fit sm:max-w-[500px]">
				<div
					className="absolute right-2 top-0 cursor-pointer text-5xl text-black/50 hover:text-black"
					onClick={handleClose}
				>
					&times;
				</div>
				<div className="mb-6 flex justify-center">
					<img src={Logo} alt="Logo" style={{ width: "40px" }} />
				</div>
				<div className=" mb-8">
					<div className="text-xl font-bold">What are you into?</div>
					<div className="text-sm text-slate-400">Select any 3</div>
				</div>
				<div className="flex flex-wrap">
					{Object.values(Tags).map((tag: Tags) => (
						<Tag
							key={tag}
							name={tag}
							handleSelectCat={handleSelectCat}
							selected={categories.includes(tag)}
						/>
					))}
				</div>
				<div style={{ textAlign: "center" }}>
					<button
						onClick={handleSubmit}
						id="categorybtn"
						style={{
							borderRadius: "52px",
							padding: "0.4rem 5rem",
							color: "white",
						}}
					>
						<h4>Continue</h4>
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default TagsModal;
