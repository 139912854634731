import { useRef, useEffect, useCallback } from "react";
import LazyLoad from "react-lazyload";

// imported components
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import RiseLoader from "react-spinners/RiseLoader";

// redux
import {
	useLazyFetchYoutubeQuery,
	useSavePostMutation,
	useFetchSavedPostsQuery,
	useUnsavePostMutation,
} from "../../../../redux/features/apis/contentApi";
import { useTypedSelector } from "../../../../redux/hooks/useTypedSelector";
import Filter from "./comp/filter";

// utils
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";
function numFormatter(num: number) {
	if (num > 999 && num < 1000000) {
		return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
	} else if (num > 1000000) {
		return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
	} else if (num < 900) {
		return num; // if value < 1000, nothing to do
	}
}

function Youtube({ isSaved }: { isSaved: boolean }) {
	const pageOptions = useTypedSelector((state) => state.content.youtube);

	const [fetchYoutubeVideos, { data, isSuccess, isFetching }] =
		useLazyFetchYoutubeQuery();
	const [
		savePost,
		{ data: saveResponse, isLoading: isLoadingSave, isError: isErrorSave },
	] = useSavePostMutation();
	const {
		data: savedPosts,
		isLoading: isLoadingSaved,
		isError: isErrorSaved,
	} = useFetchSavedPostsQuery(undefined, { skip: !isSaved });
	const [
		unsavePost,
		{
			data: unsaveResponse,
			isLoading: isLoadingUnsave,
			isError: isErrorUnsave,
		},
	] = useUnsavePostMutation();

	useEffect(() => {
		fetchYoutubeVideos({
			pageNumber: pageOptions.pageNumber,
			filter: pageOptions.filter,
			value: pageOptions.value,
		});
	}, [pageOptions.pageNumber, pageOptions.filter, pageOptions.value]);

	const lastPostObserver = useIntersectionObserver({
		type: "youtube",
		isSuccess,
		isFetching,
		hasMore: data?.hasMore,
	});

	return (
		<div className="flex flex-col items-center">
			{/*<Filter type="youtube" options={["relevance", "category"]} />*/}

			{((isSaved && savedPosts?.yt > 0) || data?.youtubeVideos) && (
				<div className="mb-5 grid grid-cols-1 justify-center gap-y-12 gap-x-4 pt-16 sm:w-3/4 sm:grid-cols-2 sm:pt-3">
					{Array.from(isSaved ? savedPosts?.yt : data?.youtubeVideos).map(
						(ytPost: any, i) => (
							<div className=" flex justify-center" key={i}>
								{data?.youtubeVideos.length === i + 3 && !isSaved && (
									<div
										ref={lastPostObserver}
										className="absolute"
									></div>
								)}

								<div className="mx-4 w-full overflow-hidden bg-[#F0EFEF] shadow">
									<div className="text-center">
										<LazyLoad height={300} once offset={0}>
											<iframe
												src={ytPost.embed_url}
												width="100%"
												height="240"
												title="YouTube video player"
												frameBorder="0"
												className="rounded"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
											></iframe>
										</LazyLoad>
									</div>
									<div className="flex flex-row justify-between py-3 px-1 text-[14px]">
										<div className="flex flex-row">
											<div className="min-w-[36px] translate-y-1">
												<img
													src={ytPost.profile_photo_url}
													alt="profile"
													className="w-[48px] rounded-full"
												/>
											</div>
											<div className="mx-auto">
												<div className="py-1 px-1  font-bold text-[#141414]">
													<span className=" mr-2 p-1">
														{ytPost.title}
													</span>
												</div>
												<div className="mb ml-2 flex flex-col text-xs">
													<span className=" mr-3">
														{ytPost.channel_name}
													</span>
													<div className="flex flex-row justify-start">
														<span className="mr-1">
															{numFormatter(ytPost.views)} views
														</span>
														{/* <span className="mr-1">·</span> */}
														{/* <span className="">2 weeks ago </span> */}
													</div>
												</div>
											</div>
										</div>
										<div className=" mr-2 flex items-end">
											<a
												href={ytPost.post_url}
												target="_blank"
												rel="noreferrer"
												className="mr-3 cursor-pointer text-black"
											>
												<FaExternalLinkAlt size="22px" />
											</a>
											<div className=" cursor-pointer">
												{ytPost.saved || isSaved ? (
													<FaBookmark
														size="23px"
														className="cursor-pointer"
														onClick={() =>
															unsavePost({
																post_id: ytPost.id,
																type_of_post: "youtube",
															})
														}
													/>
												) : (
													<FaRegBookmark
														size="23px"
														className="cursor-pointer"
														onClick={() =>
															savePost({
																post_id: ytPost.id,
																type_of_post: "youtube",
															})
														}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			)}

			{isSaved && savedPosts?.yt.length === 0 && (
				<div className="mx-auto text-center text-xl text-black/75">
					Nothing here!
				</div>
			)}

			{!isSaved && (
				<div className="mb-4 flex justify-center p-4">
					{isFetching && (
						<div className="">
							<RiseLoader color="#f89b55" />
						</div>
					)}
				</div>
			)}

			{!isSaved && data && !data?.hasMore && (
				<div className="mb-10 flex flex-row justify-center">
					<div className="flex  w-[70%] cursor-pointer flex-col items-center justify-center rounded-xl bg-orangeCrushWarm py-5 text-white shadow-md hover:scale-105">
						<h4>Upgrade to premium to unlock </h4>
						<FaLock size={40} />
						<div></div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Youtube;
