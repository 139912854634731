import React from "react";

const FinanceImg = ({width = 100, height = 100}: {width: number; height: number}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			data-name="Layer 1"
			viewBox="0 0 763.057 458">
			<path
				fill="#f2f2f2"
				d="M48 96a48 48 0 1148-48 48.054 48.054 0 01-48 48zm0-94a46 46 0 1046 46A46.052 46.052 0 0048 2zM374 68a24 24 0 1124-24 24.027 24.027 0 01-24 24zm0-46a22 22 0 1022 22 22.025 22.025 0 00-22-22zM543 185a27 27 0 1127-27 27.03 27.03 0 01-27 27zm0-52a25 25 0 1025 25 25.028 25.028 0 00-25-25z"></path>
			<path fill="#ccc" d="M762.057 458h-225a1 1 0 010-2h225a1 1 0 010 2z"></path>
			<circle cx="640.917" cy="128.686" r="24.561" fill="#ffb8b8"></circle>
			<path
				fill="#ffb8b8"
				d="M643.465 420.343L631.857 424.285 611.124 381.386 628.257 375.567 643.465 420.343z"></path>
			<path
				fill="#2f2e41"
				d="M622.439 423.785l22.387-7.604 4.788 14.096-36.483 12.392a14.887 14.887 0 019.308-18.884z"></path>
			<path
				fill="#ffb8b8"
				d="M669.694 444.575L657.45 445.19 649.249 398.255 667.321 397.346 669.694 444.575z"></path>
			<path
				fill="#2f2e41"
				d="M648.528 442.131l23.614-1.187.747 14.868-38.482 1.934a14.887 14.887 0 0114.121-15.615z"></path>
			<path
				fill="#ffb8b8"
				d="M596.829 288.63a10.056 10.056 0 00-.468-15.412l11.674-33.774-17.991 4.597-8.328 31.368a10.11 10.11 0 0015.113 13.221z"></path>
			<path
				fill="#2f2e41"
				d="M668.55 430.424a4.544 4.544 0 01-.474-.026l-16.62-1.76a4.472 4.472 0 01-3.999-3.973l-12.845-114.51-9.294 31.335 17.329 61.583a4.5 4.5 0 01-3.818 5.69l-16.848 1.936a4.523 4.523 0 01-4.863-3.315l-18.409-69.303a4.482 4.482 0 01-.111-1.748l8.274-62.199 61.714 4.95 4.462 146.702a4.5 4.5 0 01-4.498 4.638z"></path>
			<path
				fill="#f89b55"
				d="M647.217 289.719c-20.676 0-44.085-7.989-45.502-8.48l-.41-.142 23.324-119.684.43.018c9.243.375 34.469.567 34.723.569l.452.003 9.723 105.62 1.738 15.448-.213.172c-5.948 4.809-14.821 6.476-24.265 6.476z"></path>
			<path
				fill="#2f2e41"
				d="M609.864 279.375l-14.589-5.876 7.436-89.944a14.856 14.856 0 016.474-11.103l7.677-5.225a4.868 4.868 0 014.827-6.24l2.2.071a2.5 2.5 0 013.826 2.66l-.483 9.53z"></path>
			<path
				fill="#2f2e41"
				d="M602.503 263.251l-19.049-.802 6.17-39.693 5.114-37.348a8.924 8.924 0 0111.578-7.846 8.917 8.917 0 016.174 9.647l-5.18 40.467zM632.665 280.751l-.13-.4c-.088-.275-8.673-27.812 5.692-60.673l7.203-35.825 6.155-22.495a2.69 2.69 0 011.632-1.773 2.477 2.477 0 012.648.68 17.198 17.198 0 0112.86 1.286l8.645 4.554a9.815 9.815 0 014.981 10.923l-11.815 50.423 5.743 47.097z"></path>
			<path
				fill="#ffb8b8"
				d="M645.245 261.338a10.056 10.056 0 0011.118-10.684l26.756-19.821-9.353-4.434-28.851 14.862a10.11 10.11 0 00.33 20.077z"></path>
			<path
				fill="#2f2e41"
				d="M662.02 252.465l-9.037-16.869 22.287-14.668-10.876-39.01a11.795 11.795 0 019.03-14.731 11.827 11.827 0 0113.5 7.764l14.674 43.135a4.5 4.5 0 01-1.511 5.013zM642.655 133.895c-2.944.383-5.165-2.629-6.195-5.414s-1.815-6.026-4.375-7.53c-3.497-2.056-7.972.416-11.971-.262-4.516-.766-7.453-5.552-7.683-10.127s1.59-8.976 3.377-13.194l.624 5.242a10.396 10.396 0 014.542-9.086l-.803 7.692a8.164 8.164 0 019.392-6.756l-.127 4.584c5.217-.62 10.479-1.242 15.71-.77s10.501 2.129 14.467 5.574c5.931 5.154 8.098 13.641 7.37 21.466s-3.957 15.174-7.323 22.275c-.847 1.786-2.018 3.802-3.982 4.03-1.765.204-3.38-1.27-3.928-2.96a10.73 10.73 0 01.047-5.271c.497-2.638 1.122-5.333.656-7.976s-2.368-5.254-5.032-5.578-5.39 2.721-4.11 5.08z"></path>
			<path
				fill="#e6e6e6"
				d="M321.31 403.577l-9.56-4.026-6.56-47.928h-87.34l-7.108 47.733-8.554 4.276a2.031 2.031 0 00.908 3.848h117.426a2.031 2.031 0 00.788-3.903z"></path>
			<path fill="#ccc" d="M476.519 358H47.481A8.5 8.5 0 0139 349.5V289h446v60.5a8.5 8.5 0 01-8.481 8.5z"></path>
			<path
				fill="#3f3d56"
				d="M485.448 317.594H38.608V58.727A10.258 10.258 0 0148.855 48.48h426.346a10.259 10.259 0 0110.247 10.247z"></path>
			<path
				fill="#fff"
				d="M458.76 298.805H65.296a7.908 7.908 0 01-7.9-7.9V75.169a7.909 7.909 0 017.9-7.9H458.76a7.909 7.909 0 017.9 7.9v215.738a7.908 7.908 0 01-7.9 7.9z"></path>
			<path fill="#ccc" d="M455.057 407.98h-381a1 1 0 010-2h381a1 1 0 010 2z"></path>
			<path
				fill="#3f3d56"
				d="M421.796 272H102.261a1 1 0 01-1-1V117.847a1 1 0 012 0V270h318.535a1 1 0 010 2z"></path>
			<path
				fill="#f89b55"
				d="M170.531 261h-29.063a2.972 2.972 0 01-2.968-2.968v-40.064a2.972 2.972 0 012.968-2.968h29.063a2.972 2.972 0 012.969 2.968v40.064a2.972 2.972 0 01-2.969 2.968zM223.531 261h-29.063a2.972 2.972 0 01-2.968-2.968v-78.064a2.972 2.972 0 012.968-2.968h29.063a2.972 2.972 0 012.969 2.968v78.064a2.972 2.972 0 01-2.969 2.968zM276.531 261h-29.063a2.972 2.972 0 01-2.968-2.968v-40.064a2.972 2.972 0 012.968-2.968h29.063a2.972 2.972 0 012.969 2.968v40.064a2.972 2.972 0 01-2.969 2.968zM329.531 261h-29.063a2.907 2.907 0 01-2.968-2.834v-93.332a2.907 2.907 0 012.968-2.834h29.063a2.907 2.907 0 012.969 2.834v93.332a2.907 2.907 0 01-2.969 2.834zM382.531 261h-29.063a2.972 2.972 0 01-2.968-2.968V137.968a2.972 2.972 0 012.968-2.968h29.063a2.972 2.972 0 012.969 2.968v120.064a2.972 2.972 0 01-2.969 2.968z"></path>
			<circle cx="156" cy="197" r="6" fill="#3f3d56"></circle>
			<circle cx="209" cy="158" r="6" fill="#3f3d56"></circle>
			<circle cx="262" cy="197" r="6" fill="#3f3d56"></circle>
			<circle cx="315" cy="139" r="6" fill="#3f3d56"></circle>
			<circle cx="368" cy="117" r="6" fill="#3f3d56"></circle>
			<path
				fill="#3f3d56"
				d="M262.126 198.344L209 158.552 156.6 197.8 155.4 196.2 209 156.053 261.874 195.656 314.415 138.158 314.626 138.072 367.626 116.718 368.374 118.573 315.585 139.842 262.126 198.344z"></path>
		</svg>
	);
};

export default FinanceImg;
