import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

// baseUrl: "http://3.109.107.2:7000/api/",

export const apiSlice = createApi({
	reducerPath: "apiSlice",

	baseQuery: fetchBaseQuery({
		baseUrl: "https://apicruxe.xyz/api/",
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;

			// If we have a token set in state, let's assume that we should be passing it.
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	keepUnusedDataFor: 9999999, //keep cache data forever

	tagTypes: [
		"trendingBlogs",
		"blogs",
		"tweets",
		"youtube",
		"reddit",
		"creators",
		"stats",
		"tag",
		"user",
	],

	endpoints: () => ({}),
});
