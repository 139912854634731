// redux create slice template
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

let savedAuth;
const authFromLocalStorage = localStorage.getItem("auth");
if (authFromLocalStorage) {
	savedAuth = JSON.parse(authFromLocalStorage);
}

interface User {
	firstName: string;
	lastName: string;
	email: string;
	mobile: string;
	account_activated: boolean;
	dateJoined: string;
	dateOfUpdation: string;
	used_count: number;
	type_of_plan: string;
	team_member_count: number;
	team_subscription: null;
	tags: string[];
}

interface AuthState {
	user: User | null;
	token: string | null;
}

const initialState: AuthState = {
	// isAuthenticated: false,
	user: savedAuth ? savedAuth.user : null,
	token: savedAuth ? savedAuth.token : null,
	// refreshToken: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setAuth: (state: any, action: PayloadAction<{token: string; user: User | null}>) => {
			state.user = action.payload.user;
			state.token = action.payload.token;
		},
		logout: (state: any) => {
			state.user = null;
			state.token = null;
		},
	},
});

export const {setAuth, logout} = authSlice.actions;

export default authSlice.reducer;
