import React from "react";
import { IconProp } from "../assetInterfaces";

const SavedIcon = ({ width, height, isSelected }: IconProp) => {
	return (
		<>
			<svg
				width={width}
				height={height}
				fill="gray"
				viewBox="0 0 48 48"
				className={`transition-transform-2ms translate-x-[2px]  ${
					isSelected
						? "-translate-y-1 fill-[#EE732E] hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M34 6H14c-2.2 0-3.98 1.8-3.98 4L10 42l14-6 14 6V10c0-2.2-1.8-4-4-4zm0 30l-10-4.36L14 36V10h20v26z"></path>
			</svg>

			{/* <svg
				width={width}
				height={height}
				viewBox="0 0 27 27"
				fill="black"
				className={`translate-x-[2px] ${
					isSelected
						? "-translate-y-1 fill-accentOrange hover:fill-accentOrange"
						: "translate-y-0 hover:fill-black/75"
				} `}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1099_22)">
					<path d="M3.27606 3.27615V25.3902C3.27596 25.5324 3.31289 25.6722 3.38323 25.7958C3.45357 25.9194 3.55489 26.0226 3.67721 26.0952C3.79954 26.1677 3.93865 26.2072 4.08086 26.2097C4.22306 26.2122 4.36346 26.1776 4.48824 26.1093L13.1045 21.408L21.7208 26.1093C21.8456 26.1776 21.986 26.2122 22.1282 26.2097C22.2704 26.2072 22.4095 26.1677 22.5318 26.0952C22.6541 26.0226 22.7555 25.9194 22.8258 25.7958C22.8961 25.6722 22.9331 25.5324 22.933 25.3902V3.27615C22.933 2.40726 22.5878 1.57396 21.9734 0.959562C21.359 0.345165 20.5257 0 19.6568 0L6.55221 0C5.68332 0 4.85002 0.345165 4.23562 0.959562C3.62123 1.57396 3.27606 2.40726 3.27606 3.27615Z" />
				</g>
				<defs>
					<clipPath id="clip0_1099_22">
						<rect width="26.2092" height="26.2092" fill="white" />
					</clipPath>
				</defs>
			</svg> */}
		</>
	);
};

export default SavedIcon;
