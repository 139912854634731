import { useGetSummaryMutation } from "../../../../../redux/features/apis/summaryApi";
import BeatLoader from "react-spinners/BeatLoader";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const BlogSummary = ({
	id,
	isTrending,
	setIsLimitOver,
	setIsSummary,
}: {
	id: number;
	setIsLimitOver: (isLimitOver: boolean) => void;
	setIsSummary: (isSummary: boolean) => void;
	isTrending?: boolean;
}) => {
	const [getSummary, { data: summaryData, isLoading: isLoadingSummary }] =
		useGetSummaryMutation();

	const [delay, setDelay] = useState(true);
	// @Todo - 1 sec delay

	const handleGetSummary = () => {
		setDelay(true);
		setTimeout(() => {
			if (isTrending) {
				getSummary({ id, isTrending });
			} else {
				getSummary({ id });
			}
			setDelay(false);
		}, 1000);
	};

	useEffect(() => {
		summaryData?.error && setIsLimitOver(true);
		summaryData?.summary && setIsSummary(true);
	}, [summaryData]);

	// summaryData && console.log("summaryData", summaryData);

	return (
		<div className="pl-1">
			{!summaryData && !isLoadingSummary && (
				<div
					className="j_summarizeBlog cursor-pointer  text-black/50 underline sm:text-base"
					onClick={handleGetSummary}
				>
					Summarize{" "}
					<span className="font-extrabold text-orangeCrushWarm">
						{"->"}
					</span>
				</div>
			)}

			{isLoadingSummary && (
				<div className="mt-2 flex w-full justify-center">
					<BeatLoader color="#f89b55" />
				</div>
			)}

			{summaryData && (
				<div>
					<div className="flex w-full flex-row items-end justify-between">
						{summaryData.summary && (
							<div className="w-[95%] text-justify">
								<details open>
									<summary className="-offset-4 pb-1 text-sm text-[#6C6C6C] underline sm:text-base">
										Summary
									</summary>
									<p className="text-xs sm:text-base">
										{summaryData.summary}
									</p>
								</details>
							</div>
						)}
						{!summaryData.summary && (
							<p className=" w-full text-center text-xs text-red-500 sm:text-base">
								{summaryData?.error
									? summaryData.error
									: "We don't have a summary yet"}
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default BlogSummary;
