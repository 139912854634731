import {
	GET_PRICING,
	GET_PRICING_ERROR,
	PAY_AMOUNT,
	REMOVE_PAY_AMOUNT,
	PAY_AMOUNT_ERROR,
	GET_PRICING_BY_ID,
	FETCH_COUPONINFO,
	FETCH_COUPONINFOFAILED,
} from "./types";
import axios from "axios";

const proxy = "https://apicruxe.xyz";
const proxy1 = "https://apicruxe.xyz/api/products";
// const proxy1 = "http://3.7.45.147:8080/api/products";

// Get Pricing
export const getPricing = (planType, currency) => async (dispatch) => {
	try {
		const res = await axios.get(proxy1 + `/plans/${planType}/${currency}/`);

		dispatch({
			type: GET_PRICING,
			payload: res.data,
		});
	} catch (e) {
		dispatch({
			type: GET_PRICING_ERROR,
		});
	}
};

export const getCoupon = (coupon) => async (dispatch) => {
	try {
		const res = await axios.get(proxy1 + `/couponInfo/${coupon}/`);

		dispatch({
			type: FETCH_COUPONINFO,
			payload: res.data,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: FETCH_COUPONINFOFAILED,
			payload:
				error.response && error.response.message
					? error.respnse.message
					: error.message,
		});
	}
};

// Get Pricing By Id
export const getPricingById = (id) => async (dispatch) => {
	try {
		const res = await axios.get(proxy + "/api/products/plan/" + id);
		dispatch({
			type: GET_PRICING_BY_ID,
			payload: res.data[0],
		});
	} catch (e) {
		dispatch({
			type: GET_PRICING_ERROR,
		});
	}
};

// Pay amount
export const payAmount = (token, plan_id, coupon) => async (dispatch) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const body = {
		plan_id: plan_id,
		coupon_code: coupon,
	};
	try {
		const res = await axios.post(proxy1 + "/pay/", body, config);
		// console.log(res);
		dispatch({
			type: PAY_AMOUNT,
			payload: res.data,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: PAY_AMOUNT_ERROR,
			payload:
				error.response && error.response.message
					? error.respnse.message
					: error.message,
		});
	}
};

export const payAmount1 = (token, plan_id) => async (dispatch) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	const body = {
		plan_id: plan_id,
	};
	try {
		const res = await axios.post(proxy1 + "/pay/", body, config);
		console.log(res);
		dispatch({
			type: PAY_AMOUNT,
			payload: res.data,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: PAY_AMOUNT_ERROR,
			payload:
				error.response && error.response.message
					? error.respnse.message
					: error.message,
		});
	}
};

export const removePayAmount = () => async (dispatch) => {
	dispatch({
		type: REMOVE_PAY_AMOUNT,
		payload: null,
	});
};
