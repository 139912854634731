import {apiSlice} from "../apiSlice";

const authApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<{access: string; refresh: string}, {email: string; password: string}>({
			query: ({email, password}) => ({
				url: "users/simple/token/",
				method: "POST",
				body: {email, password},
			}),
		}),
		loadUser: build.query<any, {token: string}>({
			query: ({token}) => ({
				url: `users/info`,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}),
			providesTags: ["user"],
		}),
		getTokenForGoogle: build.mutation<{email: string; access_token: string}, string>({
			query: (token) => ({
				url: "users/token/obtain/",
				method: "POST",

				body: {token},
			}),
		}),
		signUp: build.mutation<
			string,
			{email: string; password: string; firstName: string; lastName: string; mobile: string}
		>({
			query: ({email, password, firstName, lastName, mobile}) => ({
				url: "users/register",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearers",
				},
				body: {email, password, firstName, lastName, mobile},
			}),
		}),
		verifyOTP: build.mutation<string, string>({
			query: (otp) => ({
				url: "users/activateAccount/",
				method: "POST",
				body: {code: otp},
			}),
		}),
		resendOTP: build.mutation<string, void>({
			query: () => ({
				url: "users/resendOtp/",
				method: "POST",
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useLoginMutation,
	useLazyLoadUserQuery,
	useGetTokenForGoogleMutation,
	useSignUpMutation,
	useVerifyOTPMutation,
	useResendOTPMutation,
} = authApi;
