import {
  GET_PRICING,
  GET_PRICING_BY_ID,
  GET_PRICING_ERROR,
  PAY_AMOUNT,
  PAY_AMOUNT_ERROR,
  REMOVE_PAY_AMOUNT,
  FETCH_COUPONINFO,
  FETCH_COUPONINFOFAILED,
} from "../actions/types";

const initialState = {
  plans: null,
  pay: null,
  loading: true,
  coupon: {
    discount: null,
    code: null,
  },
};

function pricing(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PRICING:
      return {
        ...state,
        plans: payload,
        loading: false,
      };
    case GET_PRICING_BY_ID:
      return {
        plan: payload,
      };
    case GET_PRICING_ERROR:
      return {
        ...state,
        plans: null,
        loading: false,
      };
    case PAY_AMOUNT:
      return {
        ...state,
        pay: payload,
        loading: false,
      };
    case REMOVE_PAY_AMOUNT:
      return {
        ...state,
        pay: null,
      };
    case PAY_AMOUNT_ERROR:
      return {
        ...state,
        pay: null,
        loading: false,
      };
    case FETCH_COUPONINFO:
      return {
        ...state,
        coupon: payload,
        couponerr: null,
      };
    case FETCH_COUPONINFOFAILED:
      return {
        ...state,
        couponerr: payload,
        coupon: {
          discount: null,
          code: null,
        },
      };
    default:
      return state;
  }
}

export default pricing;
