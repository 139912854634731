import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
//redux
import { useTypedSelector } from "../../redux/hooks/useTypedSelector";
import { useLazyLoadUserQuery } from "../../redux/features/apis/authApi";
import { logout } from "../../redux/features/slices/authSlice";
import { apiSlice } from "../../redux/features/apiSlice";
import { useDispatch } from "react-redux";
// loader
import RiseLoader from "react-spinners/RiseLoader";
// decoder
import jwt_decode from "jwt-decode";
//
import { setAuth } from "../../redux/features/slices/authSlice";

//iterface
interface decodedToken {
	exp: number;
	user_id: string;
	token_type: string;
	jti: string;
}

const UserRoute = ({ children }: any) => {
	const [ok, setOk] = useState<boolean>(false);
	const { user, token } = useTypedSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [
		loadUser,
		{
			data: userData,
			isLoading: isLoadingUser,
			isSuccess: isSuccessUser,
			isError: isErrorUser,
			error: errorUser,
		},
	] = useLazyLoadUserQuery();
	const handleLogout = () => {
		localStorage.removeItem("auth");
		dispatch(logout());
		dispatch(apiSlice.util.resetApiState());
	};
	useEffect(() => {
		if (token) {
			const decodedToken: decodedToken = jwt_decode(token);
			const currentTime = Date.now() / 1000;
			//  if token is expired
			if (decodedToken.exp < currentTime) {
				handleLogout();
			} else {
				loadUser({ token: token }); // token is valid or not
			}
		}
	}, [user && token]);

	useEffect(() => {
		token == null &&
			setTimeout(() => {
				handleLogout();
			}, 3000);

		if (isErrorUser && errorUser) {
			// console.log("Token error", errorUser);
			handleLogout();
		}

		if (isSuccessUser && userData && !ok) {
			// console.log("Token is valid");
			setOk(true);
		}
	}, [userData, isLoadingUser, isSuccessUser, isErrorUser, errorUser]);

	// usememo for userData
	useMemo(() => {
		if (isSuccessUser && userData && token) {
			// console.log("Token is valid");
			// dispatch user data
			dispatch(setAuth({ user: userData, token: token }));
		}
	}, [userData]);

	return !ok ? (
		<div className="flex min-h-screen items-center justify-center">
			<RiseLoader color="#f89b55" />
		</div>
	) : (
		<>{children}</>
	);
};

export default UserRoute;
