import React from "react";
import { Link } from "react-router-dom";
// import DashBoardSS from "../../../assets/dashboardSS.png";
import DashBoardSS from "../../../assets/dashboard4.png";
import ShortenImg from "../../../assets/landing/hero/shorten.png";
import Click from "../../../assets/landing/hero/click.png";
import UpArrow from "../../../assets/landing/hero/upArrow.png";
import Insta from "../../../assets/socials/insta48.png";
import Linkedin from "../../../assets/socials/linkedin48.png";
import Twitter from "../../../assets/socials/twitter48.png";
import Youtube from "../../../assets/socials/youtube48.png";

import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";

import { height } from "@mui/system";
const Hero = () => {
	const { user, token } = useTypedSelector((state) => state.auth);
	return (
		<>
			<div className="bgSvgHero mx-1 mt-[100px]  flex flex-col items-center justify-center sm:mx-10 sm:mt-[200px] ">
				<main className=" flex flex-col items-center space-y-4">
					<div className="mb-4 text-center text-4xl font-bold text-gray-600 sm:text-7xl">
						Never run out of{" "}
						<span className=" text-[#EE732E] ">Content Ideas</span>
					</div>
					<div className="mb-5 w-full  text-center text-sm text-gray-500 sm:text-xl">
						<span className="block w-full">
							Cruxe is an AI based Content Recommendation System that
							provides you with content ideas,
						</span>
						<span className="block w-full">
							so you can spend less time on research, and more time
							making great content.
						</span>
					</div>
					{token ? (
						<Link
							to="/dash/home"
							className="cursor-pointer rounded-full bg-orangeCrushWarm px-5 py-2 text-2xl font-semibold text-white hover:bg-accentOrangeD"
						>
							Go to Dashboard
						</Link>
					) : (
						<Link
							to="/signup"
							className="cursor-pointer rounded-full bg-orangeCrushWarm px-5 py-2 text-2xl font-semibold text-white hover:bg-accentOrangeD"
						>
							Sign up for free
						</Link>
					)}
					<div className="mb-5 w-3/4 text-center text-sm text-gray-400 sm:text-base">
						<span className="block ">✓ No Credit Card required </span>
					</div>
				</main>
			</div>

			<div className="flex flex-col items-center justify-center sm:mx-10 sm:h-screen lg:-mt-10">
				<main className="sm:5/6  relative mt-[70px] mb-[40px] flex w-4/5 flex-col items-center rounded-3xl shadow sm:my-0 md:w-8/12">
					<img
						src={DashBoardSS}
						alt="dashboard"
						className=" h-full w-full rounded-xl  shadow-xl sm:rounded-3xl"
					/>
					<div className="md:text-normal  absolute top-[-15px] -left-[50px] flex flex-col items-center  justify-center rounded-lg bg-white px-2 py-2 text-sm text-[9px] shadow sm:left-[-100px] sm:flex-row  sm:rounded-2xl sm:px-2 sm:py-4 sm:text-[18px]  ">
						<div className="w-[20px] sm:w-[40px]">
							<img src={ShortenImg} alt="shorten" />
						</div>
						<div className="ml-2 flex flex-col text-center font-semibold text-orangeCrushWarm">
							<span className="block">Shorten your content</span>
							<span className="block"> making journey</span>
						</div>
						<div className="absolute -right-3 -top-5 -z-10  w-[50px] sm:left-1 sm:-top-8 sm:-z-10 sm:w-[170px]">
							<img
								src={UpArrow}
								className="w-full -rotate-[18deg] "
								alt="up arrow"
							/>
						</div>
					</div>
					<div className=" md:text-normal absolute -right-10 bottom-10 w-[130px] flex-col items-center justify-center rounded-lg bg-white px-2 py-2 text-[9px] shadow sm:-right-20 sm:bottom-20 sm:flex  sm:rounded-2xl sm:px-2 sm:py-3 sm:text-[18px] md:w-[190px] lg:w-[250px]">
						<div className="ml-2 text-center font-semibold leading-tight text-orangeCrushWarm">
							Get ideas for all your social media
						</div>
						<div className="flex flex-row justify-between">
							<img
								src={Insta}
								alt="insta"
								className="w-[18px] sm:w-[30px] lg:w-[50px]"
							/>
							<img
								src={Youtube}
								alt="youtube"
								className="w-[18px] sm:w-[30px] lg:w-[50px]"
							/>
							<img
								src={Twitter}
								alt="twitter"
								className="w-[18px] sm:w-[30px] lg:w-[50px]"
							/>
							<img
								src={Linkedin}
								alt="linkedin"
								className="w-[18px] sm:w-[30px] lg:w-[50px]"
							/>
						</div>
					</div>
					<div className=" md:text-normal absolute  -bottom-5 -left-10 flex w-[120px] flex-col items-center justify-center rounded-lg bg-white px-2 py-2 text-[10px] shadow sm:bottom-12 sm:-left-28  sm:flex-row sm:rounded-2xl sm:px-2 sm:py-3 sm:text-[18px] md:w-[200px] lg:w-[250px]">
						<div className="flex w-[30px] flex-row justify-between sm:w-[50px]">
							<img src={Click} alt="click" />
						</div>
						<div className="text-center font-semibold text-orangeCrushWarm sm:ml-2">
							Summary at a click
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default Hero;
