// redux create slice template
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface OtherState {
	api: any;
	twitterSearchQry:string;
	showSidebar:boolean;
}

const initialState: OtherState = {
	api: null,
	twitterSearchQry: "",
	showSidebar: false,
};

const otherSlice = createSlice({
	name: "other",
	initialState,
	reducers: {
		setApi: (state, action: PayloadAction<any>) => {
			state.api = action.payload;
		},
		setTwitterSearchQry: (state, action: PayloadAction<string>) => {
			state.twitterSearchQry = action.payload;
		},
		setSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.showSidebar = action.payload;
		},
	},
});

export const {setApi,setTwitterSearchQry,setSidebarOpen} = otherSlice.actions;

export default otherSlice.reducer;
