import React, { Fragment, useState, useEffect } from "react";
import logo from "../../img/logo.png";
import {
	GoogleLogin,
	GoogleLoginResponse,
	GoogleLoginResponseOffline,
} from "react-google-login";
// import {googleOAuth, redirectLogin} from "../../actions/google";
import { Link, useNavigate } from "react-router-dom";
import InputPassword from "../Forms/InputPassword";
// redux
import { connect, useDispatch, useSelector } from "react-redux";
import {
	useLoginMutation,
	useLazyLoadUserQuery,
	useGetTokenForGoogleMutation,
} from "../../redux/features/apis/authApi";
import { useTypedSelector } from "../../redux/hooks/useTypedSelector";
import { setAuth } from "../../redux/features/slices/authSlice";

import PulseLoader from "react-spinners/PulseLoader";
//third party cookies check
import { useThirdPartyCookieCheck } from "../../hooks/UseThirdPartyCookieCheck";

// modal
import IsCookiesEnabled from "../modal/IsCookiesEnabled";

//reactjs-social-login
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";

const Login1 = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// show modal
	const [show, setShow] = useState(false);

	//third party cookies status
	const status = useThirdPartyCookieCheck();

	const [
		login,
		{
			data: tokens,
			isLoading: isLoadingLogin,
			isSuccess: isSuccessLogin,
			isError: isErrorLogin,
			error: errorLogin,
		},
	] = useLoginMutation();
	const [
		loadUser,
		{
			data: userData,
			isLoading: isLoadingUser,
			isSuccess: isSuccessUser,
			isError: isErrorUser,
			error: errorUser,
		},
	] = useLazyLoadUserQuery();
	const [
		getTokenForGoogle,
		{
			data: tokenForGoogle,
			isLoading: isLoadingToken,
			isSuccess: isSuccessToken,
			isError: isErrorToken,
			error: errorToken,
		},
	] = useGetTokenForGoogleMutation();

	const { user, token } = useTypedSelector((state) => state.auth);

	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	useEffect(() => {
		if (user && user.account_activated) {
			navigate("/dash/home");
		}
		if (user && !user.account_activated) {
			navigate("/signup");
		}
	}, []);

	useEffect(() => {
		if (isSuccessToken && tokenForGoogle) {
			loadUser({ token: tokenForGoogle.access_token });
		}
		if (isSuccessLogin && tokens) {
			loadUser({ token: tokens.access });
		}
		if (isSuccessUser && userData && tokens) {
			dispatch(setAuth({ token: tokens.access, user: userData }));
		}
		if (isSuccessUser && userData && tokenForGoogle) {
			dispatch(
				setAuth({ token: tokenForGoogle.access_token, user: userData })
			);
		}
		if (user && user.account_activated) {
			navigate("/dash/home");
			localStorage.setItem(
				"auth",
				JSON.stringify({ token: token, user: user })
			);
		}
		if (user && !user.account_activated) {
			navigate("/signup");
			localStorage.setItem(
				"auth",
				JSON.stringify({ token: token, user: user })
			);
		}
	}, [
		tokens,
		isSuccessLogin,
		userData,
		isSuccessUser,
		tokens,
		user,
		tokenForGoogle,
		isSuccessToken,
	]);

	const onChange = (e: any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		login(formData);
	};

	//GoogleLoginResponse | GoogleLoginResponseOffline
	const responseGoogle = (response: any) => {
		// console.log(response);
		if (!response.code) {
			console.log("google auth success", response);
			getTokenForGoogle(response.accessToken);
		}
	};
	const responseGoogleOnFail = (response: any) => {
		if (!status) {
			setShow(true);
		}
		// console.log("google auth fail", response);
	};

	const onLoginStart = () => {
		console.log("onLoginStart");
	};
	const REDIRECT_URI = "http://localhost:3000";
	return (
		<div className="mx-auto flex max-w-[500px] flex-col items-center px-3">
			<Link to="/">
				<span className="absolute top-[2%] left-[5%] text-base font-semibold text-gray-300 transition-all  duration-300 hover:text-gray-400">
					{" "}
					Go Back
				</span>
			</Link>
			<div className="mt-10 w-[200px] ">
				<img
					src={logo}
					style={{ width: "17rem", margin: "0 auto" }}
					alt="logo"
				></img>
			</div>
			<div className="mt-10">
				<div className="mx-auto text-center text-[#8D8787] sm:text-lg ">
					Content creation made 10x easier, faster, and simpler
					<br /> No more fear of running out of ideas again!
				</div>
			</div>
			<div className="mt-10 w-full">
				<div
					// onClick={() => {
					// 	if (!status) {
					// 		setShow(true);
					// 	}
					// }}
					className="mx-auto w-full rounded-lg border border-[#F89B55] bg-white py-2 text-center"
				>
					{/* <GoogleLogin
						disabled={!status}
						clientId="190515764585-pree9afrkr29ir6oohjq8uqqpf4p7djp.apps.googleusercontent.com"
						buttonText="Continue with Google"
						onSuccess={responseGoogle}
						onFailure={responseGoogleOnFail}
						cookiePolicy={"single_host_origin"}
						className="googlelogin"
					/> */}
					<LoginSocialGoogle
						client_id={
							"444881216965-tmlqqj220o8bnqulsh8l98a7helckg84.apps.googleusercontent.com"
						}
						scope="openid profile email"
						discoveryDocs="claims_supported"
						onLoginStart={onLoginStart}
						redirect_uri={REDIRECT_URI}
						access_type="offline"
						onResolve={({ provider, data }: any) => {
							// console.log("provider", provider);
							getTokenForGoogle(data?.access_token);
							// console.log("data", data?.access_token);
						}}
						onReject={(err) => {
							console.log(err);
						}}
					>
						<GoogleLoginButton className="flex items-center justify-center text-center shadow-none " />
					</LoginSocialGoogle>
				</div>
			</div>

			<div className="my-4">
				<div style={{ margin: "0 auto", color: "#8D8787" }}>OR</div>
			</div>
			<div className="w-full ">
				<div className="mx-auto w-full ">
					<form onSubmit={onSubmit}>
						<input
							id="email"
							type="email"
							name="email"
							className="formInput"
							placeholder="Email"
							required
							onChange={(e) => onChange(e)}
							style={{
								border: "0.5px solid ",
								width: "100%",
								borderColor: "#939393",
								padding: "1rem ",
							}}
						/>
						<InputPassword
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							onChange={onChange}
						/>
						{(errorLogin as any)?.data?.detail && (
							<div className="mb-2 text-red-600">
								{(errorLogin as any).data.detail}
							</div>
						)}
						<button
							type="submit"
							onClick={onSubmit}
							style={{
								border: "0.5px solid ",
								width: "100%",
								background: "#ee732e",
								padding: "0.9rem ",
								color: "white",
								borderRadius: "5px",
								fontSize: "1.1rem",
							}}
						>
							<div className="flex flex-row items-center justify-center">
								{isLoadingLogin ? (
									<PulseLoader color="white" />
								) : (
									"Log In"
								)}
							</div>
						</button>
					</form>
				</div>
			</div>
			<div className="my-2">
				<Link
					to="/signup"
					style={{
						margin: "0 auto",
						textDecoration: "underline",
						color: "#8D8787",
					}}
				>
					<span className="inline-block">Create an account</span>
				</Link>
			</div>
			<IsCookiesEnabled show={show} setShow={setShow} />
		</div>
	);
};

// const mapStateToProps = (state) => ({
// 	google: state.google,
// 	auth: state.auth,
// });

// export default connect(mapStateToProps, {googleOAuth, login})(Login1);

export default Login1;
