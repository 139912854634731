import React from "react";

function SearchIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				fill="#A6A6A6"
				d="M17.023 14.977c-.468-.449-.924-.91-1.367-1.384-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0014 7c0-3.859-3.14-7-7-7S0 3.141 0 7s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM7 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
			></path>
		</svg>
	);
}

export default SearchIcon;
