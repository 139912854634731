import React, {useState, useEffect} from "react";
import {Route, Navigate, Outlet} from "react-router-dom";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {Alert} from "react-bootstrap";
import Landing from "../landing/Landing";
import {redirectLogin} from "../../actions/google";

const PrivateRoute = ({component: Component, google, auth, ...rest}) => {
	return <>{google.token ? !google.isAuthenticated && !google.loading ? Navigate("/login") : <Outlet /> : <Outlet />}</>;
};

PrivateRoute.propTypes = {
	google: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	google: state.google,
	auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
