import React, {
	Fragment,
	useEffect,
	useState,
	createContext,
	useRef,
} from "react";
//Styles
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Components
import Landing from "./components/landing/Landing";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";

import Pricing from "./components/pricing/Pricing";
import Pricing1 from "./components/pricing/Pricing1";
import Dashboard from "./components/dashboard/Dashboard";
import About from "./components/about/About1";
import PrivacyPolicy from "./components/about/PrivacyPolicy";
import TermsConditions from "./components/about/TermsConditions";
import RefundPolicy from "./components/about/RefundPolicy";
import PrivateRoute from "./components/routing/PrivateRoute";
import Pay from "./components/pricing/Pay";
import ReportBug from "./components/Forms/ReportBug";
import Feedback from "./components/Forms/Feedback";
// Redux
import { useDispatch } from "react-redux";
import { Provider } from "react-redux";
import { googleOAuth, logout } from "./actions/google";
import { setApi } from "./redux/features/slices/otherSlice";
//
import { CANVA_API } from "./actions/types";
import ReactGA from "react-ga";
// circular progracebar
import "react-circular-progressbar/dist/styles.css";

const TRACKING_ID = "UA-241941983-2";
ReactGA.initialize(TRACKING_ID);

export const canvaContext = createContext();

const App = () => {
	const canvaRef = useRef();
	const dispatch = useDispatch();
	const [api, setApi] = useState();

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	// var api;
	useEffect(() => {
		(function (document, url) {
			ReactGA.initialize("UA-213515775-1");
			ReactGA.pageview(window.location.pathname);

			var script = document.createElement("script");
			script.src = url;
			script.onload = async function () {
				// API initialization
				if (window.Canva && window.Canva.DesignButton) {
					const response = await window.Canva.DesignButton.initialize({
						apiKey: "Z3fSTd4Dtk1_amMvnRGf2B-N",
					});
					setApi(response);
					// canvaRef.current = api;
					// dispatch(setApi(canvaRef));
				}
			};
			document.body.appendChild(script);
		})(document, "https://sdk.canva.com/designbutton/v2/api.js");
	}, []);
	return (
		<canvaContext.Provider value={api}>
			<Router>
				<Routes>
					{/* <Route path="/register-user" element={<Login />} /> */}
					{/* <Route exact path='/login1' component={Login1} /> */}
					{/* <Route path="/signup1" element={<Signup />} /> */}
					<Route path="/signup" element={<Signup />} />

					<Route path="/pricing" element={<Pricing1 />} />
					{/* <Route path="/about" element={<About />} /> */}
					{/* <Route exact path="/pricing" component={Pricing1} />
							<Route exact path="/about" component={About} />
							<Route exact path="/refund" component={RefundPolicy} />
							<Route exact path="/terms" component={TermsConditions} />
							<Route exact path="/privacy" component={PrivacyPolicy} />
							<Route exact path="/reportbug" component={ReportBug} />
							<Route exact path="/feedback" component={Feedback} /> */}
					<Route exact path="/dash/*" element={<Dashboard />} />
					{/* <Route exact path="/" element={<Dashboard />} /> */}
					{/* <Route exact path="/redesign" component={SummariseUrlcopy} /> */}
					{/* <PrivateRoute exact path={"/pay/:id"} element={<Pay />} /> */}
					{/* <Route path="/pay/:id" element={<PrivateRoute />}> */}
					{/* <Route path="/pay/:id" element={<Pay />} /> */}
					{/* </Route> */}
					{/* <PrivateRoute path='/dashboard' component={Dashboard} /> */}
					<Route exact path="/login" element={<Login />} />
					<Route exact path="/" element={<Landing />} />
				</Routes>
			</Router>
		</canvaContext.Provider>
	);
};

export default App;
