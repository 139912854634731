import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
	getPricingById,
	payAmount,
	removePayAmount,
} from "../../actions/pricing";
import { redirectLogin } from "../../actions/google";
import auth from "../../reducers/auth";

const PricingItem = ({
	plan,
	getPricingById,
	payAmount,
	removePayAmount,
	pricing,
	pay,
	google,
}) => {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const navigate = useNavigate();

	useEffect(() => {}, [plan, pricing, pricing.pay]);
	const [toggle, setToggle] = useState(false);
	// const [isLargerThan1280] = useMediaQuery("(min-width: 800px)");

	const staticVar = plan && plan;

	let temp;
	const temp1 = () => {
		temp = newScript();
		onScriptLoad();
		removePayAmount();
	};

	console.log(google.isAuthenticated);
	const clickHandler = (id) => {
		if (google.isAuthenticated) {
			payAmount(google.user.access_token, id);
			setToggle(true);
		} else if (auth.user) {
			payAmount(auth.user.access_token, id);
			setToggle(true);
		} else {
			dispatch(redirectLogin(google.isAuthenticated ? false : true));
			navigate("/login");
		}
	};

	function newScript() {
		return new Promise(function (resolve, reject) {
			const script = document.createElement("script");
			script.src =
				"https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/gkMbzJ22836176370882.js";
			script.type = "application/javascript";
			script.crossOrigin = "anonymous";
			script.addEventListener("load", function () {
				onScriptLoad();
				resolve();
			});
			script.addEventListener("error", function (e) {
				reject(e);
			});
			document.body.appendChild(script);
		});
	}

	const [status, setStatus] = useState("start");

	const doLoad = () => {
		temp
			.then(() => {
				setStatus("done");
			})
			.catch(() => {
				setStatus("error");
			});
	};

	function onScriptLoad() {
		var config = {
			root: "",
			flow: "DEFAULT",
			data: {
				orderId:
					pricing.pay &&
					pricing.pay.param_dict.order_id /* update order id */,
				token:
					pricing.pay &&
					pricing.pay.param_dict.params.body
						.txnToken /* update token value */,
				tokenType: "TXN_TOKEN",
				amount: pricing.pay && pricing.pay.param_dict.amount,
				/* update amount */
			},
			handler: {
				notifyMerchant: function (eventName, data) {
					console.log("notifyMerchant handler function called");
					console.log("eventName => ", eventName);
					console.log("data => ", data);
				},
			},
		};

		if (window.Paytm && window.Paytm.CheckoutJS) {
			window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
				// initialze configuration using init method

				window.Paytm.CheckoutJS.init(config)
					.then(function onSuccess() {
						// after successfully update configuration invoke checkoutjs
						window.Paytm.CheckoutJS.invoke();
					})
					.catch(function onError(error) {
						console.log("error => ", error);
					});
			});
		}
	}

	// const handleSuccess = (res) => {
	//   // separate key and values from the res object which is nothing but param_dict
	//   let keyArr = Object.keys(res);
	//   let valArr = Object.values(res);

	//   // when we start the payment verification we will hide our Product form
	//   // document.getElementById("paymentFrm").style.display = "none";

	//   // Lets create a form by DOM manipulation
	//   // display messages as soon as payment starts
	//   let heading1 = document.createElement("h1");
	//   heading1.innerText = "Redirecting you to the paytm....";
	//   let heading2 = document.createElement("h1");
	//   heading2.innerText = "Please do not refresh your page....";

	//   //create a form that will send necessary details to the paytm
	//   let frm = document.createElement("form");
	//   frm.action = "https://securegw-stage.paytm.in/order/process/";
	//   frm.method = "post";
	//   frm.name = "paytmForm";

	//   // we have to pass all the credentials that we've got from param_dict
	//   keyArr.map((k, i) => {
	//     // create an input element
	//     let inp = document.createElement("input");
	//     inp.key = i;
	//     inp.type = "hidden";
	//     // input tag's name should be a key of param_dict
	//     inp.name = k;
	//     // input tag's value should be a value associated with the key that we are passing in inp.name
	//     inp.value = valArr[i];
	//     // append those all input tags in the form tag
	//     frm.appendChild(inp);
	//   });

	//   // append all the above tags into the body tag
	//   document.body.appendChild(heading1);
	//   document.body.appendChild(heading2);
	//   document.body.appendChild(frm);
	//   // finally submit that form
	//   frm.submit();

	//   // if you remember, the param_dict also has "'CALLBACK_URL': 'http://127.0.0.1:8000/api/handlepayment/'"
	//   // so as soon as Paytm gets the payment it will hit that callback URL with some response and
	//   // on the basis of that response we are displaying the "payment successful" or "failed" message
	// };

	const SuccessHandlerToggled = () => {
		if (pricing.pay && toggle) temp1();
	};

	SuccessHandlerToggled();

	const mobStyle = {
		marginBottom: "40px",
	};

	return (
		<>
			{/* {google.user || auth.user ? ( */}
			{/* // (
        // <Redirect to='/login' />
        // ) : */}
			<>
				<div
					onClick={() => {
						clickHandler(plan.id);
					}}
				>
					<div
						className={`pricingcard pricingcard-${staticVar.product.title}`}
						// style={!isLargerThan1280 ? mobStyle : null}
					>
						<h3>{staticVar.product.title}</h3>
						<span className={`price price-${plan.product.title}`}>
							₹{Math.trunc(plan.unit_amount)}
						</span>
						<p className="price-desc">
							{plan.product.title === "Basic" &&
							plan.interval === "month"
								? "Monthly Basic Plan"
								: plan.product.title === "Professional" &&
								  plan.interval === "month"
								? "Professional Monthly Plan"
								: plan.product.title === "Basic" &&
								  plan.interval === "year"
								? "Yearly Basic Plan"
								: plan.product.title === "Professional" &&
								  plan.interval === "year" &&
								  "Professional Yearly Plan"}
						</p>
						{plan.product.title === "Basic" ? (
							<ul className="pricing-listing">
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Summarize by URL
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Summarize by Copy Paste
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Design with Canva
								</li>
							</ul>
						) : (
							<ul className="pricing-listing">
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Summarize by URL
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Summarize by Copy Paste
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Summarize by Document
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									View History and Uploaded documents
								</li>
								<li className="list-style">
									<span
										className={`pricing-icon-${plan.product.title}`}
									>
										<i className="fas fa-check-circle"></i>
									</span>
									Design with Canva
								</li>
							</ul>
						)}
					</div>
					{/* </Link> */}
				</div>
			</>
			{/* // ) : (
      //   <Redirect to='/login' />
      // )} */}
		</>
	);
};

PricingItem.propTypes = {
	google: PropTypes.object.isRequired,
	getPricingById: PropTypes.func.isRequired,
	pricing: PropTypes.object.isRequired,
	payAmount: PropTypes.func.isRequired,
	removePayAmount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	google: state.google,
	pricing: state.pricing,
});

export default connect(mapStateToProps, {
	getPricingById,
	payAmount,
	removePayAmount,
})(PricingItem);
