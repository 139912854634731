export const StandingGirl = () => {
  return (
    <svg
      width="265"
      height="379"
      viewBox="0 0 265 379"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.147 17.0303C146.131 27.199 156.905 51.305 153.899 56.3237L128.368 63.1645C128.354 63.12 128.339 63.0755 128.324 63.0312C118.581 64.5195 109.072 58.4686 106.461 48.7268L101.641 30.7378C98.8403 20.2843 105.044 9.53939 115.497 6.73838L117.258 6.2666C126.634 3.75436 136.244 8.48575 140.147 17.0303Z"
          fill="#051F2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.484 42.7388C110.162 39.1063 105.867 34.0581 104.589 27.4542C100.908 8.42663 128.235 5.50728 135.861 13.5651C143.488 21.6228 149.288 45.6919 143.146 49.143C140.697 50.5193 134.654 50.3746 128.041 48.4964L138.342 72.629L117.214 78.2904L115.484 42.7388Z"
          fill="#C9A485"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.35 38.0535C124.101 48.157 126.882 60.0974 124.734 63.6843L99.2031 70.5252C91.2169 46.4102 107.506 52.2125 101.326 29.1487C101.961 28.089 102.551 27.0505 103.162 26.1272L102.952 25.4566C104.969 15.3016 109.376 9.31351 116.173 7.49242C126.367 4.76078 130.845 8.60026 134.654 11.8579C134.472 19.3744 126.799 22.5379 120.898 28.774C119.885 28.3265 118.716 28.2091 117.559 28.519C114.885 29.2356 113.293 31.9651 114.003 34.6156C114.713 37.2661 117.457 38.8338 120.131 38.1173C120.205 38.0975 120.278 38.0762 120.35 38.0535Z"
          fill="#051F2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.1389 240.925C116.003 247.017 134.867 250.063 150.73 250.063C166.594 250.063 181.329 245.373 194.934 235.991C173.538 229.738 155.918 226.612 142.072 226.612C128.227 226.612 112.249 231.383 94.1389 240.925Z"
          fill="#0C3E5B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.658 258.844C169.447 258.529 169.269 258.185 169.128 257.813C166.205 250.114 119.393 167.057 114.73 151.872H167.433C170.979 163.417 186.939 235.87 189.185 250.326C196.271 273.128 210.862 346.975 212.724 352.091C214.684 357.472 203.659 363.079 200.721 356.463C196.046 345.935 184.908 316.805 179.948 299.019C175.299 282.344 171.513 267.342 169.658 258.844Z"
          fill="#C9A485"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.539 268.985C105.137 274.373 26.8547 284.114 21.4826 285.642C15.9564 287.214 11.111 275.867 17.9417 273.409C28.8105 269.498 58.7589 260.462 76.9168 256.774C91.5219 253.808 104.849 251.388 113.797 249.97C113.039 224.933 109.574 164.849 111.845 151.872H158.478C156.3 164.316 137.889 255.116 134.467 264.205C133.381 267.449 130.141 268.883 126.539 268.985Z"
          fill="#C9A485"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3684 270.307C15.5107 269.775 13.4165 269.627 12.0857 269.861C10.4671 270.147 8.15277 270.833 5.14263 271.92C5.47371 273.797 8.4492 290.672 14.0691 322.544C18.6502 323.093 20.8643 321.92 20.7113 319.024C20.5583 316.129 20.4563 313.719 20.4051 311.795L26.7459 286.415C26.8679 285.927 26.5709 285.432 26.0826 285.31C26.0742 285.308 26.0659 285.306 26.0574 285.304L22.1219 284.439C20.4349 282.031 19.4469 280.006 19.1576 278.366C18.9226 277.033 19.1363 275.098 19.7988 272.559L19.7988 272.559C20.053 271.585 19.4695 270.589 18.4954 270.335C18.4534 270.324 18.411 270.315 18.3684 270.307Z"
          fill="#E4E4E4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.375 355.695C196.356 358.417 195.846 360.453 195.846 361.805C195.846 363.448 196.12 365.847 196.667 369C198.574 369 215.709 369 248.073 369C249.409 364.584 248.638 362.199 245.759 361.847C242.881 361.495 240.49 361.177 238.587 360.893L214.694 350.242C214.234 350.037 213.695 350.243 213.49 350.703C213.487 350.711 213.484 350.719 213.48 350.727L211.944 354.452C209.28 355.696 207.115 356.317 205.449 356.317C204.096 356.317 202.227 355.77 199.842 354.677L199.842 354.677C198.927 354.258 197.845 354.66 197.425 355.575C197.407 355.614 197.391 355.654 197.375 355.695Z"
          fill="#E4E4E4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.996 151.872C132.545 146.015 151.989 146.015 170.326 151.872C176.706 180.868 192.208 192.376 194.934 236.832C163.946 252.17 125.667 225.893 93.7672 240.851C84.6531 219.929 97.4129 169.544 111.996 151.872Z"
          fill="#00A988"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.614 91.8231L188.774 69.3506C192.876 62.3152 197.142 56.6606 201.573 52.3868C202.997 51.4776 205.616 50.6175 203.885 55.4985C202.155 60.3795 200.556 65.5907 202.077 66.7322C203.598 67.8737 206.454 65.4009 208.381 67.4032C209.666 68.7382 204.635 72.0922 193.286 77.4653L174.285 102.082L160.614 91.8231ZM211.934 97.5452L224.299 87.6515C224.937 80.0424 226.588 75.9797 229.25 75.4632C231.134 74.1516 229.182 83.9685 232.93 82.4861C236.679 81.0037 244.932 72.3053 246.819 73.4432C249.61 75.1267 248.595 81.9863 246.622 85.6575C242.933 92.5192 239.362 95.2727 230.115 99.6847C225.281 101.991 220.283 105.788 215.122 111.073L211.934 97.5452Z"
          fill="#C9A485"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.774 90.7163C155.666 88.6775 169.814 83.8183 183.611 71.2733L192.095 83.7293C183.25 96.1941 169.723 107.539 156.49 109.493C146.063 111.033 138.557 99.4782 144.774 90.7163Z"
          fill="#2026A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.294 109.825C175.386 110.657 194.215 103.841 219.781 89.3776C229 102.109 233.878 111.485 234.413 117.504C213.164 133.789 194.07 142.745 177.615 146.016C179.86 155.676 180.303 165.167 177.791 174.353C172.625 193.238 121.535 185.6 105.874 191.638C89.9274 160.602 107.216 143.798 103.528 122.79C99.4895 99.7848 111.808 61.1056 114.539 60.624L139.443 56.2327C143.915 73.5577 154.245 91.6989 163.294 109.825Z"
          fill="#0C3E5B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.284332"
          y="5.59399"
          width="253.139"
          height="372.837"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4.71565" />
          <feGaussianBlur stdDeviation="2.35783" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
