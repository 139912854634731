import React from "react";

const ForCard = ({
	image,
	title,
	description,
}: {
	image: string;
	title: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col items-center tracking-wide  md:items-start">
			<img src={image} alt={title} className="w-[50px]" />
			<div className="mt-2 mb-2 text-xl font-bold text-gray-600 sm:text-2xl">
				{title}
			</div>
			<div className="text-sm font-semibold text-gray-400 sm:text-base">
				{description}
			</div>
		</div>
	);
};

export default ForCard;
