import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getPricing } from "../../actions/pricing";
import Navbar from "../landing/Navbar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import pricing from "../../reducers/pricing";
import PricingItem from "./PricingItem";
import Loader from "../loader/Loader";
import { StandingGirl } from "./standing-girl.js";
import { ArrowFrame } from "./ArrowFrame";
import cruxe from "../../img/blackXchotta.png";
import { Link } from "react-router-dom";

const Pricing = ({ getPricing, google, pricing }) => {
	const [timelyPlan, setTimelyPlan] = useState("month");
	// const [isLargerThan1280] = useMediaQuery("(min-width: 800px)");

	const imgStyle = {
		width: "150px",
		position: "absolute",
		left: "150px",
		top: "30px",
	};

	const imgStyleMob = {
		height: "45px",
		width: "150px",
		marginBottom: "20px",
	};
	useEffect(() => {
		getPricing(timelyPlan);
	}, [getPricing, timelyPlan]);

	return pricing.loading ? (
		<Loader />
	) : (
		<Fragment>
			<div className="pricing">
				<Link to="/">
					<img
						src={cruxe}
						alt=""
						// style={isLargerThan1280 ? imgStyle : imgStyleMob}
					/>
				</Link>
				<span className="pricing-headline">Pricing that works for you</span>
				<span className="pricing-sub-headline">
					catering to 50+ active paid users
				</span>

				<div className="timely-toggle">
					<div className="off-div">
						<div className="off-description">20% off</div>
						<span className="arrow-svg">
							<ArrowFrame />
						</span>
					</div>
					<div
						onClick={() => setTimelyPlan("month")}
						className={`${
							timelyPlan === "month" ? "current-toggle" : ""
						} toggle`}
					>
						Monthly
					</div>
					<div
						onClick={() => setTimelyPlan("year")}
						className={`${
							timelyPlan === "year" ? "current-toggle" : ""
						} toggle`}
					>
						Yearly
					</div>
				</div>
				<Row className="pricingcards">
					<span className="standing-girl">
						<StandingGirl />
					</span>
					{pricing.plans
						? pricing.plans.length > 0
							? pricing.plans.map((plan, index) => {
									return (
										<>
											<PricingItem plan={plan} />
											<div className="give-margin-right"></div>
										</>
									);
							  })
							: "No plans found"
						: ""}
				</Row>
				<div className="space"></div>
				<a href="/refund">
					<p className="refund">Refund Policy</p>
				</a>
			</div>
		</Fragment>
	);
};

Pricing.propTypes = {
	getPricing: PropTypes.func.isRequired,
	google: PropTypes.object.isRequired,
	pricing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	google: state.google,
	pricing: state.pricing,
});

export default connect(mapStateToProps, { getPricing })(Pricing);
