import { useRef, useEffect, useMemo, useState, useCallback } from "react";

// imported components
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark, FaExternalLinkAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import RiseLoader from "react-spinners/RiseLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import WordLimitOver from "../../../modal/WordLimitOver";

// redux
import {
	useLazyFetchTrendingBlogsQuery,
	useSavePostMutation,
	useFetchSavedPostsQuery,
	useUnsavePostMutation,
} from "../../../../redux/features/apis/contentApi";
import { useTypedSelector } from "../../../../redux/hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import BlogSummary from "./comp/BlogSummary";

import Filter from "./comp/filter";

// utils
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";
import TrendingArticles from "./comp/TrendingArticles";

function Trending({ isSaved }: { isSaved: boolean }) {
	const pageOptions = useTypedSelector((state) => state.content.trendingBlogs);
	const [isflexrow, setIsflexrow] = useState<boolean>(false);
	const [isLimitOver, setIsLimitOver] = useState<boolean>(false);

	const [fetchBlogs, { data, isSuccess, isFetching }] =
		useLazyFetchTrendingBlogsQuery();
	const [
		savePost,
		{ data: saveResponse, isLoading: isLoadingSave, isError: isErrorSave },
	] = useSavePostMutation();
	const [
		unsavePost,
		{
			data: unsaveResponse,
			isLoading: isLoadingUnsave,
			isError: isErrorUnsave,
		},
	] = useUnsavePostMutation();

	const {
		data: savedPosts,
		isLoading: isLoadingSaved,
		isError: isErrorSaved,
	} = useFetchSavedPostsQuery(undefined, { skip: !isSaved });

	useEffect(() => {
		fetchBlogs({
			pageNumber: pageOptions.pageNumber,
			filter: pageOptions.filter,
			value: pageOptions.value,
		});
	}, [pageOptions.pageNumber, pageOptions.filter, pageOptions.value]);

	const lastPostObserver = useIntersectionObserver({
		type: "trendingBlogs",
		isSuccess,
		isFetching,
		hasMore: data?.hasMore,
	});

	return (
		<div className="z-[0.1]">
			{/*<Filter type="trendingBlogs" options={["date"]} />*/}

			<div className="flex flex-col items-center overflow-x-hidden pt-14 lg:pt-3">
				{((isSaved && savedPosts?.trending_blogs) || data?.trendingBlogs) &&
					Array.from(
						isSaved ? savedPosts?.trending_blogs : data?.trendingBlogs
					).map((blog: any, i) => (
						<TrendingArticles
							key={i}
							blog={blog}
							data={data}
							i={i}
							lastPostObserver={lastPostObserver}
							isSaved={isSaved}
							setIsLimitOver={setIsLimitOver}
							savePost={savePost}
							unsavePost={unsavePost}
						/>
					))}
			</div>

			{isSaved && savedPosts?.trending_blogs.length === 0 && (
				<div className="mx-auto text-center text-xl text-black/75">
					Nothing here!
				</div>
			)}

			{!isSaved && (
				<div className="mb-4 flex justify-center p-4">
					{isFetching && (
						<div className="">
							<RiseLoader color="#f89b55" />
						</div>
					)}
				</div>
			)}

			{!isSaved && data && !data?.hasMore && (
				<div className="mb-10 flex flex-row justify-center">
					<div className="flex  w-[70%] cursor-pointer flex-col items-center justify-center rounded-xl bg-accentOrange py-5 text-white shadow-md hover:scale-105">
						<h4>Upgrade to premium to unlock </h4>
						<FaLock size={40} />
						<div></div>
					</div>
				</div>
			)}
			{/* 
			<div className="border-accentOrange bottom-10 right-10 hover:bg-accentOrange/25 active:bg-accentOrange active:text-white fixed flex flex-col items-center justify-center px-2 py-2 text-black transition-colors border-2 rounded-full cursor-pointer select-none">
				Trending
			</div> */}
			<WordLimitOver
				isLimitOver={isLimitOver}
				setIsLimitOver={setIsLimitOver}
			/>
		</div>
	);
}

export default Trending;
