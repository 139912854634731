import React, { useEffect, useState } from "react";

const Quotes = () => {
  var quotes = [
    "The Firefox logo isn’t a fox… it’s a red panda!",
    " On average, people read 10% slower from a screen than from paper.",
    "NASA’s internet speed is 91 GB per second.",
    "Until 2010, carrier pigeons were faster than the internet.",
    "QWERTY keyboard was designed to slow down the typing speed",
    "Wikipedia is maintained by thousands of bots",
    "Apple once forayed into the apparel business",
    "Amazon Alexa stores your conversations with the device",
    "Motorola produced the first handheld mobile phone",
    "Nokia used to sell toilet paper.",
    "Android holds 87% of the OS market share.",
    "35% of the internet uses WordPress.",
    "Mark Zuckerberg is red-green colourblind",
    "Facebook will pay $500 if you find a bug in their code",
    "Twitter, Wikipedia, and AOL IM all crashed at 3:15pm when Michael Jackson died.",
    "YouTube was created to be a dating site.",
    "Robots can have citizenship in Saudi Arabia.",
    "More women use Instagram than men.",
    "About 995 photos are uploaded to Instagram every second.",
    "There are 57 years worth of content on Youtube.",
    "The original name of Google was Backrub.",
    "The Google search technology is called PageRank.",
    "Google wanted in 1997 to sell their search engine system to Yahoo for $ 2 million.",
    "33% of all searches on Google come from smartphone.",
    "Elon Musk is reported to sleep at the Telsa factory.",
    "Elon Musk’s annual salary at Tesla is $1.",
    "Facebook Is the Third Most Popular Site in the World.",
    "People Are Most Active on Facebook at 8 a.m. & 10 p.m.",
  ];
  const [random, setrandom] = useState(
    "Note: We recommend summarizing an article in 100 words for best results"
  );
  useEffect((random) => {
    const interval = setInterval(() => {
      setrandom(quotes[Math.floor(Math.random() * quotes.length)]);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  //   console.log(random);
  return (
    <>
      <div>
        {/* <h2 style={{ fontWeight: "800", fontSize: "1.3rem" }}>Did you know?</h2> */}
        {random}
      </div>
    </>
  );
};

export default Quotes;
