import React from "react";
import {VscTriangleUp} from "react-icons/vsc";

import {IoIosArrowDown} from "react-icons/io";
import {Tags} from "../TagsModal";

const ContentCard = ({open}: {open: boolean}) => {
	return (
		<div className="w-full border-b border-orange-600">
			<div className=" hover:bg-accentOrange/10 flex flex-row items-center justify-between px-10 py-10 cursor-pointer">
				<div className="flex flex-row items-center">
					<div className="rounded-full  min-w-[40px] min-h-[40px]  max-w-[40px] max-h-[40px] border-2  p-3 text-xl font-semibold text-center border-accentOrange text-accentOrangeD bg-accentOrange/30 mr-4 flex justify-center items-center">
						1
					</div>
					<h2 className="text-xl font-semibold">Start the reel series</h2>
				</div>

				<div className={`transition-transform-2ms flex justify-items-end ${open ? "rotate-180" : "rotate-0"}`}>
					{/* <VscTriangleUp /> */}
					<IoIosArrowDown size={30} color="#F89B55" />
				</div>
			</div>
			{open && (
				<div className="px-10 py-10">
					{/* a list of items  */}
					<ul>
						<li>This is some text</li>
						<li>This is some text</li>
						<li>This is some text</li>
						<li>This is some text</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default ContentCard;
