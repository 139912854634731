import { useEffect } from "react";
import LazyLoad from "react-lazyload";
import { Tweet } from "react-twitter-widgets";

// imported components
import RiseLoader from "react-spinners/RiseLoader";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";

import { FaLock } from "react-icons/fa";

// redux
import {
	useLazyFetchTweetsQuery,
	useSavePostMutation,
	useFetchSavedPostsQuery,
	useUnsavePostMutation,
} from "../../../../redux/features/apis/contentApi";
import { useTypedSelector } from "../../../../redux/hooks/useTypedSelector";
import { useDispatch } from "react-redux";

import Filter from "./comp/filter";

// utils
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";

function Twitter({ isSaved }: { isSaved: boolean }) {
	const pageOptions = useTypedSelector((state) => state.content.twitter);

	const [fetchTweets, { data, isSuccess, isFetching }] =
		useLazyFetchTweetsQuery();
	const [
		savePost,
		{ data: saveResponse, isLoading: isLoadingSave, isError: isErrorSave },
	] = useSavePostMutation();
	const {
		data: savedPosts,
		isLoading: isLoadingSaved,
		isError: isErrorSaved,
	} = useFetchSavedPostsQuery(undefined, { skip: !isSaved });
	const [
		unsavePost,
		{
			data: unsaveResponse,
			isLoading: isLoadingUnsave,
			isError: isErrorUnsave,
		},
	] = useUnsavePostMutation();

	useEffect(() => {
		fetchTweets({
			pageNumber: pageOptions.pageNumber,
			filter: pageOptions.filter,
			value: pageOptions.value,
		});
	}, [pageOptions.pageNumber, pageOptions.filter, pageOptions.value]);

	const lastPostObserver = useIntersectionObserver({
		type: "twitter",
		isSuccess,
		isFetching,
		hasMore: data?.hasMore,
	});
	// console.log("data twitter", data);
	return (
		<div className="">
			{/*<Filter type="twitter" options={["relevance", "category", "date"]} />*/}

			<div
				className="mx-auto mt-10 max-w-[500px] lg:max-w-[1140px] lg:columns-2 "
				id="boring"
			>
				{((isSaved && savedPosts?.tweets) || data?.tweets) &&
					Array.from(isSaved ? savedPosts?.tweets : data?.tweets).map(
						(tweet: any, idx) => (
							<div
								className="mx-auto mb-0 flex flex-col items-center p-2  sm:mb-4"
								key={idx}
							>
								{data?.tweets.length === idx + 3 && !isSaved && (
									<div
										ref={lastPostObserver}
										className="absolute"
									></div>
								)}
								<div className=" w-full sm:p-0 " id={tweet.tweet_id}>
									<LazyLoad height={400} once offset={0}>
										<div className="min-h-[400px] ">
											<Tweet
												tweetId={tweet.post_url.split("/")[5]}
											/>
										</div>
									</LazyLoad>
								</div>

								<div className="flex w-full flex-col items-center ">
									<div className="w-full text-center  font-semibold ">
										{tweet.saved || isSaved ? (
											<div
												onClick={() =>
													unsavePost({
														post_id: tweet.id,
														type_of_post: "twitter",
													})
												}
												className=" cursor-pointer rounded-md border-[1px] border-[#EE732E] bg-[#EE732E] p-2 text-white"
											>
												Saved
											</div>
										) : (
											<div
												onClick={() =>
													savePost({
														post_id: tweet.id,
														type_of_post: "twitter",
													})
												}
												className=" cursor-pointer rounded-md border-[1px] border-[#EE732E] p-2 text-[#EE732E] hover:bg-[#FDF0E7]"
											>
												Save
											</div>
										)}
									</div>
								</div>
							</div>
						)
					)}
			</div>

			{isSaved && savedPosts?.tweets.length === 0 && (
				<div className="mx-auto text-center text-xl text-black/75">
					Nothing here!
				</div>
			)}

			{!isSaved && (
				<div className="mb-4 flex justify-center p-4">
					{isFetching && (
						<div className="">
							<RiseLoader color="#f89b55" />
						</div>
					)}
				</div>
			)}
			{!isSaved && data && !data?.hasMore && (
				<div className="mb-10 flex flex-row justify-center">
					<div className="flex  w-[70%] cursor-pointer flex-col items-center justify-center rounded-xl bg-orangeCrushWarm py-5 text-white shadow-md hover:scale-105">
						<h4>Upgrade to premium to unlock </h4>
						<FaLock size={40} />
						<div></div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Twitter;
