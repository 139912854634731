import React from "react";
import ForCard from "../comp/ForCard";
import Organizations from "../../../assets/landing/cruxeIsFor/organizations.png";
import Teams from "../../../assets/landing/cruxeIsFor/teams.png";
import Researchers from "../../../assets/landing/cruxeIsFor/researchers.png";
import Seo from "../../../assets/landing/cruxeIsFor/seo.png";
import Students from "../../../assets/landing/cruxeIsFor/students.png";
import Innovators from "../../../assets/landing/cruxeIsFor/innovators.png";

const CruxeIsFor = () => {
	return (
		// <div className=" bg-gradient-to-b from-[#fef2f2] to-white px-3 py-[40px] sm:px-[170px] sm:py-[100px]">
		<div className=" sm:px[40px] mt-[40px] bg-white px-8 pb-[140px] pt-[80px] md:px-[70px] lg:px-[100px]   ">
			<div className="mb-7 text-center text-4xl font-semibold text-gray-600 sm:mb-16 md:text-6xl">
				<span className="text-[#EE732E]">Cruxe</span> is for
			</div>
			<div className="grid grid-cols-2 gap-5  sm:gap-10 md:grid-cols-3 ">
				<ForCard
					image={Organizations}
					title="Organizations"
					description="Decipher and interpret data by
                                summarizing widespread content
                                which can be directly exported and
                                designed with Canva.
                                "
				/>
				<ForCard
					image={Teams}
					title="Content Teams"
					description="Enhance your workflow with easy
                    readability and enhanced
                    productivity for optimized content
                    coordination.
                    
                                "
				/>
				<ForCard
					image={Researchers}
					title="Reasearchers"
					description="Research is tough, curating content
                    should not be. Boost your efficiency
                    by summarizing lengthy research
                    based articles and papers
                    
                                "
				/>
				<ForCard
					image={Seo}
					title="SEO folks"
					description="Good content means Higher clicks.
                    Escalate your website visibility with
                    high-quality content and well-
                    formulated information.
                                "
				/>
				<ForCard
					image={Students}
					title="Students"
					description="Too much to read but don't have the
                    time? Crunch your study material
                    into easily readable and
                    understandable content for better
                    scores and results.
                    
                                "
				/>
				<ForCard
					image={Innovators}
					title="Innovators"
					description="Your one-stop destination for
                    handling social media and content
                    curation for your next YouTube
                    channel, Instagram page.
                    
                                "
				/>
			</div>
		</div>
	);
};

export default CruxeIsFor;
