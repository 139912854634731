import React, { useEffect, useState } from "react";
import { useDebounce } from "../../function";
import RiseLoader from "react-spinners/RiseLoader";

import Tweets from "../components/other/Tweets";
import { useLazyGetAllTweetsQuery } from "../../../redux/features/apis/otherApi";
import { useTypedSelector } from "../../../redux/hooks/useTypedSelector";

const TwitterPage = () => {
	const TwitterSearchQry = useTypedSelector(
		(state) => state.other.twitterSearchQry
	);
	const [getTweets, { data, isLoading, isSuccess, isError }] =
		useLazyGetAllTweetsQuery();

	const query = useDebounce<string>(TwitterSearchQry, 500);

	useEffect(() => {
		if (!query) {
			getTweets({ query: "startup", exact_search: true });
		} else {
			getTweets({ query, exact_search: true });
		}
	}, [query]);

	// console.log("tweets page ", isLoading, isSuccess, isError, data);

	return (
		<div className="mt-6 sm:mt-10">
			<div className="mx-auto flex min-h-screen w-fit justify-center rounded-xl  lg:bg-white lg:p-8 ">
				{isLoading ? (
					<div className="flex items-center justify-center">
						<RiseLoader color="#f89b55" />
					</div>
				) : isError ? (
					`Something went wrong.
						 Refresh the page`
				) : (
					<Tweets data={data} />
				)}
			</div>
		</div>
	);
};

export default TwitterPage;
