import { useRef, useEffect, useMemo, useState, useCallback } from "react";

// imported components
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark, FaExternalLinkAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import RiseLoader from "react-spinners/RiseLoader";
import PropagateLoader from "react-spinners/PropagateLoader";

// redux
import {
	useLazyFetchBlogsQuery,
	useSavePostMutation,
	useFetchSavedPostsQuery,
	useUnsavePostMutation,
} from "../../../../redux/features/apis/contentApi";
import { useTypedSelector } from "../../../../redux/hooks/useTypedSelector";
import BlogSummary from "./comp/BlogSummary";

import BlogArticales from "./comp/BlogArticales";

import Filter from "./comp/filter";
import useIntersectionObserver from "../../../../utils/useIntersectionObserver";
import WordLimitOver from "../../../modal/WordLimitOver";

function Blogs({ isSaved }: { isSaved: boolean }) {
	const pageOptions = useTypedSelector((state) => state.content.blogs);

	const [fetchBlogs, { data, isSuccess, isFetching }] =
		useLazyFetchBlogsQuery();
	const [savePost] = useSavePostMutation();
	const [unsavePost] = useUnsavePostMutation();

	const [isLimitOver, setIsLimitOver] = useState<boolean>(false);
	const [isflexrow, setIsflexrow] = useState<boolean>(true);

	const {
		data: savedPosts,
		isLoading: isLoadingSaved,
		isError: isErrorSaved,
	} = useFetchSavedPostsQuery(undefined, { skip: !isSaved });

	useEffect(() => {
		if (!isSaved) {
			fetchBlogs({
				pageNumber: pageOptions.pageNumber,
				filter: pageOptions.filter,
				value: pageOptions.value,
			});
		}
	}, [pageOptions.pageNumber, pageOptions.filter, pageOptions.value]);

	const lastPostObserver = useIntersectionObserver({
		type: "blogs",
		isSuccess,
		isFetching,
		hasMore: data?.hasMore,
	});

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLimitOver(true);
	// 	}, 2000);
	// }, []);

	return (
		<div>
			{/*<Filter type="blogs" options={["relevance", "category"]} />*/}
			<div className="flex flex-col items-center overflow-x-hidden pt-14 lg:pt-3">
				{((isSaved && savedPosts?.blogs) || data?.blogs) &&
					Array.from(isSaved ? savedPosts?.blogs : data?.blogs).map(
						(blog: any, i) => (
							<BlogArticales
								key={i}
								blog={blog}
								data={data}
								i={i}
								lastPostObserver={lastPostObserver}
								isSaved={isSaved}
								setIsLimitOver={setIsLimitOver}
								savePost={savePost}
								unsavePost={unsavePost}
							/>

							// 			<article
							// 				key={i}
							// 				className="flex-column mx-2 mb-2 flex w-full justify-between rounded-xl border border-black/25    sm:w-[90%] lg:w-[70%] "
							// 			>
							// 				{data?.blogs.length === i + 3 && !isSaved && (
							// 					<div
							// 						ref={lastPostObserver}
							// 						className="absolute"
							// 					></div>
							// 				)}
							// 				<div className="flex flex-row">
							// 					<div>
							// 						<img
							// 							src={blog.media_url_photo}
							// 							alt="blog"
							// 							className=" mr-2 h-[150px] min-w-[150px] max-w-[150px] rounded-lg object-cover sm:mr-10 sm:h-[200px] sm:min-w-[200px] sm:max-w-[200px]"
							// 						/>
							// 					</div>
							// 					<div className="flex flex-col justify-between">
							// 						<div>
							// 							<div className="mb-2 flex flex-row items-end">
							// 								<img
							// 									src={blog.website_logo_url}
							// 									alt="website logo"
							// 									className="mr-3 max-h-[15px]  sm:max-h-[20px]"
							// 								/>

							// 								<p className="m-0 text-[10px] font-semibold sm:text-sm">
							// 									{blog.website_name}
							// 								</p>
							// 							</div>
							// 							<h1 className="text-sm font-bold sm:text-xl">
							// 								{blog.title}
							// 							</h1>
							// 							<div className="hidden pt-4 sm:flex">
							// 								<BlogSummary
							// 									id={blog.id}
							// 									setIsLimitOver={setIsLimitOver}
							// 								/>
							// 							</div>
							// 						</div>
							// 						<div className="hidden flex-row items-center space-x-4 sm:flex lg:space-x-12">
							// 							{blog.saved || isSaved ? (
							// 								<div className="">
							// 									<span
							// 										className="j_bookmark cursor-pointer rounded-md  border-orangeCrushWarm bg-orangeCrushWarm py-1 px-4 text-white"
							// 										onClick={() =>
							// 											unsavePost({
							// 												post_id: blog.id,
							// 												type_of_post: "blog",
							// 											})
							// 										}
							// 									>
							// 										Saved
							// 									</span>
							// 								</div>
							// 							) : (
							// 								<>
							// 									<div className="relative flex items-center justify-center ">
							// 										<span
							// 											// size="23px"
							// 											className="j_bookmark cursor-pointer rounded-md border-[1px] border-orangeCrushWarm px-6 py-1 text-orangeCrushWarm hover:bg-[#FDF0E7]"
							// 											onClick={() =>
							// 												savePost({
							// 													post_id: blog.id,
							// 													type_of_post: "blog",
							// 												})
							// 											}
							// 										>
							// 											Save
							// 										</span>
							// 										{/* <BeatLoader color="#f89b55" size="10px" /> */}
							// </div>
							// 								</>
							// 							)}
							// 							<a
							// 								href={blog.post_url}
							// 								target="_blank"
							// 								rel="noreferrer"
							// 								className=" bottom-b-1 cursor-pointer border-[#472208]  text-[#472208] "
							// 							>
							// 								<span className=" text-base ">visit</span>
							// 							</a>
							// 						</div>
							// 						{/* <BlogSummary id={blog.id} /> */}
							// </div>
							// 				</div>
							// 				<div className=" flex flex-col  pt-2 sm:hidden  sm:py-0 sm:pt-2">
							// 					<div className="">
							// 						<BlogSummary
							// 							id={blog.id}
							// 							setIsLimitOver={setIsLimitOver}
							// 						/>
							// 					</div>
							// 					<div className="flex flex-row items-center space-x-2">
							// 						<a
							// 							href={blog.post_url}
							// 							target="_blank"
							// 							rel="noreferrer"
							// 							className=" bottom-b-1 cursor-pointer border-[#472208]  text-[#472208] "
							// 						>
							// 							<span className=" text-base ">visit</span>
							// 						</a>
							// 						{blog.saved || isSaved ? (
							// 							<div className="">
							// 								<span
							// 									className="j_bookmark cursor-pointer rounded-md border-orangeCrushWarm bg-orangeCrushWarm py-1 px-4 text-sm text-white"
							// 									onClick={() =>
							// 										unsavePost({
							// 											post_id: blog.id,
							// 											type_of_post: "blog",
							// 										})
							// 									}
							// 								>
							// 									Saved
							// 								</span>
							// 							</div>
							// 						) : (
							// 							<>
							// 								<div className="relative flex items-center justify-center ">
							// 									<span
							// 										// size="23px"
							// 										className="j_bookmark cursor-pointer rounded-md border-[1px] border-orangeCrushWarm px-6 py-1 text-sm text-orangeCrushWarm hover:bg-[#FDF0E7]"
							// 										onClick={() =>
							// 											savePost({
							// 												post_id: blog.id,
							// 												type_of_post: "blog",
							// 											})
							// 										}
							// 									>
							// 										Save
							// 									</span>
							// 									{/* <BeatLoader color="#f89b55" size="10px" /> */}
							// </div>
							// 							</>
							// 						)}
							// 					</div>
							// 				</div>
							// 			</article>
						)
					)}
			</div>

			{isSaved && savedPosts?.blogs.length === 0 && (
				<div className="mx-auto text-center text-xl text-black/75">
					Nothing here!
				</div>
			)}

			{!isSaved && (
				<div className="mb-4 flex justify-center p-4">
					{isFetching && (
						<div className="">
							<RiseLoader color="#f89b55" />
						</div>
					)}
				</div>
			)}

			{!isSaved && data && !data?.hasMore && (
				<div className="mb-10 flex flex-row justify-center">
					<div className="flex  w-[70%] cursor-pointer flex-col items-center justify-center rounded-xl bg-orangeCrushWarm py-5 text-white shadow-md hover:scale-105">
						<h4>Upgrade to premium to unlock </h4>
						<FaLock size={40} />
						<div></div>
					</div>
				</div>
			)}
			{/* 
			<div className="border-accentOrange bottom-10 right-10 hover:bg-accentOrange/25 active:bg-accentOrange active:text-white fixed flex flex-col items-center justify-center px-2 py-2 text-black transition-colors border-2 rounded-full cursor-pointer select-none">
				Trending
			</div> */}
			<WordLimitOver
				isLimitOver={isLimitOver}
				setIsLimitOver={setIsLimitOver}
			/>
		</div>
	);
}

export default Blogs;
