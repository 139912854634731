import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./../../store";
import { apiSlice } from "../apiSlice";
import axios from "axios";
import { pageOptions } from "../slices/contentSlice";

const authApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		// login: build.mutation<{access: string; refresh: string}, {email: string; password: string}>({
		// 	query: ({email, password}) => ({
		// 		url: "users/simple/token/",
		// 		method: "POST",
		// 		body: {email, password},
		// 	}),
		// }),

		fetchTrendingBlogs: build.query<any, pageOptions>({
			queryFn: async ({ pageNumber, filter, value }, { getState }) => {
				let state = getState() as RootState;
				const { token } = (getState() as RootState).auth;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				let trendingBlogs = [];
				if (pageNumber > 1) {
					const { data }: any =
						authApi.endpoints.fetchTrendingBlogs.select({
							pageNumber: pageNumber - 1,
							filter,
							value,
						})(state as any);
					if (data?.trendingBlogs) {
						trendingBlogs.push(...data.trendingBlogs);
					}
				}

				const newTrendingBlogs = await axios.get(
					`https://apicruxe.xyz/api/content/blogstrending/${pageNumber}/?filter=${filter}&value=${value}`,
					config
				);
				if (newTrendingBlogs.data.length > 0) {
					trendingBlogs.push(...newTrendingBlogs.data);
				}
				return {
					data: {
						trendingBlogs,
						hasMore: newTrendingBlogs.data.length > 0,
						pageNumber,
					},
				};
			},
			providesTags: ["trendingBlogs"],
		}),
		fetchBlogs: build.query<any, pageOptions>({
			queryFn: async ({ pageNumber, filter, value }, { getState }) => {
				let state = getState() as RootState;
				const { token } = (getState() as RootState).auth;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				let blogs = [];
				if (pageNumber > 1) {
					const { data }: any = authApi.endpoints.fetchBlogs.select({
						pageNumber: pageNumber - 1,
						filter,
						value,
					})(state as any);
					if (data?.blogs) {
						blogs.push(...data.blogs);
					}
				}

				const newBlogs = await axios.get(
					`https://apicruxe.xyz/api/content/blogs/${pageNumber}/?filter=${filter}&value=${value}`,
					config
				);
				if (Array.isArray(newBlogs.data)) {
					blogs.push(...newBlogs.data);
				}
				return {
					data: {
						blogs,
						hasMore: Array.isArray(newBlogs.data),
						pageNumber,
					},
				};
			},
			providesTags: ["blogs"],
		}),
		fetchTweets: build.query<any, pageOptions>({
			queryFn: async ({ pageNumber, filter, value }, { getState }) => {
				let state = getState() as RootState;
				const { token } = (getState() as RootState).auth;

				const config = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				let tweets = [];
				if (pageNumber > 1) {
					const { data }: any = authApi.endpoints.fetchTweets.select({
						pageNumber: pageNumber - 1,
						filter,
						value,
					})(state as any);
					if (data?.tweets) {
						tweets.push(...data.tweets);
					}
				}

				const newTweets = await axios.get(
					`https://apicruxe.xyz/api/content/twitter/${pageNumber}/?filter=${filter}&value=${value}`,
					config
				);
				if (Array.isArray(newTweets.data)) {
					tweets.push(...newTweets.data);
				}
				return {
					data: {
						tweets,
						hasMore: Array.isArray(newTweets.data),
						pageNumber,
					},
				};
			},
			providesTags: ["tweets"],
		}),
		fetchYoutube: build.query<any, pageOptions>({
			queryFn: async ({ pageNumber, filter, value }, { getState }) => {
				let state = getState() as RootState;
				const { token } = (getState() as RootState).auth;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				let youtubeVideos = [];
				if (pageNumber > 1) {
					const { data }: any = authApi.endpoints.fetchYoutube.select({
						pageNumber: pageNumber - 1,
						filter,
						value,
					})(state as any);
					if (data?.youtubeVideos) {
						youtubeVideos.push(...data.youtubeVideos);
					}
				}

				const newYoutubeVideos = await axios.get(
					`https://apicruxe.xyz/api/content/youtube/${pageNumber}/?filter=${filter}&value=${value}`,
					config
				);
				if (Array.isArray(newYoutubeVideos.data)) {
					youtubeVideos.push(...newYoutubeVideos.data);
				}
				return {
					data: {
						youtubeVideos,
						hasMore: Array.isArray(newYoutubeVideos.data),
						pageNumber,
					},
				};
			},
			providesTags: ["youtube"],
		}),
		fetchReddit: build.query<any, pageOptions>({
			queryFn: async ({ pageNumber, filter, value }, { getState }) => {
				let state = getState() as RootState;
				const { token } = (getState() as RootState).auth;
				const config = {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				let redditPosts = [];
				if (pageNumber > 1) {
					const { data }: any = authApi.endpoints.fetchReddit.select({
						pageNumber: pageNumber - 1,
						filter,
						value,
					})(state as any);
					if (data?.redditPosts) {
						redditPosts.push(...data.redditPosts);
					}
				}

				const newRedditPosts = await axios.get(
					`https://apicruxe.xyz/api/content/reddit/${pageNumber}/?filter=${filter}&value=${value}`,
					config
				);
				if (Array.isArray(newRedditPosts.data)) {
					redditPosts.push(...newRedditPosts.data);
				}
				return {
					data: {
						redditPosts,
						hasMore: Array.isArray(newRedditPosts.data),
						pageNumber,
					},
				};
			},
			providesTags: ["reddit"],
		}),

		fetchCreators: build.query<any, void>({
			query: () => ({
				url: `content/creators-to-follow`,
				method: "GET",
			}),
			providesTags: ["creators"],
		}),

		updateTags: build.mutation<string, string[]>({
			query: (tags) => ({
				url: "content/updateTags/",
				method: "POST",
				body: { tags },
			}),
			invalidatesTags: [
				"trendingBlogs",
				"blogs",
				"tweets",
				"youtube",
				"reddit",
				"creators",
				"tag",
			],
		}),

		getTags: build.query<any, void>({
			query: () => ({
				url: `content/updateTags/`,
				method: "GET",
			}),
			providesTags: ["tag"],
		}),
		fetchSavedPosts: build.query<any, void>({
			query: () => ({
				url: `content/savePost/`,
				method: "GET",
			}),
		}),

		savePost: build.mutation<
			string,
			{ post_id: number; type_of_post: string }
		>({
			query: ({ post_id, type_of_post }) => ({
				url: "content/savePost/",
				method: "POST",
				body: { post_id, type_of_post },
			}),
			async onQueryStarted(
				{ post_id, type_of_post },
				{ dispatch, queryFulfilled, getState }
			) {
				// console.log("onQueryStarted");
				const content = (getState() as RootState).content;

				let pageOptions: pageOptions = { pageNumber: 1 };
				type PostType =
					| "fetchTrendingBlogs"
					| "fetchBlogs"
					| "fetchTweets"
					| "fetchYoutube"
					| "fetchReddit";

				let fetchQuery: PostType = "fetchBlogs";
				if (type_of_post === "blog") {
					pageOptions = content.blogs;
					fetchQuery = "fetchBlogs";
				} else if (type_of_post === "twitter") {
					pageOptions = content.twitter;
					fetchQuery = "fetchTweets";
				} else if (type_of_post === "youtube") {
					pageOptions = content.youtube;
					fetchQuery = "fetchYoutube";
				} else if (type_of_post === "reddit") {
					pageOptions = content.reddit;
					fetchQuery = "fetchReddit";
				} else if (type_of_post === "blog_trending") {
					pageOptions = content.trendingBlogs;
					fetchQuery = "fetchTrendingBlogs";
				}

				const patchResult = dispatch(
					authApi.util.updateQueryData(
						fetchQuery,
						pageOptions,
						(draft) => {
							if (type_of_post === "blog") {
								const index = draft.blogs.findIndex(
									(blog: any) => blog.id === post_id
								);
								if (index > -1) {
									draft.blogs[index].saved = true;
								}
							} else if (type_of_post === "twitter") {
								const index = draft.tweets.findIndex(
									(tweet: any) => tweet.id === post_id
								);
								if (index > -1) {
									draft.tweets[index].saved = true;
								}
							} else if (type_of_post === "youtube") {
								const index = draft.youtubeVideos.findIndex(
									(video: any) => video.id === post_id
								);
								if (index > -1) {
									draft.youtubeVideos[index].saved = true;
								}
							} else if (type_of_post === "reddit") {
								const index = draft.redditPosts.findIndex(
									(post: any) => post.id === post_id
								);
								if (index > -1) {
									draft.redditPosts[index].saved = true;
								}
							} else if (type_of_post === "blog_trending") {
								const index = draft.trendingBlogs.findIndex(
									(post: any) => post.id === post_id
								);
								if (index > -1) {
									draft.trendingBlogs[index].saved = true;
								}
							}
						}
					)
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		unsavePost: build.mutation<
			string,
			{ post_id: number; type_of_post: string }
		>({
			query: ({ post_id, type_of_post }) => ({
				url: "content/unsavePost/",
				method: "POST",
				body: { post_id, type_of_post },
			}),
			async onQueryStarted(
				{ post_id, type_of_post },
				{ dispatch, queryFulfilled, getState }
			) {
				// console.log("onQueryStarted");
				const content = (getState() as RootState).content;

				let pageOptions: pageOptions = { pageNumber: 1 };
				type PostType =
					| "fetchTrendingBlogs"
					| "fetchBlogs"
					| "fetchTweets"
					| "fetchYoutube"
					| "fetchReddit";

				let fetchQuery: PostType = "fetchBlogs";
				if (type_of_post === "blog") {
					pageOptions = content.blogs;
					fetchQuery = "fetchBlogs";
				} else if (type_of_post === "twitter") {
					pageOptions = content.twitter;
					fetchQuery = "fetchTweets";
				} else if (type_of_post === "youtube") {
					pageOptions = content.youtube;
					fetchQuery = "fetchYoutube";
				} else if (type_of_post === "reddit") {
					pageOptions = content.reddit;
					fetchQuery = "fetchReddit";
				} else if (type_of_post === "blog_trending") {
					pageOptions = content.trendingBlogs;
					fetchQuery = "fetchTrendingBlogs";
				}

				const patchResult = dispatch(
					authApi.util.updateQueryData(
						fetchQuery,
						pageOptions,
						(draft) => {
							if (type_of_post === "blog") {
								const index = draft.blogs.findIndex(
									(blog: any) => blog.id === post_id
								);
								if (index > -1) {
									draft.blogs[index].saved = false;
								}
							} else if (type_of_post === "twitter") {
								const index = draft.tweets.findIndex(
									(tweet: any) => tweet.id === post_id
								);
								if (index > -1) {
									draft.tweets[index].saved = false;
								}
							} else if (type_of_post === "youtube") {
								const index = draft.youtubeVideos.findIndex(
									(video: any) => video.id === post_id
								);
								if (index > -1) {
									draft.youtubeVideos[index].saved = false;
								}
							} else if (type_of_post === "reddit") {
								const index = draft.redditPosts.findIndex(
									(post: any) => post.id === post_id
								);
								if (index > -1) {
									draft.redditPosts[index].saved = false;
								}
							} else if (type_of_post === "blog_trending") {
								const index = draft.trendingBlogs.findIndex(
									(post: any) => post.id === post_id
								);
								if (index > -1) {
									draft.trendingBlogs[index].saved = false;
								}
							}
						}
					)
				);
				const patch2Result = dispatch(
					authApi.util.updateQueryData(
						"fetchSavedPosts",
						undefined,
						(draft) => {
							if (type_of_post === "blog") {
								const index = draft.blogs.findIndex(
									(blog: any) => blog.id === post_id
								);

								// remove blog from saved blogs
								if (index > -1) {
									draft.blogs.splice(index, 1);
								}
							} else if (type_of_post === "twitter") {
								const index = draft.tweets.findIndex(
									(tweet: any) => tweet.id === post_id
								);

								// remove tweet from saved tweets
								if (index > -1) {
									draft.tweets.splice(index, 1);
								}
							} else if (type_of_post === "youtube") {
								const index = draft.yt.findIndex(
									(video: any) => video.id === post_id
								);

								// remove video from saved videos
								if (index > -1) {
									draft.yt.splice(index, 1);
								}
							} else if (type_of_post === "reddit") {
								const index = draft.reddit.findIndex(
									(post: any) => post.id === post_id
								);

								// remove post from saved posts
								if (index > -1) {
									draft.reddit.splice(index, 1);
								}
							} else if (type_of_post === "blog_trending") {
								const index = draft.trending_blogs.findIndex(
									(post: any) => post.id === post_id
								);

								// remove post from saved posts
								if (index > -1) {
									draft.trending_blogs.splice(index, 1);
								}
							}
						}
					)
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
					patch2Result.undo();
				}
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useLazyFetchTrendingBlogsQuery,
	useLazyFetchBlogsQuery,
	useLazyFetchTweetsQuery,
	useLazyFetchYoutubeQuery,
	useLazyFetchRedditQuery,
	useFetchCreatorsQuery,
	useSavePostMutation,
	useUpdateTagsMutation,
	useFetchSavedPostsQuery,
	useUnsavePostMutation,
	useGetTagsQuery,
} = authApi;
