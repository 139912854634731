import {useDispatch} from "react-redux";
import {useState, useEffect, useRef, useCallback} from "react";
import {incrementPage} from "../redux/features/slices/contentSlice";
import {ContentState} from "../redux/features/slices/contentSlice";

interface hookProps {
	type: keyof ContentState;
	isSuccess: boolean;
	isFetching: boolean;
	hasMore: boolean;
}

export default function useIntersectionObserver({type, isSuccess, isFetching, hasMore}: hookProps) {
	const dispatch = useDispatch();
	const observer = useRef<IntersectionObserver | null>();

	const lastPostObserver = useCallback(
		(node) => {
			if (isSuccess === false) {
				return;
			}
			if (isFetching) {
				return;
			}
			if (observer.current) observer?.current?.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					// console.log("visible, ", pageOptions.pageNumber);
					dispatch(incrementPage(type));
				}
			});
			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isFetching, hasMore]
	);

	// return ref to be used in the component
	return lastPostObserver;
}
