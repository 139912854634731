import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { RxCookie } from "react-icons/rx";
import { Button } from "react-scroll";

interface Props {
	show: boolean;
	setShow: (show: boolean) => void;
}

function IsCookiesEnabled({ show, setShow }: Props) {
	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Body className="relative flex flex-col items-center text-center ">
				<div>
					<RxCookie className="text-7xl text-gray-400 md:text-9xl" />
				</div>
				<h1 className="h5 font-bold text-gray-400">Cookies are disabled</h1>
				<p className=" mb-4 text-center text-sm text-gray-500 md:text-base">
					{" "}
					You need to enable third party Cookies, otherwise you can't
					Login/Signup using Google{" "}
				</p>

				<a
					target={"_blank"}
					href={
						"https://intercom.help/herohealth/en/articles/4506995-how-to-enable-third-party-cookies-in-your-browser"
					}
					className="rounded-md bg-orangeCrushWarm px-4 py-2 text-sm font-semibold text-white hover:bg-accentOrange"
				>
					Enble Cookies
				</a>
			</Modal.Body>
		</Modal>
	);
}

export default IsCookiesEnabled;
