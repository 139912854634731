import React from "react";
import Navbar from "./comp/Navbar";
import Hero from "./sections/Hero";
import HowItWorks from "./sections/HowItWorks";
import Use from "./sections/Use";
import Numbers from "./sections/Numbers";
import Extension from "./sections/Extension";
import CruxeIsFor from "./sections/CruxeIsFor";
import Testimonials from "./sections/Testimonials";
import FAQ from "./sections/FAQ";
import Footer from "./sections/Footer";

const Landing = () => {
	return (
		<div className="overflow-x-hidden">
			<Navbar />
			<Hero />
			<HowItWorks />
			{/* <Use /> */}
			{/* <Numbers /> */}
			{/* <Extension /> */}
			<CruxeIsFor />
			{/* <Testimonials /> */}
			<FAQ />
			<Footer />
		</div>
	);
};

export default Landing;
