import React from "react";

interface TagProps {
	name: string;
	handleSelectCat: any;
	selected: boolean;
}

const Tag = ({ name, handleSelectCat, selected }: TagProps) => {
	return (
		<span
			className={`categories text-nowrap mb-2 mr-2  select-none px-2 py-1 text-center font-normal ${
				selected && "selected font-semibold text-gray-100"
			}`}
			onClick={() => {
				handleSelectCat(name);
			}}
		>
			{name}
		</span>
	);
};

export default Tag;
